import { useState } from "react";
import EmptyPromptPopover from "../popovers/emptyPromptPopover";
import { UseFormRegister, FieldError } from "react-hook-form";

type APISelectFieldProps = {
    options: any[];
    attributes: React.SelectHTMLAttributes<HTMLSelectElement>; 
    register: UseFormRegister<any>;
    name: string;
    placeholder: string;
    pathname?: string;
    required: boolean | string;
    valueAsNumber?: boolean;
    error?: FieldError;
    mapKey?: string;
    mapValue?: string;
    mapText?: string;
    mapTexts?: string[];
    customMapText?: (option: any) => string;
    /** If true, the `Set Option` popup will not come up when 
     * the options array (the API resource data) is empty */
    doNotLinkToResource?: boolean;
}

const APISelectField = ({
    options,
    attributes,
    register,
    name,
    placeholder,
    pathname,
    required,
    valueAsNumber,
    error,
    mapKey,
    mapValue,
    mapText,
    mapTexts,
    customMapText,
    doNotLinkToResource,
}: APISelectFieldProps) => {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

	const handleNoOptionsClick = (event: any) => {
		if (!options.length) setAnchorEl(event.currentTarget);
	};

    return (<>  
        <select
            id={name}
            {...register(name, {
                required: required,
                valueAsNumber: valueAsNumber,
            })}
            className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
                error
                    ? " border border-red-500 focus:border-red-500 focus:outline-red-500"
                    : "border-none"
            }`}
            onClick={handleNoOptionsClick}
            { ...attributes }
        >
            <option value="">{placeholder}</option>
            {options.map((option) => (
                <option 
                    key={mapKey ? option[mapKey] : option.id} 
                    value={mapValue ? option[mapValue] : option.id}
                >
                    {customMapText
                        ? customMapText(option) 
                        : mapTexts 
                            ? mapTexts.reduce((sum, key) => sum + `${option[key]} `, "")
                            : mapText 
                                ? option[mapText] 
                                : option.name
                    }
                </option>
            ))}
        </select>
        {(!options.length && !doNotLinkToResource) && (
            <EmptyPromptPopover
                id={Boolean(anchorEl) ? "simple-popover" : undefined}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                handleClose={() => setAnchorEl(null)}
                pathname={pathname || ""}
            />
        )}
    </>);
}
 
export default APISelectField;