import {  
	TextField, 
	styled,
    Tooltip, 
    tooltipClasses, 
    TooltipProps 
} from "@mui/material";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { GridApiCommunity } from "@mui/x-data-grid/internals";

export const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
	border: 0,
	color:
		theme.palette.mode === "light"
			? "rgba(0,0,0,.85)"
			: "rgba(255,255,255,0.85)",
	fontFamily: [
		"Inter",
		"-apple-system",
		"BlinkMacSystemFont",
		'"Segoe UI"',
		"Roboto",
		'"Helvetica Neue"',
		"Arial",
		"sans-serif",
		'"Apple Color Emoji"',
		'"Segoe UI Emoji"',
		'"Segoe UI Symbol"',
	].join(","),
	WebkitFontSmoothing: "auto",
	letterSpacing: "normal",
	"& .MuiDataGrid-columnsContainer": {
		backgroundColor: theme.palette.mode === "light" ? "#fafafa" : "#1d1d1d",
	},
	"& .MuiDataGrid-iconSeparator": {
		display: "none",
	},
	"& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
		borderBottom: `1px solid ${
			theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
		}`,
	},
	"& .MuiDataGrid-cell": {
		color:
			theme.palette.mode === "light"
				? "rgba(0,0,0,.85)"
				: "rgba(255,255,255,0.65)",
	},
	"& .MuiPaginationItem-root": {
		borderRadius: 0,
	},
	"& .MuiDataGrid-root .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-root .MuiDataGrid-cell:focus":
		{
			outline: "none",
		},
}));


export const CustomizedTooltip = styled(({ className, maxWidth, ...props }: TooltipProps & { maxWidth?: string }) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme, maxWidth = "none" }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
	  maxWidth: maxWidth || undefined,
	  fontSize: 12,
	  fontFamily: "Inter"
	},
}));

export const BoxStyle = {
	height: 300,
	backgroundColor: "background.paper",
	borderRadius: 4,
	width: "100%",
	"& .actions": {
		color: "text.secondary",
	},
	"& .textPrimary": {
		color: "text.primary",
	},
	"& .super-app-theme--header": {
		backgroundColor: "#F2F2F4",
		fontWeight: "bold",
	},
	"& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
		width: "11px",
	},
	"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
		background: "#f7fafc",
	},
	"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
		backgroundColor: "#e2e8f0",
		borderRadius: "6px",
		border: "3px solid #f7fafc",
	},
	"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover": {
		backgroundColor: "#e2e8f0",
		borderRadius: "7px",
		border: "3px solid #f7fafc",
	},
};

export const CustomTextField = styled(TextField)({
	"& .MuiOutlinedInput-root": {
		"& fieldset": {
			border: "none",
		},
	},
});

export const ModalStyle = {
	position: "absolute" as "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	// width: 600,
	maxWidth: "95vw",
	maxHeight: "90vh",
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 4,
	border: "none",
	borderRadius: "8px",
	outline: "none",
	overflowY: "auto",
	overflowX: "hidden",
};

export const APP_CONFIG = {
	PAGE_LIMIT: 500,
	PLATFORM_CLIENT: {
		REACT_APP_CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
		REACT_APP_CLIENT_SECRET: process.env.REACT_APP_CLIENT_SECRET,
		REACT_APP_GRANT_TYPE: process.env.REACT_APP_GRANT_TYPE,

	},
	UPLOAD_REQUEST_TOKEN: `eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJDNHRLcjhyeWlYM2szMTNVR2tfb0p1Uks0SGhEWnd0eUx6eDNlVDktM2pJIn0.eyJleHAiOjE2OTA5ODA3ODUsImlhdCI6MTY5MDk4MDQ4NSwianRpIjoiMDdkOGI2MGItMzlmNy00MmZjLTg2NjYtYjZlZTA3Yzg0NTY0IiwiaXNzIjoiaHR0cHM6Ly9zZXJ2aWNlcy5sdWNpZGFycmF5LmRldi9rZXljbG9hay9yZWFsbXMvc2VydmljZXMiLCJhdWQiOiJhY2NvdW50Iiwic3ViIjoiNmFmZGQ4YWUtY2Q3Ny00ODc2LWJhZjAtNTgzMjE3NDU1MGQ1IiwidHlwIjoiQmVhcmVyIiwiYXpwIjoiYWthdHVhLXBsYXRmb3JtLWNsaWVudCIsImFjciI6IjEiLCJhbGxvd2VkLW9yaWdpbnMiOlsiLyoiXSwicmVhbG1fYWNjZXNzIjp7InJvbGVzIjpbImRlZmF1bHQtcm9sZXMtc2VydmljZXMiLCJvZmZsaW5lX2FjY2VzcyIsInVtYV9hdXRob3JpemF0aW9uIl19LCJyZXNvdXJjZV9hY2Nlc3MiOnsiYWNjb3VudCI6eyJyb2xlcyI6WyJtYW5hZ2UtYWNjb3VudCIsIm1hbmFnZS1hY2NvdW50LWxpbmtzIiwidmlldy1wcm9maWxlIl19LCJha2F0dWEtcGxhdGZvcm0tY2xpZW50Ijp7InJvbGVzIjpbInVtYV9wcm90ZWN0aW9uIl19fSwic2NvcGUiOiJwcm9maWxlIGVtYWlsIiwiY2xpZW50SG9zdCI6IjE1NC4xNjAuMS4yMzEiLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsInByZWZlcnJlZF91c2VybmFtZSI6InNlcnZpY2UtYWNjb3VudC1ha2F0dWEtcGxhdGZvcm0tY2xpZW50IiwiY2xpZW50QWRkcmVzcyI6IjE1NC4xNjAuMS4yMzEiLCJjbGllbnRfaWQiOiJha2F0dWEtcGxhdGZvcm0tY2xpZW50In0.hrWuamYNoDKNl5OyO8gRsAvAzEAJ7eRyvYnX4OSCX6c8Kgk-A9Gre15OCXiFc1QCObo0PlqF33PN-HHbHxb67vHAnxwiHfmpiMlH0-Zf1IAf7vuDNmqqVrPfvhh6TvxNZu9qHnhtzNQhj7NB1d8xl6yrtWipRFgDuA3qYfo2anq-TfFwgd6KpOVycuYTdXsN71p3W2cptYjWMp22JBuofCOVjvRWWkXButUG6950rVJKPOQWliX1M5pRsNMyKo84k1qxlaohV-iD3kFRg28SdA_obyQ7k_EtzMo5bSLZZi0ndcZH0AHMB5iCZ-um6Pp1p5BpQ34lsFIkmsbba4VSmw`


}

// App Tables

export const formatDateAndTime = (params: GridValueGetterParams | string) => {
	if (!params) return "";
	
	const input = typeof params === "string" ? params : params.value;
	const dateTime = new Date(input);
	
    const day = String(dateTime.getDate()).padStart(2, '0'); // Ensure day is always 2 digits
    const month = String(dateTime.getMonth() + 1).padStart(2, '0'); // Ensure month is always 2 digits
    const year = dateTime.getFullYear();
    const formattedDate = `${day}/${month}/${year}`;

	const formattedTime = dateTime.toLocaleTimeString("en-US", {
		hour: "2-digit",
		minute: "2-digit",
	});
	return `${formattedDate}, ${formattedTime}`;
}

export const formatDate = (params: GridValueGetterParams | string) => {
	if (!params) return "";

	const input = typeof params === "string" ? params : params.value;
	const dateTime = new Date(input);
	
    const day = String(dateTime.getDate()).padStart(2, '0'); // Ensure day is always 2 digits
    const month = String(dateTime.getMonth() + 1).padStart(2, '0'); // Ensure month is always 2 digits
    const year = dateTime.getFullYear();
    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
}

export const createdAtmodifiedAtActions: GridColDef[] = [
	{
		field: "createdAt",
		headerName: "Date Created",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		sortComparator: (date1, date2) => new Date(date1).getTime() - new Date(date2).getTime(),
		valueGetter: (params: GridValueGetterParams) => {
			const createdOn = formatDateAndTime(params);
			return createdOn;
		},
	},
	{
		field: "modifiedAt",
		headerName: "Date Modified",
		width: 180,
		editable: true,
		headerClassName: "super-app-theme--header",
		sortComparator: (date1, date2) => new Date(date1).getTime() - new Date(date2).getTime(),
		valueGetter: (params: GridValueGetterParams) => {
			const modifiedOn = formatDateAndTime(params);
			return modifiedOn;
		},
	},
	{
		field: "actions",
		headerName: "Actions",
		cellClassName: "actions",
		headerClassName: "super-app-theme--header",
	}
];

export const BpIcon = styled("span")(({ theme }) => ({
	borderRadius: "50%",
	width: 16,
	height: 16,
	boxShadow:
		theme.palette.mode === "dark"
			? "0 0 0 1px rgb(16 22 26 / 40%)"
			: "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
	backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
	backgroundImage:
		theme.palette.mode === "dark"
			? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
			: "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
	".Mui-focusVisible &": {
		outline: "2px auto rgba(19,124,189,.6)",
		outlineOffset: 2,
	},
	"input:hover ~ &": {
		backgroundColor: theme.palette.mode === "dark" ? "#2A3742" : "#DBE2E7",
		cursor: "pointer",
	},
	"input:disabled ~ &": {
		boxShadow: "none",
		background:
			theme.palette.mode === "dark"
				? "rgba(57,75,89,.5)"
				: "rgba(206,217,224,.5)",
	},
}));

export const BpCheckedIcon = styled(BpIcon)({
	backgroundColor: "#fff",
	backgroundImage:
		"linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
	"&:before": {
		display: "block",
		width: 16,
		height: 16,
		backgroundImage: "radial-gradient(#26A0F9,#26A0F9 28%,transparent 32%)",
		content: '""',
	},
	"input:hover ~ &": {
		backgroundColor: "#1A6390",
		cursor: "pointer",
	},
});

export const FREQUENCY = [
    'ANNUALLY',
    'BIANNUALLY',
    'QUARTERLY',
    'MONTHLY',
    'FORTNIGHTLY',
    'WEEKLY',
    'DAILY',
];

export function moneyFormat(
	value: number | string | bigint, 
	standard?: string | string[], 
	dec = 0
) {
	const nf = new Intl.NumberFormat(standard, {
		minimumFractionDigits: dec || 2,
		maximumFractionDigits: dec || 2,
	});

	return nf.format(Number(value));
}

/** Default props for tables add/edit form */
export interface IAddEditTableForm {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	title: string;
	isEditForm: boolean;
	tableAPIRef?: React.MutableRefObject<GridApiCommunity>;
}

/** Used for company tree nodes */
export const randomHexColors: string[] = [
	"#6e44ff", "#ff7e79", "#07a8a6", "#808F54", "#714C22",
	"#0B006E", "#6E0505", "#10AC5B", "#422D2A", "#1D5765",
	"#CF992C", "#D84933", "#4a69bd", "#60a3bc", "#78e08f",
	"#fa983a", "#eb2f06", "#1e3799", "#3c6382", "#196966",
	"#e58e26", "#b71540", "#0c2461", "#0a3d62", "#123937",
	"#91D54D", "#A32B73", "#32835B", "#273657", "#BE9A5D",
];

export const subscriptionCardColors = {
	"0": ["#0093AF", "#4EAEC1"],
	"1": ["#9B51E0", "#C29BE7"],
	"2": ["#4B0082", "#7931AC"],
	"3": ["#00416A", "#3383B5"],
	"4": ["#575788", "#8F8FC1"],
	"5": ["#A975A9", "#DBBBDB"],
	"6": ["#9494FC", "#AFAFF0"],
}

/** Validate a date field to ensure it's value is less than the 
 * current day. Applied as a `react-hook-form` validator
 */
export const validatePast = (value: string) => {
	const today = new Date();
	today.setHours(0,0,0,0); // To avoid comparing time part

	if (new Date(value) > today) {
		return "Date must not be greater than today"
	}

	return true
};

/** Validate a date field to ensure it's value is greater than the 
 * current day. Applied as a `react-hook-form` validator
 */
export const validateFuture = (value: string) => {
	const today = new Date();
	today.setHours(0,0,0,0); // To avoid comparing time part

	if (today > new Date(value)) {
		return "Date must not be less than today"
	}

	return true
};

/** Validate two date fields (`dateOne` and `dateTwo`) to check if `dateOne` is 
 * greater than `dateTwo`. Applied as a `react-hook-form` validator.
 */
export const compareDateFields = (
	value1: string, 
	value2: string, 
	errorMessage?: string
) => {
	const date1 = new Date(value1).setHours(0,0,0,0);
	const date2 = new Date(value2).setHours(0,0,0,0);

	if (date1 > date2) {
		return errorMessage || "Start date must be less than end date"
	}

	return true
};

/** ie Converts `ELAPSED_CONTRACT` to `Elapsed Contract` */
export const enumToStringConverter = (value: string) => {
	if (!value) return value;
	const splittedValues = value.split("_");

	const newValue = splittedValues.reduce((acc, value) => {
		return acc += value.slice(0, 1) + value.slice(1).toLowerCase() + " ";
	}, "")

	return newValue.trim();
}

/** Generates key for each element in a list */
export const generateKey = () => {
	return `${Math.random()}-${Math.random()}`
}

/** Maps out `Ids` to add and `Ids` to remove */
export const manageResourceIds = (
	{ newData, oldData }: 
	{ 
		newData: (number | string)[]; 
		oldData: (number | string)[];
	}
) => {
	const addIds: number[] = [];
	const removeIds: number[] = [];

	newData.map(id => {
		const foundId = Boolean(oldData.find(oldId => oldId === id));
		if (!foundId) addIds.push(Number(id));
	})
	oldData.map(id => {
		const foundId = Boolean(newData.find(newId => newId === id));
		if (!foundId) removeIds.push(Number(id));
	})

	return {
		addIds: addIds.length ? addIds : undefined,
		removeIds: removeIds.length ? removeIds : undefined,
	}
}

/** For formatting rate components */
export const formatRate = (data: any, fieldName?: string) => {
	const field = fieldName || "rateComponent";
	data[field] = (data[field] || data[field] === 0) ? (data[field] / 100) : undefined;
}