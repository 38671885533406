import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import Label from "../../../../../components/form_fields/Label";
import OrangeButton from "../../../../../components/buttons/OrangeButton";
import FormDescriptionInput from "../../../../../components/form_fields/FormDescriptionInput";
import { useCreateGradeLevel } from "../../../../../services/grade-level.service";
import { GRADE_LEVEL_TYPE } from "../../../../../models/grade-level.dto";
import { CompanyLevelDto } from "../../../../../models/company-level.dto";
import useCompanyStore from "../../../../../state-management/useCompanyStore";
import { useManageAccordionsData } from "../../../../../helpers/useManageAccordions";
import FormCodeInput from "../../../../../components/form_fields/FormCodeInput";
import APISelectField from "../../../../../components/form_fields/APISelectField";
import { EmployeeBandDto } from "../../../../../models/employee-band.dto";
import { PATHS } from "../../../../../routes/routes.paths";

interface IFormInputs {
    companyLevelId: number;
	name: string;
	code: string;
    type: GRADE_LEVEL_TYPE;
	description?: string;
    employeeBandId?: number;
}

interface INewGradeLevelForm {
	companyLevels: CompanyLevelDto[];
	employeeBands: EmployeeBandDto[];
	setShowNewGradeLevelForm: React.Dispatch<React.SetStateAction<boolean>>;
}

const NewGradeLevelForm = ({
	companyLevels,
	employeeBands,
	setShowNewGradeLevelForm,
}: INewGradeLevelForm) => {
	const { currentCompany } = useCompanyStore();
	const [uploading, setUploading] = useState(false);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<IFormInputs>();
	
	const { mutate: createGradeLevel } = useCreateGradeLevel();
	const { addNewAccordionsData } = useManageAccordionsData();
	
	const onSubmit: SubmitHandler<IFormInputs> = (data) => {
		setUploading(true);

		createGradeLevel(
			{ payload: { ...data, companyId: currentCompany?.id as number } },
			{
				onSuccess: () => {
					addNewAccordionsData();
					setShowNewGradeLevelForm(false);
				},
				onSettled: () => setUploading(false),
			}
		)
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="grid grid-cols-2 gap-x-4 gap-y-6 mt-6">
				<div className="w-full">
					<Label title="Name" for={`name`} />
					<input
						placeholder={"Enter Name"}
						type="text"
						{...register(`name`, {
							required: "Enter name here",
						})}
						id={`name`}
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
							errors?.name
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
					/>
					{errors?.name && (
						<p className="text-red-500 text-sm mt-1">
							{errors?.name?.message}
						</p>
					)}
				</div>
				<div className="w-full">
					<Label title="Unique Code" for="code" />
					<FormCodeInput
						register={register}
						attributes={{
							className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
								errors.code
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`,
						}}
					/>
					{errors?.code && (
						<p className="text-red-500 text-sm mt-1">
							{errors?.code?.message}
						</p>
					)}
				</div>
				<div className="w-full">
					<Label title="Company Level" for="companyLevelId" />
					<APISelectField 
						key="companyLevelId"
						attributes={{
							id: "companyLevelId",
						}}
						placeholder="Select Company Level"
						register={register}
						name="companyLevelId"
						options={companyLevels}
						mapText="levelName"
						pathname={PATHS.SUB_COMPANY_SETTINGS.COMPANY_LEVELS}
						required={"Select company level here"}
						error={errors.companyLevelId}
						valueAsNumber
					/>
					{errors.companyLevelId && (
						<p className="text-red-500 text-sm mt-1">
							{errors.companyLevelId.message}
						</p>
					)}
				</div>
				<div className="w-full">
					<Label title="Type" for="type" />
					<select
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
							errors.type
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
						{...register("type", {
							required: "Select type here",
						})}
					>
						<option value="">Select Type</option>
						<option value="MINOR">Minor</option>
						<option value="MAJOR">Major</option>
					</select>
					{errors.type && (
						<p className="text-red-500 text-sm mt-1">
							{errors.type.message}
						</p>
					)}
				</div>
				<div className="col-span-2 w-full">
					<Label title="Description" for={`description`} optional />
					<FormDescriptionInput 
						attributes={{
							className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
								errors.description
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`
						}}
						register={register}
						required={false}
					/>
				</div>
				<div className="w-full">
					<Label title="Employee Band" for="employeeBandId" optional />
					<APISelectField 
						key="employeeBandId"
						attributes={{
							id: "employeeBandId",
						}}
						placeholder="Select Employee Band"
						register={register}
						name="employeeBandId"
						options={employeeBands}
						pathname={PATHS.SUB_COMPANY_SETTINGS.BANDS}
						required={false}
						error={errors.employeeBandId}
						valueAsNumber
					/>
				</div>
			</div>

			<div className="w-full flex items-center justify-between my-8">
				<button
					onClick={() => setShowNewGradeLevelForm(false)}
					className="px-10 py-4 border text-gray-400 hover:text-gray-500 border-gray-300 rounded-lg text-2xs"
				>
					Close Form
				</button>
				<OrangeButton
					title={`${uploading ? "Saving..." : "Add Grade Level"}`}
					className={"h-14 px-8"}
					type="submit"
					disabled={uploading}
				/>
			</div>
		</form>
	);
};

export default NewGradeLevelForm;
