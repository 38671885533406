import { useState, useEffect } from "react";
import { Box, Modal, LinearProgress, Popover } from "@mui/material";
import { IoMdClose } from "react-icons/io";
import { useDropzone } from "react-dropzone";
import { ModalStyle } from "../../../helpers/appHelpers";
import {
	BsDownload,
	BsUpload,
	BsFillFileEarmarkTextFill,
} from "react-icons/bs";
import useCompanyStore from "../../../state-management/useCompanyStore";
import { useUploadEmployee, useUploadRevision } from "../../../services/employee.service";
import useUserStore from "../../../state-management/useUserStore";
import { toast } from "react-toastify";
import { useManageTableWithSearchParamsData } from "../../../helpers/useManageTable";
import { UploadEmployeeResponse } from "../../../models/employee.dto";

type FileStatus = "success" | "error" | "uploading" | ""

interface UploadEmployeesProps {
	open: boolean;
	setOpen: (open: boolean) => void;
}

const UploadEmployees = ({ open, setOpen }: UploadEmployeesProps) => {
	const { currentCompany } = useCompanyStore();
	const [file, setFile] = useState<File | null>(null);
	const [fileStatus, setFileStatus] = useState<FileStatus>("");
	const [uploading, setUploading] = useState(true);
	const [employeeRevision, setEmployeeRevision] = useState(false);
	const [errorModalEl, setErrorModalEl] = useState<any>(null);
	const [uploadEmployeeResponse, setUploadEmployeeResponse] = 
		useState<UploadEmployeeResponse | undefined>();

	const { getRootProps, getInputProps } = useDropzone({
		accept: {
			"text/xlsx": [".xlsx"],
		},
		maxFiles: 1,
		onDrop: (acceptedFiles: File[]) => {
			const file = acceptedFiles[0];
			const maxFileSize = 2 * 1024 * 1024; // (2 megabytes)
	
			// If the selected file size is larger than the maximum file size
			if (file.size > maxFileSize) {
				alert("File is too large!");
				return;
			}

			setFile(file);
		},
		onDropRejected: () => {
			alert("Invalid file format. Please upload a valid Excel file (.xlsx)");
		},
	});

	useEffect(() => {
		file ? setUploading(false) : setUploading(true);
	}, [file])

	const { mutate: uploadEmployee } = useUploadEmployee();
	const { mutate: uploadRevision } = useUploadRevision();
	const { addNewTableData, reloadTableData } = useManageTableWithSearchParamsData();

	const createEmployee = () => {
		if (!file) {
			alert("No file selected.");
			return;
		}

		setUploading(true);
		setFileStatus("uploading");

		const formData = new FormData();
		formData.append('file', file);

		const uploadFunction = employeeRevision ? uploadRevision : uploadEmployee;
		
		uploadFunction(
			{ 
				payload: formData, 
				companyId: currentCompany!.id
			},
			{
				onSuccess: (data) => {
					setFileStatus("success");

					if (data.successful.length && !data.failed.length) {
						if (employeeRevision) {
							toast.success("Employee emails updated successfully");
							reloadTableData();
						} else {
							toast.success("All employees created successfully");
							addNewTableData();
						}

						handleClose();
						return
					}
					
					setUploadEmployeeResponse(data);

					if (!data.successful.length && data.failed.length) return;

					addNewTableData();
				},
				onError: () => setFileStatus("error"),
				onSettled: () => setUploading(false),
			}
		)
	};

	const handleClose = () => {
		setFile(null);
		setOpen(false);
	};

	return (
		<Modal
			open={open}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={ModalStyle} width={600}>
				<>
					<div className="flex justify-between items-center">
						<h1 className="font-bold text-lg">Upload Employees</h1>
						<button onClick={handleClose}>
							<IoMdClose className="text-xl hover:scale-[1.1]" />
						</button>
					</div>
					<div
						{...getRootProps()}
						className="mt-8 border-2 border-dashed border-spacing-3 border-primary-blue rounded-xl h-[120px] w-full flex flex-col space-y-3 justify-center items-center text-primary-blue bg-[#26A0F91A] text-sm cursor-pointer"
					>
						<input {...getInputProps()} />
						<BsUpload className="text-xl" />
						<p>Drag and drop or browse your computer to add file (.xlsx)</p>
					</div>
					{file && (
						<div className="flex items-center space-x-4 w-full mt-1">
							<BsFillFileEarmarkTextFill className="text-6xl text-gray-500" />
							<div className="flex flex-col items-start w-full">
								<div className="flex justify-between items-center w-full mb-1">
									<p className="text-xs w-[250px] text-primary-blue text-ellipsis truncate">
										{file.name}
									</p>
									<button onClick={() => {
										setFile(null);
										setFileStatus("");
										setUploadEmployeeResponse(undefined);
									}}>
										<IoMdClose className="text-xl hover:scale-[1.1]" />
									</button>
								</div>
								<Box sx={{ width: "100%", mb: 1, mt: 0 }}>
									<LinearProgress
										color={fileStatus === "error" ? "error" : "primary"}
										variant={"determinate"}
										value={fileStatus ? 100 : 0}
									/>
								</Box>
								<div className="flex justify-between text-xs w-full">
									<p>
										File size: {(file.size / 1000000).toFixed(2)}mb
									</p>
									{fileStatus === "success" ? (
										<span className="text-green-500 text-xs italic">
											File uploaded
										</span>
									) : fileStatus === "error" ? (
										<span className="text-red-500 text-xs italic">
											File upload error! Try again
										</span>
									) : null}
								</div>
							</div>
						</div>
					)}

					<div className="flex space-x-3 items-center mt-5 text-sm">
						<input 
							type="checkbox"
							id="revision"
							value="true"
							onChange={() => setEmployeeRevision(prev => !prev)}
						/>
						<label htmlFor="revision">Update Employee Emails Only</label>
					</div>

					{uploadEmployeeResponse && (
						<p className="mt-5 text-2xs">
							<span className="font-bold">{uploadEmployeeResponse.successful.length}</span>
							{" "}employees processed,{" "} 
							<span className="font-bold">{uploadEmployeeResponse.failed.length}</span>
							{" "}with issues, click{" "}
							<button 
								onClick={(e) => setErrorModalEl(e.currentTarget)}
								className="text-primary-blue"
							>
								here
							</button>
							{" "}to view
						</p>
					)}
					<div className="text-sm mt-8">
						<p>Download an editable file template below</p>
						<button 
							className="border border-gray-300 rounded-lg text-[12px] text-gray-600 
								py-3 px-3 flex items-center mt-4 hover:shadow-sm"
							onClick={() => {
								window.location.href = '/assets/others/employees_upload.xlsx';
							}}
						>
							<BsDownload className="mr-3 text-[17px] text-gray-500" />
							Download template
						</button>
					</div>

					<div className="flex justify-end mt-8">
						<button 
							onClick={createEmployee}
							disabled={uploading}
							className="bg-primary-mango text-white h-12 px-6 rounded-lg text-sm hover:shadow-sm"
						>
							{fileStatus === "uploading" 
								? "Uploading..." 
								: "Upload File"}
						</button>
					</div>
				</>
				<Popover
					open={Boolean(errorModalEl)}
					anchorEl={errorModalEl}
					onClose={() => setErrorModalEl(null)}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "left",
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "left",
					}}
					PaperProps={{
						style: {
							maxHeight: "900px",
							maxWidth: "450px",
							fontSize: "13px",
							color: "#4F4F4F",
							borderRadius: 10,
							background: "white",
							boxShadow: "-10px -5px 30px 0px rgba(0, 0, 0, 0.15)",
							padding: "20px",
							overflowX: "hidden",
							overflowY: "auto",
							whiteSpace: "normal",
						}
					}}
					classes={{ root: "children-scroll-bar", paper: "children-scroll-bar" }}
				>
					<h5 className="text-[14px] font-bold mb-3">File Upload Error(s)</h5>
					<div className="flex flex-col gap-3">
						{uploadEmployeeResponse?.failed.map((failedItem, index) => (
							<div key={index} className="border-t pt-3">
								<h6 className="font-semibold">
									Employee on row {failedItem.rowNumber} had the following errors:
								</h6>
								<ul className="ml-2 pt-2 pl-5 list-disc list-inside space-y-2">
									{failedItem.errors.map((columnError) => (
										<li key={columnError.reason} className="-indent-5">
											{columnError.reason}.{" "}
											<span className="font-medium">
												({columnError.column})
											</span>
										</li>
									))}
								</ul>
							</div>
						))}
					</div>
				</Popover>
			</Box>
		</Modal>
	);
};

export default UploadEmployees;
