import { PaginatorInfo } from "./generic.dto";


type AuditLog = {
    id: number
    action: string
    organizationId: string
    requestId: string
    user: any
    input: string | null
    companyId: number | null
    timestamp: string
    modifiedAt: string | null
}

export enum AuditLogOrderBy {
  TIMESTAMP_ASC = 'timestamp:asc',
  TIMESTAMP_DESC = 'timestamp:desc',
}

export class QueryAuditLogDto {
  action?: string;
  organizationId?: string;
  requestId?: string;
  companyId?: number;
  page = 1;
  limit?: number;
  orderBy: AuditLogOrderBy = AuditLogOrderBy.TIMESTAMP_DESC;
}

export class SearchAuditLogDto {
  q?: string;

  page = 1;
  limit?: number;
  orderBy: AuditLogOrderBy = AuditLogOrderBy.TIMESTAMP_DESC;
}


export interface AuditLogDto extends AuditLog {};
export interface AuditLogPaginator extends PaginatorInfo<AuditLogDto> {}