import { useState } from "react";
import ModalComponent from "../../../../components/modals/ModalFormComponent";
import Label from "../../../../components/form_fields/Label";
import { SubmitHandler, useForm } from "react-hook-form";
import useEditFormStore from "../../../../state-management/useEditFormStore";
import { IAddEditTableForm } from "../../../../helpers/appHelpers";
import APISelectField from "../../../../components/form_fields/APISelectField";
import OrangeButton from "../../../../components/buttons/OrangeButton";
import { useManageTableWithSearchParamsData } from "../../../../helpers/useManageTable";
import useCompanyStore from "../../../../state-management/useCompanyStore";
import useUserStore from "../../../../state-management/useUserStore";
import { PATHS } from "../../../../routes/routes.paths"
import { EmployeeDto } from "../../../../models/employee.dto";
import { PayPeriodDto } from "../../../../models/pay-period.dto";
import { useCreateEmployeeWorkTime, useUpdateEmployeeWorkTime } from "../../../../services/employee-work-time.service";

interface IFormInputs {
	employeeId: number;
	payPeriodId: number;
	timeUnit: number;
	timeValue: number;
}

type WorkTimeModalFormProps = IAddEditTableForm & {
	employees: EmployeeDto[];
	payPeriods: PayPeriodDto[];
}

const WorkTimeModalForm = ({
	open,
	setOpen,
	title,
	isEditForm,
	tableAPIRef,
	employees,
	payPeriods,
}: WorkTimeModalFormProps) => {
	const { userType } = useUserStore();
	const { currentCompany } = useCompanyStore();
	const {formDataValues} = useEditFormStore();
	const [uploading, setUploading] = useState(false);
	
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<IFormInputs>({
		defaultValues: {
			employeeId: isEditForm ? formDataValues?.employeeId : "",
			payPeriodId: isEditForm ? formDataValues?.payPeriodId : "",
			timeUnit: isEditForm ? formDataValues?.timeUnit : "",
			timeValue: isEditForm ? formDataValues?.timeValue : "",
		}
	});

	const { mutate: createEmployeeWorkTime } = useCreateEmployeeWorkTime();
	const { mutate: updateEmployeeWorkTime } = useUpdateEmployeeWorkTime();
	const { addNewTableData } = useManageTableWithSearchParamsData();

	const onSubmit: SubmitHandler<IFormInputs> = (data) => {
		setUploading(true);

		if (isEditForm) {
			updateEmployeeWorkTime(
				{ payload: data, id: formDataValues?.id }, 
				{
					onSuccess: (updatedData) => {
						tableAPIRef?.current.updateRows([updatedData.data]);
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		} else {
			createEmployeeWorkTime(
				{
					payload: {
						...data,
						// companyId: currentCompany?.id,
					}
				}, 
				{
					onSuccess: () => {
						addNewTableData();
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		}
	};

	const handleClose = () => {
		setOpen(false);
		reset();
	};

	return (
		<ModalComponent open={open} title={title} handleClose={handleClose}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="grid grid-cols-2 gap-x-4 gap-y-6">
					<div className="w-full">
						<Label title="Employee" for="employeeId" />
						<select
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.employeeId
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							{...register("employeeId", {
								required: "Select employee here",
								valueAsNumber: true,
							})}
						>
							<option value="">
								Select Employee
							</option>
							{employees.map((employee) => (
								<option 
									key={employee.id} 
									value={employee.id} 
								>
									{employee.firstName + " " + employee.lastName}
								</option>
							))}
						</select>
						{errors.employeeId && (
							<p className="text-red-500 text-sm mt-1">
								{errors.employeeId.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Pay Period" for="payPeriodId" />
						<APISelectField 
							key="payPeriodId"
							attributes={{
								id: "payPeriodId",
								defaultValue: isEditForm ? formDataValues?.payPeriodId : undefined
							}}
							placeholder="Select Pay Period"
							register={register}
							name="payPeriodId"
							options={payPeriods}
							mapText="code"
							pathname={PATHS.SUB_COMPANY_SETTINGS.PAY_PERIODS}
							required={"Select pay period here"}
							error={errors.payPeriodId}
							doNotLinkToResource={userType === "HR"}
							valueAsNumber
						/>
						{errors.payPeriodId && (
							<p className="text-red-500 text-sm mt-1">
								{errors.payPeriodId.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Time Unit" for="timeUnit" />
						<select
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
								errors.timeUnit
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							{...register("timeUnit", {
								required: "Select time unit here",
							})}
						>
							<option value="">Select Unit</option>
							<option value="HOUR">Hour</option>
							<option value="DAY">Day</option>
						</select>
						{errors.timeUnit && (
							<p className="text-red-500 text-sm mt-1">
								{errors.timeUnit.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Time Value" for="timeValue" />
						<input
							defaultValue={isEditForm ? formDataValues?.timeValue : ""}
							placeholder={"Enter value"}
							type="number"
							{...register("timeValue", {
								required: "Enter time value here",
							})}
							id="timeValue"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.timeValue
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.timeValue && (
							<p className="text-red-500 text-sm mt-1">
								{errors.timeValue.message}
							</p>
						)}
					</div>
				</div>
				
				<div className="w-full flex justify-end mt-8">
					<OrangeButton
						type="submit"
						title={
							uploading
							? "Uploading..."
							: isEditForm
								? "Update Entry"
								: "Create Entry"
						}
						className="bg-primary-mango text-white h-14 px-8 rounded-lg text-sm"
						disabled={uploading}
					/>
				</div>
			</form>
		</ModalComponent>
	);
};

export default WorkTimeModalForm;