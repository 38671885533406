import {PaginatorInfo} from "./generic.dto";

export const FREQUENCY = {
  ANNUALLY: 'ANNUALLY',
  BIANNUALLY: 'BIANNUALLY',
  QUARTERLY: 'QUARTERLY',
  MONTHLY: 'MONTHLY',
  FORTNIGHTLY: 'FORTNIGHTLY',
  WEEKLY: 'WEEKLY',
  DAILY: 'DAILY',
};

type WorkDaysInAPeriod = {
  id: number
  companyId: number
  period: keyof typeof FREQUENCY
  numberOfDays: number
  createdAt: string
  modifiedAt: string | null
}
export class CreateWorkDaysInAPeriodDto{
  companyId!: number
  period !: keyof typeof FREQUENCY
  numberOfDays!: number;
}

export class UpdateWorkDaysInAPeriodDto{
  companyId?: number
  period ?: keyof typeof FREQUENCY
  numberOfDays?: number
}

export interface WorkDaysInAPeriodDto extends WorkDaysInAPeriod { }

export class QueryWorkDaysInAPeriodDto {
  companyId?: number;
  page?: number = 1;
  limit?: number;
  orderBy?: WorkDaysInAPeriodOrderBy = WorkDaysInAPeriodOrderBy.CREATED_AT_ASC;
}
export enum WorkDaysInAPeriodOrderBy {
  CREATED_AT_ASC = 'createdAt:asc',
  CREATED_AT_DESC = 'createdAt:desc',
  MODIFIED_AT_ASC = 'modifiedAt:asc',
  MODIFIED_AT_DESC = 'modifiedAt:desc',
}

export interface WorkDaysInAPeriodPaginator extends PaginatorInfo<WorkDaysInAPeriodDto> {}