import { useEffect, useState } from "react";
import ModalComponent from "../../../../components/modals/ModalFormComponent";
import Label from "../../../../components/form_fields/Label";
import { SubmitHandler, useForm } from "react-hook-form";
import { useCreateAllowance, useUpdateAllowance } from "../../../../services/allowance.service";
import { ALLOWANCE_FORMULA_TYPE, EMPLOYMENT_PERIOD_UNIT } from "../../../../models/allowance.dto";
import {
	formatRate,
	IAddEditTableForm,
	manageResourceIds,
} from "../../../../helpers/appHelpers";
import useEditFormStore from "../../../../state-management/useEditFormStore";
import FormDescriptionInput from "../../../../components/form_fields/FormDescriptionInput";
import MoneyInput from "../../../../components/form_fields/MoneyInput";
import OrangeButton from "../../../../components/buttons/OrangeButton";
import { useManageTableWithSearchParamsData } from "../../../../helpers/useManageTable";
import useCompanyStore from "../../../../state-management/useCompanyStore";
import { CompanyCurrencyConfigDto } from "../../../../models/company-currency.dto";
import { EmployeeBandDto } from "../../../../models/employee-band.dto";
import { PayPeriodDto } from "../../../../models/pay-period.dto";
import FormCodeInput from "../../../../components/form_fields/FormCodeInput";
import MultiSelectField from "../../../../components/form_fields/MultiSelectField";
import { PATHS } from "../../../../routes/routes.paths";
import RateComponent from "../../../../components/form_fields/RateComponent";
import { EmployeeDto } from "../../../../models/employee.dto";

interface IFormInputs {
	code: string;
	name: string;
	description: string;
    currencyId: number;
	formulaType: ALLOWANCE_FORMULA_TYPE;
	fixedComponent: number;
	rateComponent: number;
	minEmploymentPeriod?: number;
	employmentPeriodUnit?: EMPLOYMENT_PERIOD_UNIT;
    applicableValuePeriod: string;
	minValue: number;
	maxValue: number;
	prorate: string;
	active: string;
	employeeBandIds?: number[];
	payPeriodIds?: number[];
}

type AllowanceModalFormProps = IAddEditTableForm & {
	companyCurrencies: CompanyCurrencyConfigDto[];
	employees: EmployeeDto[];
	employeeBands: EmployeeBandDto[];
	payPeriods: PayPeriodDto[];
}

const AllowanceModalForm = ({
	open,
	setOpen,
	title,
	isEditForm,
	tableAPIRef,
	companyCurrencies,
	employees,
	employeeBands,
	payPeriods,
}: AllowanceModalFormProps) => {
	const { currentCompany } = useCompanyStore();
	const { formDataValues } = useEditFormStore();
	const [uploading, setUploading] = useState(false);

	const {
		register,
		handleSubmit,
		watch,
		setValue,
		reset,
		formState: { errors },
	} = useForm<IFormInputs>({
		defaultValues: {
			name: isEditForm ? formDataValues?.name : "",
			code: isEditForm ? formDataValues?.code : "",
			description: isEditForm ? formDataValues?.description : "",
			currencyId: isEditForm ? formDataValues?.currencyId : "",
			formulaType: isEditForm ? formDataValues?.formulaType : "",
			fixedComponent: isEditForm ? formDataValues?.fixedComponent : "",
			rateComponent: isEditForm ? (Number(formDataValues?.rateComponent) * 100) as any : "",
			minEmploymentPeriod: isEditForm ? formDataValues?.minEmploymentPeriod : "",
			employmentPeriodUnit: isEditForm ? formDataValues?.employmentPeriodUnit : "",
			applicableValuePeriod: isEditForm ? formDataValues?.applicableValuePeriod : "",
			minValue: isEditForm ? formDataValues?.minValue : "",
			maxValue: isEditForm ? formDataValues?.maxValue : "",
			prorate: isEditForm ? (formDataValues?.prorate ? "yes" : "no") : "",
			active: isEditForm ? (formDataValues?.active ? "yes" : "no") : "",
		}
	});

	const maxValue = watch("maxValue");
	const formulaType = watch("formulaType");
	const minEmploymentPeriod = watch("minEmploymentPeriod");
	const employmentPeriodUnit = watch("employmentPeriodUnit");

	useEffect(() => {
		if (formulaType === "FLAT") {
			setValue("rateComponent", 0)
		} else if (formulaType === "PERCENT") {
			setValue("fixedComponent", 0)
		}
	}, [formulaType])

	const [employeeIds, setEmployeeIds] = useState<number[]>([]);
	const [employeeBandIds, setEmployeeBandIds] = useState<number[]>([]);
	const [payPeriodIds, setPayPeriodIds] = useState<number[]>([]);
	const { mutate: createAllowance } = useCreateAllowance();
	const { mutate: updateAllowance } = useUpdateAllowance();
	const { addNewTableData } = useManageTableWithSearchParamsData();

	const onSubmit: SubmitHandler<IFormInputs> = (data: any) => {
		setUploading(true);
		
		formatRate(data);
		data.prorate = data.prorate === "yes" ? true : false;
		data.active = data.active === "yes" ? true : false;

		if (isEditForm) {
			const { 
				addIds: addPayPeriodIds, 
				removeIds: removePayPeriodIds 
			} = manageResourceIds({
				newData: payPeriodIds,
				oldData: formDataValues?.payPeriods?.map(payPeriod => payPeriod.id) || [],
			});
			const { 
				addIds: addEmployeeIds, 
				removeIds: removeEmployeeIds 
			} = manageResourceIds({
				newData: employeeIds,
				oldData: formDataValues?.employeeAllowances?.map(employeeAllowance => employeeAllowance?.employee.id) || [],
			});
			const { 
				addIds: addEmployeeBandIds, 
				removeIds: removeEmployeeBandIds 
			} = manageResourceIds({
				newData: employeeBandIds,
				oldData: formDataValues?.employeeBandAllowances?.map(employeeBandAllowance => employeeBandAllowance?.employeeBand.id) || [],
			});
			
			data.addPayPeriodIds = addPayPeriodIds;
			data.removePayPeriodIds = removePayPeriodIds;
			data.addEmployeeIds = addEmployeeIds;
			data.removeEmployeeIds = removeEmployeeIds;
			data.addEmployeeBandIds = addEmployeeBandIds;
			data.removeEmployeeBandIds = removeEmployeeBandIds;

			updateAllowance(
				{ payload: data, id: formDataValues?.id },
				{
					onSuccess: (updatedData) => {
						tableAPIRef?.current.updateRows([updatedData.data]);
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		} else {
			data.employeeBandIds = employeeBandIds.length ? employeeBandIds as any : undefined;
			data.payPeriodIds = payPeriodIds.length ? payPeriodIds as any : undefined;

			createAllowance(
				{ payload: { ...data, companyId: currentCompany?.id } },
				{
					onSuccess: () => {
						addNewTableData();
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		}
	};

	const handleClose = () => setOpen(false);

	return (
		<ModalComponent open={open} title={title} handleClose={handleClose}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="flex space-x-4">
					<div className="w-full">
						<Label title="Name" for="name" />
						<input
							placeholder={"Enter name"}
							type="text"
							{...register("name", {
								required: "Enter name here",
							})}
							id="name"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.name
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.name && (
							<p className="text-red-500 text-sm mt-1">{errors.name.message}</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Unique Code" for="code" />
						<FormCodeInput
							register={register}
							attributes={{
								className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
									errors.code
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`,
							}}
						/>
						{errors.code && (
							<p className="text-red-500 text-sm mt-1">{errors.code.message}</p>
						)}
					</div>
				</div>
				<div className="w-full mt-6">
					<Label title="Description" for="description" />
					<FormDescriptionInput
						register={register}
						attributes={{
							defaultValue: isEditForm ? formDataValues?.description : "",
							className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
								errors.description
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`,
						}}
					/>
					{errors.description && (
						<p className="text-red-500 text-sm mt-1">
							{errors.description.message}
						</p>
					)}
				</div>
				<div className="grid grid-cols-2 gap-x-4 gap-y-6 spa mt-6">
					<div className="w-full">
						<Label title="Currency" for="currencyId" />
						<select
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm mb-2 ${
								errors.currencyId
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							{...register("currencyId", {
								required: "Enter currency here",
								valueAsNumber: true,
							})}
						>
							<option value="">
								Select Currency
							</option>
							{companyCurrencies?.map((item) => (
								<option key={item?.id} value={item?.id}>
									{item?.currency?.name} ({item?.currency?.code})
								</option>
							))}
						</select>
						{errors.currencyId && (
							<p className="text-red-500 text-sm mt-1">
								{errors.currencyId.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Formula Type" for="formulaType" />
						<select
							defaultValue={isEditForm ? formDataValues?.formulaType : ""}
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
								errors.formulaType
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							{...register("formulaType", {
								required: "Select formula type here",
							})}
						>
							<option value="">Select Formula Type</option>
							<option value="FLAT">Flat</option>
							<option value="PERCENT">Percent</option>
							<option value="HYBRID">Hybrid</option>
						</select>
						{errors.formulaType && (
							<p className="text-red-500 text-sm mt-1">
								{errors.formulaType.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Fixed Component" for="fixedComponent" />
						<MoneyInput 
							attributes={{
								id: "fixedComponent",
								placeholder: "0",
								disabled: formulaType === "PERCENT",
							}}
							register={register}
							name="fixedComponent"
							setValue={setValue}
							defaultValue={isEditForm ? formDataValues?.fixedComponent : undefined}
							clearValue={formulaType === "PERCENT"}
							required={
								formulaType === "PERCENT" 
									? false 
									: "Enter fixed component here"
							}
							error={errors.fixedComponent}
						/>
						{errors.fixedComponent && (
							<p className="text-red-500 text-sm mt-1">
								{errors.fixedComponent.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Rate Component" for="rateComponent" />
						<RateComponent 
							attributes={{ 
								id: "rateComponent",
								disabled: Boolean(formulaType === "FLAT"),
							}}
							register={register}
							name="rateComponent"
							required={formulaType === "FLAT" ? false : "Enter rate component"}
							error={errors.rateComponent}
						/>
						{errors.rateComponent && (
							<p className="text-red-500 text-sm mt-1">
								{errors.rateComponent.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Minimum Value" for="minValue" />
						<MoneyInput 
							attributes={{
								id: "minValue",
								placeholder: "0"
							}}
							register={register}
							name="minValue"
							setValue={setValue}
							defaultValue={isEditForm ? formDataValues?.minValue : undefined}
							required="Enter minimum value here"
							validate={(value: number) => {
								if (maxValue && value > maxValue) {
									return "Cannot be more than the maximum value"
								}
								return true
							}}
							error={errors.minValue}
						/>
						{errors.minValue && (
							<p className="text-red-500 text-sm mt-1">
								{errors.minValue.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Maximum Value" for="maxValue" />
						<MoneyInput 
							attributes={{
								id: "maxValue",
								placeholder: "0"
							}}
							register={register}
							name="maxValue"
							setValue={setValue}
							defaultValue={isEditForm ? formDataValues?.maxValue : undefined}
							required="Enter maximum value here"
							error={errors.maxValue}
						/>
						{errors.maxValue && (
							<p className="text-red-500 text-sm mt-1">
								{errors.maxValue.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Applicable Value Period" for="applicableValuePeriod" />
						<select
							defaultValue={
								isEditForm ? formDataValues?.applicableValuePeriod : ""
							}
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
								errors.applicableValuePeriod
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							{...register("applicableValuePeriod", {
								required: "Select applicable value period here",
							})}
						>
							<option value="">Select Value</option>
							<option value="MONTH">Month</option>
							<option value="QUARTER">Quarter</option>
							<option value="YEAR">Year</option>
						</select>
						{errors.applicableValuePeriod && (
							<p className="text-red-500 text-sm mt-1">
								{errors.applicableValuePeriod.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label
							title="Minimum Employment Period"
							for="minEmploymentPeriod"
							optional
						/>
						<div className="flex items-center text-sm">
							<input
								placeholder={"0"}
								type="number"
								{...register("minEmploymentPeriod", {
									required: employmentPeriodUnit 
										? "Enter minimum employment period or clear unit" 
										: false,
								})}
								id="minEmploymentPeriod"
								min={1}
								className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 ${
									errors.minEmploymentPeriod
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
							/>
							<select
								{...register("employmentPeriodUnit", {
									required: minEmploymentPeriod 
										? "Select unit or clear minimum employment period" 
										: false,
								})}
								className={`dark ${
									errors.employmentPeriodUnit
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
							>
								<option value="">Select Unit</option>
								<option value="DAY">Day</option>
								<option value="MONTH">Month</option>
								<option value="YEAR">Year</option>
							</select>
						</div>
						{(errors.minEmploymentPeriod || errors.employmentPeriodUnit) && (
							<p className="text-red-500 text-sm mt-1">
								{errors.minEmploymentPeriod?.message || errors.employmentPeriodUnit?.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Assign Pay Periods" for="payPeriodIds" optional />
						<MultiSelectField
							key={"payPeriodIds"}
							options={payPeriods}
							setResourceIds={setPayPeriodIds}
							placeholder={"Select Pay Periods"}
							pathname={PATHS.SUB_COMPANY_SETTINGS.PAY_PERIODS}
							mapText={"code"}
							required={false}
							defaultValues={
								!isEditForm 
									? [] 
									: (formDataValues?.payPeriods?.map(payPeriod => payPeriod.id) || [])
							}
						/>
					</div>
					<div className="w-full">
						<Label title="Assign Employees" for="employeeIds" optional />
						<MultiSelectField
							key={"employeeIds"}
							options={employees}
							setResourceIds={setEmployeeIds}
							placeholder={"Select Employees"}
							pathname={PATHS.EMPLOYEES}
							required={false}
							mapTexts={["firstName", "lastName"]}
							defaultValues={
								!isEditForm 
									? [] 
									: (formDataValues?.employeeAllowances?.map(
										employeeAllowance => employeeAllowance?.employee.id) || []
									)
							}
						/>
					</div>
					<div className="w-full">
						<Label title="Assign Employee Bands" for="employeeBandIds" optional />
						<MultiSelectField
							key={"employeeBandIds"}
							options={employeeBands}
							setResourceIds={setEmployeeBandIds}
							placeholder={"Select Bands"}
							pathname={PATHS.SUB_COMPANY_SETTINGS.BANDS}
							required={false}
							defaultValues={
								!isEditForm 
									? [] 
									: (formDataValues?.employeeBandAllowances?.map(
										employeeBandAllowance => employeeBandAllowance?.employeeBand.id) || []
									)
							}
						/>
					</div>
					<div className="col-span-2">
						<div className="flex space-x-12 text-base">
							<h3>Prorate</h3>
							<div className="flex space-x-3 items-center">
								<input
									{...register("prorate")}
									defaultChecked={isEditForm && formDataValues?.prorate === true}
									type="radio"
									value={"yes"}
									id="prorate"
								/>
								<label htmlFor="prorate">Yes</label>
							</div>
							<div className="flex space-x-3 items-center">
								<input
									{...register("prorate")}
									defaultChecked={
										isEditForm && formDataValues?.prorate === false
									}
									type="radio"
									value={"no"}
									id="no-prorate"
								/>
								<label htmlFor="no-prorate">No</label>
							</div>
						</div>
					</div>
					{errors.prorate && (
						<p className="text-red-500 text-sm mt-1">
							{errors.prorate.message}
						</p>
					)}
					<div className="col-span-2">
						<div className="flex space-x-12 text-base">
							<h3>Active</h3>
							<div className="flex space-x-3 items-center">
								<input
									{...register("active")}
									defaultChecked={isEditForm && formDataValues?.active === true}
									type="radio"
									value={"yes"}
									id="active"
								/>
								<label htmlFor="active">Yes</label>
							</div>
							<div className="flex space-x-3 items-center">
								<input
									{...register("active")}
									defaultChecked={isEditForm && formDataValues?.active === false}
									type="radio"
									value={"no"}
									id="inactive"
								/>
								<label htmlFor="inactive">No</label>
							</div>
						</div>
						{errors.active && (
							<p className="text-red-500 text-sm mt-1">
								{errors.active.message}
							</p>
						)}
					</div>
				</div>

				<div className="w-full flex justify-end mt-8">
					<OrangeButton
						type="submit"
						title={
							uploading
							? "Uploading..."
							: isEditForm
								? "Update Allowance"
								: "Add Allowance"
						}
						className=""
						disabled={uploading}
					/>
				</div>
			</form>
		</ModalComponent>
	);
};

export default AllowanceModalForm;
