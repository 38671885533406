import { Popover } from "@mui/material";
import { useState } from "react";
import { IoIosArrowUp } from "react-icons/io";
import { useSearchParams } from "react-router-dom";
import { generateFilter } from "../../../helpers/useManageTable";
import { LuFilter } from "react-icons/lu";
import { MdOutlineArrowUpward, MdOutlineArrowDownward } from "react-icons/md";
import { enumToStringConverter, generateKey } from "../../../helpers/appHelpers";
import { TableFilterField } from "./model";

type TableFilterProps = {
    fields: TableFilterField[];
    filterBoxWidth?: string;
    filterSectionMaxHeight?: string;

    // For cases where search params are not used
    externalFilter?: any;
	setExternalFilter?: React.Dispatch<React.SetStateAction<any>>;
	setSearching?: React.Dispatch<React.SetStateAction<boolean>>;
    searchOption?: boolean;
}

const TableFilter = ({ 
    fields,
    filterBoxWidth,
    filterSectionMaxHeight,
    externalFilter,
    setExternalFilter,
    setSearching,
    searchOption,
}: TableFilterProps) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [sortType, setSortType] = useState("");
	const [filter, setFilter] = useState<any>({ 
        ...(externalFilter ? externalFilter : generateFilter(searchParams))
    });
    const [dropdown, setDropdown] = useState<any>(
        fields.reduce((acc, _, index) => ({ ...acc, [`${index}`]: false }), {})
    );

    const addFilterValue = (field: string, value: string, clear = true) => {
        setFilter(previousValue => {
            if (previousValue[field] === value && clear) {
                return { ...previousValue, [field]: undefined }
            }
            return { ...previousValue, [field]: value || undefined };
        });
    };

    const handleSortParameter = (type: "asc" | "desc") => {
        setSortType(":" + type);

        if (!filter.orderBy) return;

        const fieldName = (filter.orderBy as string).split(":");

        setFilter(previousValue => (
            { ...previousValue, orderBy: `${fieldName[0]}:${type}` }
        ));
    };

    const toggleDropdown = (field: string) => {
        setDropdown(previousValue => {
            if (previousValue[field]) {
                return { ...previousValue, [field]: false };
            }
            return { ...previousValue, [field]: true };
        });
    };

    const applyFilter = () => {
        const entries = Object.entries({ 
            page: 1, 
            filter: !setExternalFilter ? true : undefined,
            ...filter,
        });

        const view = searchParams.get("view");
        const taxRule = searchParams.get("taxRule");
        const searching = searchParams.get("searching");

        if (view) entries.push(["view", view]);
        if (taxRule) entries.push(["taxRule", taxRule]);
        if (searching) entries.push(["clearSearch", "true"]);
      
        let filteredEntries: any = {};

        entries.forEach(([key, value]) => {
            if (value !== undefined) {
                switch(value) {
                    case "yes":
                        filteredEntries[key] = true;
                        break;
                    case "no":
                        filteredEntries[key] = false;
                        break;
                    default:
                        filteredEntries[key] = value;
                }
            }
        });

        if (setExternalFilter) {
			setSearching && setSearching(false);
            setExternalFilter(filteredEntries);
            searchOption && setSearchParams({ ...filteredEntries });
        } else {
            setSearchParams({ ...filteredEntries });
        }
      
        setAnchorEl(null);
    };

    const clearFilter = () => {
        if (setExternalFilter) {
            setExternalFilter({});
        } else {
            const view = searchParams.get("view");
    
            if (view) {
                setSearchParams({ view, page: "1" });
            } else {
                setSearchParams({ page: "1" });
            }
        }

        setFilter({});
        setAnchorEl(null);
    };

    return (<>
        <button 
            onClick={(e) => setAnchorEl(e.currentTarget)}
            className="w-fit h-[44px] px-5 rounded-lg bg-black text-white flex items-center gap-2 text-sm font-medium"
        >
            <span>Filter</span>
            <LuFilter />
        </button>
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
            PaperProps={{
                className: "table-filter",
                style: {
                    width: filterBoxWidth || "330px",
                    borderRadius: 8,
                    padding: "16px 0",
                    display: "flex",
                    flexDirection: "column",
                    fontSize: "15px",
                    boxShadow: "-10px -5px 30px 0px rgba(0, 0, 0, 0.15)",
                },
            }}
        >
            <h4 className="font-bold px-6">Filter</h4>
            <section 
                className="w-full flex flex-col mb-4 mt-2 px-6 overflow-y-auto"
                style={{ maxHeight: filterSectionMaxHeight || "450px" }}
            >
                {fields.map((field, index) => {
                    switch(field.fieldType) {
                        case "SORT":
                            return (
                                <div key={generateKey()} className="w-full border-b border-[#B4B7BD]">
                                    <button 
                                        className="w-full h-[48px] flex items-center justify-between"
                                        onClick={() => toggleDropdown(`${index}`)}
                                    >
                                        <span>Sort</span>
                                        <IoIosArrowUp 
                                            className={`text-[#292D30] transform-ease 
                                                ${dropdown[`${index}`] && "rotate-180"}`
                                            } 
                                        />
                                    </button>
                                    {dropdown[`${index}`] && (<>
                                        <div className="w-full flex flex-col gap-4 mb-4">
                                            {field.options.map((option, index) => (
                                                <div key={index} className="flex items-center">
                                                    <input
                                                        checked={
                                                            filter.orderBy?.slice(0, option.fieldName.length) === option.fieldName
                                                        }
                                                        type="radio"
                                                        value={option.fieldName}
                                                        id={option.fieldName}
                                                        onClick={(e) => addFilterValue(
                                                            "orderBy", 
                                                            `${option.fieldName}${sortType || ":asc"}`
                                                        )}
                                                        onChange={() => {}}
                                                    />
                                                    <label 
                                                        htmlFor={option.fieldName} 
                                                        className="pl-3"
                                                    > 
                                                        {option.title}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="mb-4 flex items-center gap-2.5 text-[11px]">
                                            <button 
                                                onClick={() => handleSortParameter("asc")}
                                                className={`${filter.orderBy?.slice(-3) === "asc" && "sort-active"} px-2.5 py-2 
                                                    flex items-center gap-1 rounded-full border border-[#4F4F4F] hover:shadow-sm
                                                `}
                                            >
                                                <span>ASC</span>
                                                <MdOutlineArrowUpward />
                                            </button>
                                            <button 
                                                onClick={() => handleSortParameter("desc")}
                                                className={`${filter.orderBy?.slice(-4) === "desc" && "sort-active"} px-2.5 py-2 
                                                    flex items-center gap-1 rounded-full border border-[#4F4F4F] hover:shadow-sm
                                                `}
                                            >
                                                <span>DESC</span>
                                                <MdOutlineArrowDownward />
                                            </button>
                                        </div>
                                    </>)}
                                </div>
                            )
                        case "CHECKBOX_LIST":
                            return (
                                <div key={generateKey()} className="w-full border-b border-[#B4B7BD]">
                                    <button 
                                        className="w-full h-[48px] flex items-center justify-between"
                                        onClick={() => toggleDropdown(`${index}`)}
                                    >
                                        <span>{field.title}</span>
                                        <IoIosArrowUp 
                                            className={`text-[#292D30] transform-ease 
                                                ${dropdown[`${index}`] && "rotate-180"}`
                                            } 
                                        />
                                    </button>
                                    {dropdown[`${index}`] && (
                                        <div className="w-full flex flex-col gap-4 mb-4">
                                            {Object.entries(field.options).map(([key, value]) => (
                                                <div key={key} className="flex items-center">
                                                    <input
                                                        checked={filter[field.fieldName] === value}
                                                        type="checkbox"
                                                        value={value as any}
                                                        id={(value as any).toLowerCase()}
                                                        onChange={(e) => addFilterValue(field.fieldName, e.target.value)}
                                                    />
                                                    <label 
                                                        htmlFor={(value as any).toLowerCase()} 
                                                        className="pl-3 capitalize"
                                                    >
                                                        {enumToStringConverter(value as any)}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            )
                        case "YES_NO":
                            return (
                                <div key={generateKey()} className="w-full border-b border-[#B4B7BD]">
                                    <button 
                                        className="w-full h-[48px] flex items-center justify-between"
                                        onClick={() => toggleDropdown(`${index}`)}
                                    >
                                        <span>{field.title}</span>
                                        <IoIosArrowUp 
                                            className={`text-[#292D30] transform-ease 
                                                ${dropdown[`${index}`] && "rotate-180"}`
                                            } 
                                        />
                                    </button>
                                    {dropdown[`${index}`] && (
                                        <div className="w-full flex flex-col gap-4 mb-4">
                                            <div className="flex items-center">
                                                <input
                                                    checked={filter[field.fieldName] === "yes"}
                                                    type="radio"
                                                    value={"yes"}
                                                    id={field.fieldName + "-yes"}
                                                    onClick={() => addFilterValue(field.fieldName, "yes")}
                                                    onChange={() => {}}
                                                />
                                                <label 
                                                    htmlFor={field.fieldName + "-yes"} 
                                                    className="pl-3"
                                                > Yes
                                                </label>
                                            </div>
                                            <div className="flex items-center">
                                                <input
                                                    checked={filter[field.fieldName] === "no"}
                                                    type="radio"
                                                    value={"no"}
                                                    id={field.fieldName + "-no"}
                                                    onClick={() => addFilterValue(field.fieldName, "no")}
                                                    onChange={() => {}}
                                                />
                                                <label 
                                                    htmlFor={field.fieldName + "-no"} 
                                                    className="pl-3"
                                                > No
                                                </label>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )
                        case "SELECT_FIELD":
                            return (
                                <div key={generateKey()} className="w-full border-b border-[#B4B7BD]">
                                    <button 
                                        className="w-full h-[48px] flex items-center justify-between"
                                        onClick={() => toggleDropdown(`${index}`)}
                                    >
                                        <span>{field.title}</span>
                                        <IoIosArrowUp 
                                            className={`text-[#292D30] transform-ease 
                                                ${dropdown[`${index}`] && "rotate-180"}`
                                            } 
                                        />
                                    </button>
                                    {dropdown[`${index}`] && (
                                        <div className="w-full flex flex-col gap-4 mb-4 text-[13px]">
                                            <select
                                                defaultValue={filter[field.fieldName]}
                                                id={field.fieldName}
                                                className="filter w-full bg-secondary-gray rounded-lg h-12 px-5 mt-1.5 text-sm"
                                                onChange={(e) => addFilterValue(field.fieldName, e.target.value, false)}
                                            >
                                                <option value="">Select Option</option>
                                                {field.options.map((option) => (
                                                    <option 
                                                        key={field.mapKey ? option[field.mapKey] : option.id} 
                                                        value={field.mapValue ? option[field.mapValue] : option.id}
                                                    >
                                                        {field.mapTexts 
                                                            ? field.mapTexts.reduce((sum, key) => sum + `${option[key]} `, "")
                                                            : field.mapText 
                                                                ? option[field.mapText] 
                                                                : option.name
                                                        }
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    )}
                                </div>
                            )
                        case "DATE_RANGE":
                            return (
                                <div key={generateKey()} className="w-full border-b border-[#B4B7BD]">
                                    <button 
                                        className="w-full h-[48px] flex items-center justify-between"
                                        onClick={() => toggleDropdown(`${index}`)}
                                    >
                                        <span>{field.title}</span>
                                        <IoIosArrowUp 
                                            className={`text-[#292D30] transform-ease 
                                                ${dropdown[`${index}`] && "rotate-180"}`
                                            } 
                                        />
                                    </button>
                                    {dropdown[`${index}`] && (
                                        <div className="w-full flex flex-col gap-4 mb-4 text-[13px]">
                                            <div className="w-full">
                                                <label htmlFor="startDate">Start</label>
                                                <input
                                                    defaultValue={filter[field.fieldName + ".gte"]}
                                                    type="date"
                                                    id="startDate"
                                                    className="w-full bg-secondary-gray rounded-lg h-12 px-5 mt-1.5 text-sm"
                                                    onChange={(e) => 
                                                        addFilterValue(
                                                            field.fieldName + ".gte", 
                                                            e.target.value, 
                                                            false
                                                        )
                                                    }
                                                />
                                            </div>
                                            <div className="w-full">
                                                <label htmlFor="endDate">End</label>
                                                <input
                                                    defaultValue={filter[field.fieldName + ".lte"]}
                                                    type="date"
                                                    id="endDate"
                                                    className="w-full bg-secondary-gray rounded-lg h-12 px-5 mt-1.5 text-sm"
                                                    onChange={(e) => 
                                                        addFilterValue(
                                                            field.fieldName + ".lte", 
                                                            e.target.value, 
                                                            false
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )
                        case "INPUT_FIELD":
                            return (
                                <div key={generateKey()} className="w-full border-b border-[#B4B7BD]">
                                    <button 
                                        className="w-full h-[48px] flex items-center justify-between"
                                        onClick={() => toggleDropdown(`${index}`)}
                                    >
                                        <span>{field.title}</span>
                                        <IoIosArrowUp 
                                            className={`text-[#292D30] transform-ease 
                                                ${dropdown[`${index}`] && "rotate-180"}`
                                            } 
                                        />
                                    </button>
                                    {dropdown[`${index}`] && (
                                        <div className="w-full flex flex-col gap-4 mb-4 text-[13px]">
                                            <input
                                                defaultValue={filter[field.fieldName]}
                                                type={field.inputType || "text"}
                                                placeholder="Enter value"
                                                id={field.fieldName}
                                                className="w-full bg-secondary-gray rounded-lg h-12 px-5 mt-1.5 text-sm"
                                                onChange={(e) => addFilterValue(field.fieldName, e.target.value, false)}
                                                { ...field.attributes }
                                            />
                                        </div>
                                    )}
                                </div>
                            )
                    }
                })}
            </section>
            <div className="w-full flex items-center gap-4 px-6 font-medium">
                <button 
                    onClick={applyFilter}
                    className="w-full py-3 px-4 bg-primary-mango rounded-[10px] text-white hover:shadow-sm"
                >
                    Apply Filter
                </button>
                <button
                    onClick={clearFilter}
                    className="w-full py-3 px-4 border text-gray-400 hover:text-gray-500 border-gray-300 rounded-[10px]"
                >
                    Clear Filter
                </button>
            </div>
        </Popover>
    </>);
}
 
export default TableFilter;