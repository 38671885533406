import { useEffect, useState } from "react";
import OnboardingLayout from "../../components/layouts/OnboardingLayout";
import OrangeButton from "../../components/buttons/OrangeButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import Label from "../../components/form_fields/Label";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { Helmet } from "react-helmet-async";
import { 
	useGetAuthDefaultPasswordPolicy, 
	useGetAuthPasswordPolicyById, 
	useResetPassword 
} from "../../services/auth.service";
import { PATHS } from "../../routes/routes.paths";
import { toast } from "react-toastify";
import useUserStore from "../../state-management/useUserStore";
import { validatePassword, PasswordRequirementModal } from "../../helpers/useValidatePassword";
import { PasswordPolicyPublicDto } from "../../models/password-policy.dto";


interface IFormInputs {
	newPassword: string;
	confirmPassword: string;
}

const ResetPassword = () => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
    const token = searchParams.get("code");
    const username = searchParams.get("username");
    const organizationId = searchParams.get("ouid");
    const [passwordPolicy, setPasswowrdPolicy] = useState<PasswordPolicyPublicDto>();
    const [passwordRequirementPopup, setPasswordRequirementPopup] = useState(false);
    const [validPassword, setValidPassword] = useState(false);
	const { setCurrentUser, setUserType } = useUserStore();
	const [isShown, setIsShown] = useState(false);

    const { mutate: getAuthPasswordPolicyById } = useGetAuthPasswordPolicyById();
    const { mutate: getAuthDefaultPasswordPolicy } = useGetAuthDefaultPasswordPolicy();

    useEffect(() => {
        if (organizationId) {
            getAuthPasswordPolicyById(
                { organizationId },
                { onSuccess: (data) => setPasswowrdPolicy(data.data) }
            )
        } else {
            getAuthDefaultPasswordPolicy(
                {}, { onSuccess: (data) => setPasswowrdPolicy(data.data) }
            )
        }
    }, [organizationId])

	const {
		register,
        watch,
        handleSubmit,
        getValues,
        formState: {errors},
    } = useForm<IFormInputs>();

    const newPassword = watch("newPassword");

    useEffect(() => {
        if (!newPassword || !passwordPolicy) {
            setValidPassword(false);
            return
        }

        const isValid = validatePassword(newPassword, passwordPolicy);
        setValidPassword(isValid);
    }, [newPassword])

    const { 
        mutate: resetPassword, 
        isLoading: resettingPassword  
    } = useResetPassword();

	const onSubmit: SubmitHandler<IFormInputs> = (formData) => {
        if(!token){
            toast.error('Token not found');
            return
        }

		resetPassword(
            { 
                payload: {
                    token,
                    password: formData.newPassword
                }
            },
			{
                onSuccess: (data) => {
					toast.success('Password updated successfully');

					if (!data.user?.companyProfiles || !data.user?.companyProfiles[0]) {
						navigate(PATHS.SIGN_IN);
						return
					}

					setCurrentUser({ ...data, loginTime: new Date() });

					const userCategory = data.user.category;
					const adminUser = data.user.organization?.billingType.admin;

					switch(userCategory.toLowerCase()) {
						case "operations":
							if (adminUser) {
								setUserType("ADMIN");
								navigate(PATHS.ADMIN_ACCOUNTS_MANAGEMENT);
							} else {
								setUserType("ORGANIZATION");
								navigate(PATHS.OVERVIEW);
							}
							break;
						case "hr":
							setUserType("HR");
							navigate(PATHS.HR_EMPLOYEE_MANAGEMENT);
							break;
						case "employee":
							setUserType("EMPLOYEE");
							navigate(PATHS.SELF_SERVICE_DASHBOARD);
							break;
					}
                },
                onError: (error) => {
                    if (
                        error.response?.data?.error === "OTP_EXPIRED" || 
                        error.response?.data?.message === "OTP has expired"
                    ) {
                        navigate(PATHS.FORGOT_PASSWORD);
                    }
                },
			}
		);
	};

	return (
		<OnboardingLayout>
			<Helmet>
				<title>Reset Password | Akatua</title>
			</Helmet>
			<div className="flex flex-col px-24 justify-center w-full">
				<img src="/assets/logos/akatua-logo.svg" alt="akatua" width={130} />
				<h3 className="mt-6 text-lg font-normal">
					Reset your password? Complete the form below.
				</h3>
				<form 
					onSubmit={handleSubmit(onSubmit)} 
					className="text-charcoal mt-4"
				>
					<div className="mt-6 flex items-center w-full space-x-4">
						<div className="w-full">
							<Label title="New Password" for="newPassword" />
							<div className="relative">
								<input
									type={isShown === false ? "password" : "text"}
									id={"newPassword"}
									placeholder={"Enter new password"}
									className={`w-full bg-secondary-gray rounded-lg py-4 px-4 mt-2 bg text-sm  ${
										errors.newPassword
											? " border border-red-500 focus:border-red-500 focus:outline-red-500"
											: "border-none"
									}`}
									{...register("newPassword", {
										required: "Enter your new password here",
									})}
									autoComplete="off"
									disabled={!passwordPolicy}
									onClick={() => setPasswordRequirementPopup(true)}
									onBlur={() => setPasswordRequirementPopup(false)}
								/>
								<div
									className={`absolute top-[26px] right-6 block `}
									onClick={() => setIsShown(prev => !prev)}
								>
									{isShown === false ? (
										<BsEyeSlash className="text-gray-400 text-lg" />
									) : (
										<BsEye className="text-gray-400 text-lg" />
									)}
								</div>
								{(!validPassword && passwordPolicy && passwordRequirementPopup) && (
									<PasswordRequirementModal 
										passwordPolicy={passwordPolicy} 
										password={newPassword} 
									/> 
								)}
							</div>
							{errors.newPassword && (
								<p className="text-red-500 text-sm mt-1">
									{errors.newPassword.message}
								</p>
							)}
						</div>
						<div className="w-full">
							<Label title="Confirm Password" for="confirmPassword" />
							<div className="relative">
								<input
									type={isShown === false ? "password" : "text"}
									id={"confirmPassword"}
									placeholder={"Re-Enter new password"}
									className={`w-full bg-secondary-gray rounded-lg py-4 px-4 mt-2 bg text-sm  ${
										errors.confirmPassword
											? " border border-red-500 focus:border-red-500 focus:outline-red-500"
											: "border-none"
									}`}
									{...register("confirmPassword", {
										required: "Confirm your new password",
										validate: {
											matchesPreviousPassword: (value) => {
												const { newPassword } = getValues();
												return (
													newPassword === value || "Passwords should match!"
												);
											},
										},
									})}
									autoComplete="off"
									disabled={!passwordPolicy}
								/>
								<div
									className={`absolute top-[26px] right-6 block `}
									onClick={() => setIsShown(prev => !prev)}
								>
									{isShown === false ? (
										<BsEyeSlash className="text-gray-400 text-lg" />
									) : (
										<BsEye className="text-gray-400 text-lg" />
									)}
								</div>
							</div>
							{errors.confirmPassword && (
								<p className="text-red-500 text-sm mt-1">
									{errors.confirmPassword.message}
								</p>
							)}
						</div>
					</div>

					<div className="w-[50%]">
						<OrangeButton
							title={
								passwordPolicy
									? resettingPassword 
										? "Resetting Password..." 
										: "Reset Password"
									: "Fetching Password Requirements..."
							}
							className="w-full mt-10 font-light"
							type="submit"
							disabled={!passwordPolicy || !validPassword || resettingPassword}
						/>
					</div>
				</form>
			</div>
		</OnboardingLayout>
	);
};

export default ResetPassword;
