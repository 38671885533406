import { useEffect, useState } from "react";
import PayrollCompanyLayout from "../../../components/layouts/PayrollCompanyLayout";
import { Helmet } from "react-helmet-async";
import CustomTab from "../../../components/custom_tab/CustomTab";
import { PayrollTabColumns } from "../../../components/custom_tab/tabColumns";
import { useNavigate, useSearchParams } from "react-router-dom";
import useUserStore from "../../../state-management/useUserStore";
import HumanResourceLayout from "../../../components/layouts/HumanResourceLayout";
import CompanyPayrollRuns from "./views/CompanyPayrollRuns";
import CompanyPayslips from "./views/CompanyPayslips";
import useCompanyStore from "../../../state-management/useCompanyStore";
import { useAsyncEffect, useLockFn } from "ahooks";
import API from '../../../services/utils/api';
import { useCheckUserPermissions } from "../../../helpers/useCheckUserPermissions";
import { PATHS } from "../../../routes/routes.paths";
import CompanyPayrollReports from "./views/CompanyPayrollReports";

const Payroll = () => {
	const { currentCompany } = useCompanyStore();
	const { userType } = useUserStore();
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const checkUserPermissions = useCheckUserPermissions();
	const [view, setView] = useState(
		searchParams.get("view") || "companyPayrollRuns"
	);
	const [recentPayrollRun, setRecentPayrollRun] = useState<any>();

	useEffect(() => {
		if (!checkUserPermissions(["salary:read", "salary:write"])) {
			navigate(PATHS.FORBIDDEN);
		}
	}, [])

	useAsyncEffect(useLockFn(async () => {
		try {
			const response = await API.PayrollAPI.getRecentPayrollRun(
				currentCompany?.id as number, {}
			);
			setRecentPayrollRun(response.data);
		} catch (error) {}
	}), [currentCompany])
	
	return (<>
		{userType === "ORGANIZATION" ? (
			<PayrollCompanyLayout>
				<Helmet>
					<title>Payroll | Company Flow</title>
				</Helmet>
				<h1 className="font-bold text-xl mb-8">Payroll</h1>
				<CustomTab
					view={view}
					setView={setView}
					tabColumns={PayrollTabColumns}
				>
					{view === "companyPayrollRuns" && <CompanyPayrollRuns />}
					{view === "companyPayslips" && <CompanyPayslips defaultPayPeriod={recentPayrollRun} />}
					{view === "companyPayrollReports" && <CompanyPayrollReports defaultPayPeriod={recentPayrollRun} />}
				</CustomTab>
			</PayrollCompanyLayout>
		):(
			<HumanResourceLayout>
				<Helmet>
					<title>Payroll Management | HR Flow</title>
				</Helmet>
				<h1 className="font-bold text-xl mb-8">Payroll Management</h1>
				<CustomTab
					view={view}
					setView={setView}
					tabColumns={PayrollTabColumns}
				>
					{view === "companyPayrollRuns" && <CompanyPayrollRuns />}
					{view === "companyPayslips" && <CompanyPayslips defaultPayPeriod={recentPayrollRun} />}
					{view === "companyPayrollReports" && <CompanyPayrollReports defaultPayPeriod={recentPayrollRun} />}
				</CustomTab>
			</HumanResourceLayout>
		)}
	</>);
};

export default Payroll;
