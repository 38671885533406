const REACT_APP_USER_API=process.env.REACT_APP_USER_API;
const REACT_APP_ORGANIZATION_API=process.env.REACT_APP_ORGANIZATION_API;
const REACT_APP_BILLING_AND_SUBSCRIPTION_API=process.env.REACT_APP_BILLING_AND_SUBSCRIPTION_API;
const REACT_APP_PROCESSING_COMPANY_API=process.env.REACT_APP_PROCESSING_COMPANY_API;
const REACT_APP_DOCUMENT_API=process.env.REACT_APP_DOCUMENT_API;
const REACT_APP_HR_API=process.env.REACT_APP_HR_API;
const REACT_APP_PAYROLL_API=process.env.REACT_APP_PAYROLL_API;
const REACT_APP_AUDIT_API=process.env.REACT_APP_AUDIT_API;


export default {
    PROCESSING_COMPANY: {
        COMPANY: {
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/payroll-companies',
            GET_COUNT: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/payroll-companies/count',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/payroll-companies/{companyId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/payroll-companies',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/payroll-companies/{companyId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/payroll-companies/{companyId}',
        },
        EMPLOYEE: {
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employees',
            SEARCH: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employees/search',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employees/{employeeId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employees',
            GET_COUNT: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employees/count',
            UPLOAD: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employees/upload/{companyId}',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employees/{employeeId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employees/{employeeId}',
            TERMINATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employees/{employeeId}/termination',
            UPLOAD_REVISION: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/payroll-companies/{companyId}/uploads/employees/revision',
        },
        COST_CENTER: {
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/cost-centres',
            SEARCH: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/cost-centres/search',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/cost-centres/{costCenterId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/cost-centres',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/cost-centres/{costCenterId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/cost-centres/{costCenterId}',
        },
        COMPANY_GROUPING: {
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/company-groupings',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/company-groupings/{groupingId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/company-groupings',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/company-groupings/{groupingId}',
            BY_TYPE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/company-groupings/{type}/{companyId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/company-groupings/{groupingId}',
        },
        PAY_PERIOD: {
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/pay-periods',
            SEARCH: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/pay-periods/search',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/pay-periods/{payPeriodId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/pay-periods',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/pay-periods/{payPeriodId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/pay-periods/{payPeriodId}'
        },
        TAX: {
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/tax',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/tax/{taxId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/tax',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/tax/{taxId}'
        },
        NOTCHES: {
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/notches',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/notches/{notchId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/notches',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/notches/{notchId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/notches/{notchId}'
        },
        HOLIDAY: {
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/holidays',
            SEARCH: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/holidays/search',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/holidays/{holidayId}',
            GETBYCODE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/holidays/code/{code}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/holidays',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/holidays/{holidayId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/holidays/{holidayId}'
        },
        ASSETS_TYPE: {
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/asset-types',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/asset-types/{assetId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/asset-types',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/asset-types/{assetId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/asset-types/{assetId}'
        },
        GRADE_LEVEL:{
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/grade-levels',
            SEARCH: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/grade-levels/search',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/grade-levels/{gradeLevelId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/grade-levels',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/grade-levels/{gradeLevelId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/grade-levels/{gradeLevelId}'
        },
        ALLOWANCE:{
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/allowances',
            SEARCH: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/allowances/search',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/allowances/{allowanceId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/allowances',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/allowances/{allowanceId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/allowances/{allowanceId}'
        },
        BENEFIT:{
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/benefits',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/benefits/{benefitId}',
            SEARCH: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/benefits/search',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/benefits',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/benefits/{benefitId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/benefits/{benefitId}'
        },
        PENSION:{
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/pensions',
            SEARCH: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/pensions/search',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/pensions/{pensionId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/pensions',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/pensions/{pensionId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/pensions/{pensionId}'
        },
        BONUS:{
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/bonuses',
            SEARCH: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/bonuses/search',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/bonuses/{bonusId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/bonuses',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/bonuses/{bonusId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/bonuses/{bonusId}'
        },
        UNION:{
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/unions',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/unions/{unionId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/unions',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/unions/{unionId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/unions/{unionId}'
        },
        SAVINGS_SCHEME:{
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/savings-schemes',
            SEARCH: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/savings-schemes/search',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/savings-schemes/{savingsSchemeId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/savings-schemes',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/savings-schemes/{savingsSchemeId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/savings-schemes/{savingsSchemeId}'
        },
        ASSIGN_ASSET:{
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/assigned-assets',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/assigned-assets/{assignedAssetId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/assigned-assets',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/assigned-assets/{assignedAssetId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/assigned-assets/{assignedAssetId}'
        },
        EMPLOYEE_QUALIFICATION:{
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-qualifications',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-qualifications/{qualificationId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-qualifications',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-qualifications/{qualificationId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-qualifications/{qualificationId}'
        },
        EMPLOYEE_RELATIVE:{
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-relatives',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-relatives/{relativeId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-relatives',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-relatives/{relativeId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-relatives/{relativeId}'
        },
        EMPLOYEE_PAY_INFO:{
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-pay-infos',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-pay-infos/{payInfoId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-pay-infos',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-pay-infos/{payInfoId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-pay-infos/{payInfoId}'
        },
        EMPLOYEE_ACCOUNT_INFO:{
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-account-info',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-account-info/{accountId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-account-info',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-account-info/{accountId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-account-info/{accountId}'
        },
        EMPLOYEE_UNION:{
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-unions',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-unions/{employeeUnionId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-unions',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-unions/{employeeUnionId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-unions/{employeeUnionId}'
        },
        NATIONALITY:{
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/nationalities',
            SEARCH: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/nationalities/search',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/nationalities/{nationalityId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/nationalities',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/nationalities/{nationalityId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/nationalities/{nationalityId}'
        },
        CONTRACT_TYPE:{
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/contract-types',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/contract-types/{contractTypeId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/contract-types',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/contract-types/{contractTypeId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/contract-types/{contractTypeId}'
        },
        DISABILITIES:{
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/disabilities',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/disabilities/{disabilityId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/disabilities',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/disabilities/{disabilityId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/disabilities/{disabilityId}'
        },
        WORK_DAYS_IN_A_PERIOD:{
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/work-days-in-a-period',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/work-days-in-a-period/{workId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/work-days-in-a-period',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/work-days-in-a-period/{workId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/work-days-in-a-period/{workId}'
        },
        EMPLOYEE_BAND:{
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-bands',
            SEARCH: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-bands/search',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-bands/{bandId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-bands',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-bands/{bandId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-bands/{bandId}'
        },
        CUSTOM_TAX_RELIEF:{
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/custom-tax-reliefs',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/custom-tax-reliefs/{customTaxReliefId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/custom-tax-reliefs',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/custom-tax-reliefs/{customTaxReliefId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/custom-tax-reliefs/{customTaxReliefId}'
        },
        JOB_TITLE:{
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/job-titles',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/job-titles/{jobTitleId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/job-titles',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/job-titles/{jobTitleId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/job-titles/{jobTitleId}'
        },
        EMPLOYEE_EXPLICIT_DEDUCTION:{
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-explicit-deductions',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-explicit-deductions/{explicitDeductionId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-explicit-deductions',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-explicit-deductions/{explicitDeductionId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-explicit-deductions/{explicitDeductionId}'
        },
        OVERTIME:{
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/overtimes',
            SEARCH: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/overtimes/search',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/overtimes/{overtimeId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/overtimes',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/overtimes/{overtimeId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/overtimes/{overtimeId}'
        },
        OVERTIME_PAYMENT_TIER:{
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/overtime-payment-tiers',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/overtime-payment-tiers/{paymentTierId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/overtime-payment-tiers',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/overtime-payment-tiers/{paymentTierId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/overtime-payment-tiers/{paymentTierId}'
        },
        COMPANY_CURRENCY:{
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/company-currency-configs',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/company-currency-configs/{currencyConfigId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/company-currency-configs',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/company-currency-configs/{currencyConfigId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/company-currency-configs/{currencyConfigId}'
        },
        EMPLOYEE_BAND_ALLOWANCE:{
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-band-allowances',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-band-allowances/{bandId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-band-allowances',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-band-allowances/{bandId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-band-allowances/{bandId}'
        },
        EMPLOYEE_BAND_BENEFIT:{
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-band-benefits',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-band-benefits/{bandId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-band-benefits',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-band-benefits/{bandId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-band-benefits/{bandId}'
        },
        EMPLOYEE_BAND_BONUS:{
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-band-bonuses',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-band-bonuses/{bandId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-band-bonuses',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-band-bonuses/{bandId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-band-bonuses/{bandId}'
        },
        EMPLOYEE_BAND_LOAN_BENEFIT:{
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-band-loan-benefits',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-band-loan-benefits/{bandId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-band-loan-benefits',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-band-loan-benefits/{bandId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-band-loan-benefits/{bandId}'
        },
        EMPLOYEE_BAND_SAVINGS_SCHEME:{
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-band-savings-schemes',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-band-savings-schemes/{bandId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-band-savings-schemes',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-band-savings-schemes/{bandId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-band-savings-schemes/{bandId}'
        },
        EXPLICIT_DEDUCTION:{
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/explicit-deductions',
            SEARCH: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/explicit-deductions/search',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/explicit-deductions/{explicitDeductionId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/explicit-deductions',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/explicit-deductions/{explicitDeductionId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/explicit-deductions/{explicitDeductionId}'
        },
        BENEFIT_TAX:{
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/benefit-taxes',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/benefit-taxes/{benefitTaxId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/benefit-taxes',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/benefit-taxes/{benefitTaxId}',
            DELTE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/benefit-taxes/{benefitTaxId}'
        },
        TAX_RATE:{
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/tax-rates',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/tax-rates/{taxRateId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/tax-rates',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/tax-rates/{taxRateId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/tax-rates/{taxRateId}'
        },
        TAX_RELIEF:{
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/tax-reliefs',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/tax-reliefs/{taxReliefId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/tax-reliefs',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/tax-reliefs/{taxReliefId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/tax-reliefs/{taxReliefId}'
        },
        TAX_RELIEF_TYPE:{
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/tax-relief-types',
            SEARCH: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/tax-reliefs/search',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/tax-relief-types/{taxReliefTypeId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/tax-relief-types',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/tax-relief-types/{taxReliefTypeId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/tax-relief-types/{taxReliefTypeId}'
        },
        BACK_PAY:{
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/back-pays',
            SEARCH: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/back-pays/search',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/back-pays/{backPayId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/back-pays',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/back-pays/{backPayId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/back-pays/{backPayId}'
        },
        BENEFIT_TYPE:{
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/benefit-type-instances',
            SEARCH: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/benefit-type-instances/search',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/benefit-type-instances/{benefitTypeId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/benefit-type-instances',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/benefit-type-instances/{benefitTypeId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/benefit-type-instances/{benefitTypeId}'
        },
        INDUSTRIES:{
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/industries',
            SEARCH: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/industries/search',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/industries/{industryId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/industries',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/industries/{industryId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/industries/{industryId}'
        },
        INDUSTRY_BENEFIT_TAXES:{
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/industry-benefit-taxes',
            SEARCH: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/industry-benefit-taxes/search',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/industry-benefit-taxes/{industryBenefitTaxId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/industry-benefit-taxes',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/industry-benefit-taxes/{industryBenefitTaxId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/industry-benefit-taxes/{industryBenefitTaxId}'
        },
        TAX_CODE:{
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/tax-codes',
            SEARCH: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/tax-codes/search',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/tax-codes/{taxCodeId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/tax-codes',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/tax-codes/{taxCodeId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/tax-codes/{taxCodeId}',
            SET_DEFAULT: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/tax-codes/{taxCodeId}/set-default'
        },
        COMPANY_LEVEL_LEAVE_PACKAGE: {
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/company-level-leave-packages',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/company-level-leave-packages/{compLevelLeavePackageId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/company-level-leave-packages',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/company-level-leave-packages/{compLevelLeavePackageId}'
        },
        COMPANY_LEVEL: {
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/company-levels',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/company-levels/{companyLevelId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/company-levels',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/company-levels/{companyLevelId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/company-levels/{companyLevelId}',
        },
        EMPLOYEE_BAND_PENSION:{
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-band-pensions',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-band-pensions/{bandPensionId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-band-pensions',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-band-pensions/{bandPensionId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-band-pensions/{bandPensionId}'
        },
        EMPLOYEE_BAND_CUSTOM_TAX_RELIEF:{
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-band-custom-tax-reliefs',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-band-custom-tax-reliefs/{empBandCustomTaxReliefId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-band-custom-tax-reliefs',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-band-custom-tax-reliefs/{empBandCustomTaxReliefId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-band-custom-tax-reliefs/{empBandCustomTaxReliefId}'
        },
        EMPLOYEE_BAND_EXPLICIT_DEDUCTIONS:{
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-band-explicit-deductions',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-band-explicit-deductions/{empBandExplicitDeductionId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-band-explicit-deductions',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-band-explicit-deductions/{empBandExplicitDeductionId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-band-explicit-deductions/{empBandExplicitDeductionId}'
        },
        EMPLOYEE_BENEFIT:{
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-benefits',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-benefits/{employeeBenefitId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-benefits',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-benefits/{employeeBenefitId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-benefits/{employeeBenefitId}'
        },
        EMPLOYEE_CONTRACT:{
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-contracts',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-contracts/{employeeContractId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-contracts',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-contracts/{employeeContractId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-contracts/{employeeContractId}'
        },
        EMPLOYEE_CUSTOM_TAX_RELIEF:{
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-custom-tax-reliefs',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-custom-tax-reliefs/{empCustomTaxReliefId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-custom-tax-reliefs',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-custom-tax-reliefs/{empCustomTaxReliefId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-custom-tax-reliefs/{empCustomTaxReliefId}'
        },
        EMPLOYEE_LOAN_BENEFIT:{
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-loan-benefits',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-loan-benefits/{empLoanBenefitId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-loan-benefits',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-loan-benefits/{empLoanBenefitId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-loan-benefits/{empLoanBenefitId}'
        },
        EMPLOYEE_SAVINGS_SCHEMES:{
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-savings-schemes',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-savings-schemes/{empSavingsSchemeId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-savings-schemes',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-savings-schemes/{empSavingsSchemeId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-savings-schemes/{empSavingsSchemeId}'
        },
        ERP_INTEGRATION_MAPPING:{
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/erp-integration-mappings',
            SEARCH: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/erp-integration-mappings/search',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/erp-integration-mappings/{erpIntMappingId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/erp-integration-mappings',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/erp-integration-mappings/{erpIntMappingId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/erp-integration-mappings/{erpIntMappingId}'
        },
        LOAN_BENEFIT:{
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/loan-benefits',
            SEARCH: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/loan-benefits/search',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/loan-benefits/{loanBenefitId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/loan-benefits',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/loan-benefits/{loanBenefitId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/loan-benefits/{loanBenefitId}'
        },
        LOAN:{
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/loans',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/loans/{loanId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/loans',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/loans/{loanId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/loans/{loanId}'
        },
        PARTIAL_SALARY:{
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/partial-salaries',
            GET_WITH_PAY_PERIOD: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/partial-salaries/applicable-pay-period/{payPeriodId}',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/partial-salaries/{partialSalaryId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/partial-salaries',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/partial-salaries/{partialSalaryId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/partial-salaries/{partialSalaryId}'
        },
        SALARY_ADVANCE:{
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/salary-advances',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/salary-advances/{salaryAdvanceId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/salary-advances',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/salary-advances/{salaryAdvanceId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/salary-advances/{salaryAdvanceId}'
        },
        DEPARTMENT:{
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/payroll-companies/{companyId}/departments',
            SEARCH: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/payroll-companies/{companyId}/departments/search',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/payroll-companies/{companyId}/departments/{departmentId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/payroll-companies/{companyId}/departments',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/payroll-companies/{companyId}/departments/{departmentId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/payroll-companies/{companyId}/departments/{departmentId}'
        },
        DEPARTMENT_LEADERSHIP:{
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/payroll-companies/{companyId}/department-leadership',
            UNLINK: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/payroll-companies/{companyId}/department-leadership/{leadershipId}/employee',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/payroll-companies/{companyId}/department-leadership/{leadershipId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/payroll-companies/{companyId}/department-leadership',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/payroll-companies/{companyId}/department-leadership/{leadershipId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/payroll-companies/{companyId}/department-leadership/{leadershipId}'
        },
        EMPLOYEE_HISTORY: {
            GET_QUALIFICATIONS: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-history/qualifications',
            GET_JOB_TITLES: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-history/job-titles',
            GET_NOTCHES: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-history/notches',
            GET_BANDS: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-history/bands',
            GET_BASIC_SALARY: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-history/basic-salary',
            GET_ENGAGEMENTS: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-history/engagements',
            GET_DISENGAGEMENTS: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-history/disengagements',
            GET_ALLOWANCES: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-history/allowances',
        },
        EMPLOYEE_ALLOWANCE:{
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-allowances',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-allowances/{employeeAllowanceId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-allowances',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-allowances/{employeeAllowanceId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-allowances/{employeeAllowanceId}'
        },
        TAX_CODE_BENEFIT_RULE:{
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/tax-code-benefit-rules',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/tax-code-benefit-rules/{id}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/tax-code-benefit-rules',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/tax-code-benefit-rules/{id}',
        },
        TAX_CODE_RATE_RULE:{
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/tax-code-rate-rules',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/tax-code-rate-rules/{id}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/tax-code-rate-rules',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/tax-code-rate-rules/{id}',
        },
        TAX_CODE_RELIEF_RULE:{
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/tax-code-relief-rules',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/tax-code-relief-rules/{id}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/tax-code-relief-rules',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/tax-code-relief-rules/{id}',
        },
        EMPLOYEE_BONUS:{
            GET: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-bonuses',
            DETAIL: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-bonuses/{bandId}',
            CREATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-bonuses',
            UPDATE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-bonuses/{bandId}',
            DELETE: REACT_APP_PROCESSING_COMPANY_API + '/api/v1/employee-bonuses/{bandId}'
        },
    },
    USER:{
        GET: REACT_APP_USER_API + '/api/v1/users',
        SEARCH: REACT_APP_USER_API + '/api/v1/users/search',
        CREATE: REACT_APP_USER_API + '/api/v1/users',
        UPDATE: REACT_APP_USER_API + '/api/v1/users/{userId}',
        DETAIL: REACT_APP_USER_API + '/api/v1/users/{userId}',
        UPDATE_SELF: REACT_APP_USER_API + '/api/v1/users/me',
        GET_COUNT: REACT_APP_USER_API + '/api/v1/users/count',
        CHANGE_PASSWORD: REACT_APP_USER_API + '/api/v1/users/me/password',
        ACCOUNT_CHECK: REACT_APP_USER_API + '/api/v1/users/account-check',
        REACTIVATE: REACT_APP_USER_API + '/api/v1/users/{userId}/reactivation',
        DELETE: REACT_APP_USER_API + '/api/v1/users/{userId}',
        AUTH: {
            LOGIN: REACT_APP_USER_API + '/auth/v1/login',
            RESEND: REACT_APP_USER_API + '/auth/v1/account-confirmation/resend',
            CONFIRM: REACT_APP_USER_API + '/auth/v1/confirm',
            FORGOT_PASSWORD: REACT_APP_USER_API + '/auth/v1/forgotpassword',
            PASSWORD: REACT_APP_USER_API + '/auth/v1/password',
            REFRESH_TOKEN: REACT_APP_USER_API + '/auth/v1/token/refresh',
            GET_DEFAULT_PWD_POLICY: REACT_APP_USER_API +'/auth/v1/password-policies/default',
            GET_PWD_POLICY: REACT_APP_USER_API +'/auth/v1/password-policies/organizations/{organizationId}',
            ROTATE_PASSWORD: REACT_APP_USER_API + '/auth/v1/password/rotation', 
            GET_USER_ORG_PWD_POLICY: REACT_APP_USER_API +'/auth/v1/password-policies/organizations/users/{username}',
        },
        ORGANIZATION_ROLE: {
            GET: REACT_APP_USER_API + '/api/v1/organization-roles',
            DETAIL: REACT_APP_USER_API + '/api/v1/organization-roles/{roleId}',
            CREATE: REACT_APP_USER_API + '/api/v1/organization-roles',
            UPDATE: REACT_APP_USER_API + '/api/v1/organization-roles/{roleId}',
        },
        COMPANY_ROLE: {
            GET: REACT_APP_USER_API + '/api/v1/company-roles',
            DETAIL: REACT_APP_USER_API + '/api/v1/company-roles/{roleId}',
            CREATE: REACT_APP_USER_API + '/api/v1/company-roles',
            UPDATE: REACT_APP_USER_API + '/api/v1/company-roles/{roleId}',
        },
        USER_COMPANY_PROFILE: {
            GET: REACT_APP_USER_API + '/api/v1/user-company-profiles',
            DETAIL: REACT_APP_USER_API + '/api/v1/user-company-profiles/{profileId}',
            CREATE: REACT_APP_USER_API + '/api/v1/user-company-profiles',
            UPDATE: REACT_APP_USER_API + '/api/v1/user-company-profiles/{profileId}',
        },
        PERMISSION: {
            GET: REACT_APP_USER_API + '/api/v1/permissions',
            DETAIL: REACT_APP_USER_API + '/api/v1/permissions/{code}',
            CREATE: REACT_APP_USER_API + '/api/v1/permissions',
            UPDATE: REACT_APP_USER_API + '/api/v1/permissions/{code}',
        },
        PERMISSION_GROUP: {
            GET: REACT_APP_USER_API + '/api/v1/permission-groups',
            DETAIL: REACT_APP_USER_API + '/api/v1/permission-groups/{permissionGroupId}',
            CREATE: REACT_APP_USER_API + '/api/v1/permission-groups',
            UPDATE: REACT_APP_USER_API + '/api/v1/permission-groups/{permissionGroupId}',
        },
        PASSWORD_POLICY: {
            CREATE: REACT_APP_USER_API +'/api/v1/password-policies',
            GET_DEFAULT: REACT_APP_USER_API +'/api/v1/password-policies/default',
            GET: REACT_APP_USER_API +'/api/v1/password-policies/organizations/me',
            CLEAR: REACT_APP_USER_API +'/api/v1/password-policies/organizations/me',
        }
    },
    ORGANIZATION: {
        CREATE: REACT_APP_ORGANIZATION_API + '/api/v1/organizations',
        GET: REACT_APP_ORGANIZATION_API + '/api/v1/organizations',
        SEARCH: REACT_APP_ORGANIZATION_API + '/api/v1/organizations/search',
        GET_COUNT: REACT_APP_ORGANIZATION_API + '/api/v1/organizations/count',
        UPDATE: REACT_APP_ORGANIZATION_API + '/api/v1/organizations/{organizationId}',
        UPDATE_BILLING_TYPE: REACT_APP_ORGANIZATION_API +'/organizations/{organizationId}/billing-type',
        ORGANIZATION_CONFIG: {
            CREATE_OR_UPDATE: REACT_APP_ORGANIZATION_API + '/api/v1/organization-configs',
            DETAIL: REACT_APP_ORGANIZATION_API + '/api/v1/organization-configs/{organizationId}',
        },
        ORGANIZATION_PAYMENT_METHOD: {
            GET: REACT_APP_ORGANIZATION_API + '/api/v1/organization-payment-methods',
            DETAIL: REACT_APP_ORGANIZATION_API + '/api/v1/organization-payment-methods/{orgPaymentMethodId}',
            CREATE: REACT_APP_ORGANIZATION_API + '/api/v1/organization-payment-methods',
            UPDATE: REACT_APP_ORGANIZATION_API + '/api/v1/organization-payment-methods/{orgPaymentMethodId}',
        },
        ORGANIZATION_PROMOTION: {
            GET: REACT_APP_ORGANIZATION_API + '/api/v1/organization-promotions',
            DETAIL: REACT_APP_ORGANIZATION_API + '/api/v1/organization-promotions/{orgPromotionId}',
            CREATE: REACT_APP_ORGANIZATION_API + '/api/v1/organization-payment-promotions',
        },
        SUBSCRIPTION_HISTORY: {
            GET: REACT_APP_ORGANIZATION_API + '/api/v1/subscription-history',
            DETAIL: REACT_APP_ORGANIZATION_API + '/api/v1/subscription-history/{subscriptionHistoyId}',
        },

    },
    BILLING_AND_SUBSCRIPTION: {
        COUNTRY:{
            GET: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/countries',
            SEARCH: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/countries/search',
            DETAIL: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/countries/{countryId}',
            CREATE: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/countries',
            UPDATE: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/countries/{countryId}',
            DELETE: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/countries/{countryId}'
        },
        CURRENCY:{
            GET: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/currencies',
            SEARCH: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/currencies/search',
            DETAIL: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/currencies/{currencyId}',
            CREATE: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/currencies',
            UPDATE: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/currencies/{currencyId}',
            DELETE: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/currencies/{currencyId}'
        },
        BILLING_TYPE: {
            GET: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/billing-types',
            DETAIL: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/billing-types/{billingTypeId}',
            CREATE: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/billing-types',
            UPDATE: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/billing-types/{billingTypeId}',
            DELETE: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/billing-types/{billingTypeId}'
        },
        SUBSCRIPTION_TIER: {
            GET: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/subscription-tiers',
            GET_PACKAGES: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/subscription-tiers/packages',
            DETAIL: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/subscription-tiers/{subscriptionTierId}',
            CREATE: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/subscription-tiers',
            UPDATE: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/subscription-tiers/{subscriptionTierId}',
            DELETE: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/subscription-tiers/{subscriptionTierId}'
        },
        SUBSCRIPTION_BILLING: {
            GET: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/subscription-billings',
            DETAIL: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/subscription-billings/{subscriptionBillingId}',
        },
        PAYMENT_INSTITUTION: {
            GET: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/payment-institutions',
            SEARCH: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/payment-institutions/search',
            DETAIL: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/payment-institutions/{paymentInstitutionId}',
            CREATE: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/payment-institutions',
            UPDATE: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/payment-institutions/{paymentInstitutionId}',
            DELETE: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/payment-institutions/{paymentInstitutionId}'
        },
        PAYMENT_CHANNEL: {
            GET: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/payment-channels',
            DETAIL: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/payment-channels/{paymentChannelId}',
            CREATE: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/payment-channels',
            UPDATE: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/payment-channels/{paymentChannelId}',
            SEARCH: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/payment-channels/search',
            DELETE: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/payment-channels/{paymentChannelId}',
        },
        INVOICE: {
            GET: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/invoices',
            SEARCH: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/invoices/search',
            PAY: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/invoices/pay',
            CANCEL: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/invoices/cancel',
            DETAIL: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/invoices/{invoiceId}',
            CREATE: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/invoices',
            UPDATE: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/invoices/{invoiceId}',
        },
        PROMOTION: {
            GET: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/promotions',
            SEARCH: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/promotions/search',
            DETAIL: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/promotions/{promotionId}',
            CREATE: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/promotions',
            UPDATE: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/promotions/{promotionId}',
            DELETE: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/promotions/{promotionId}'
        },
        ORG_TRANSACTION: {
            GET: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/transactions',
            SEARCH: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/transactions/search',
            CREDIT: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/transactions/credit',
            REVERSE: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/transactions/reverse',
            DETAIL: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/tracsactions/{transactionId}',
            UPDATE: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/invoices/{transactionId}',
        },
        PAYMENT_INSTITUTION_BRANCH: {
            GET: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/payment-institution-branches',
            SEARCH: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/payment-institution-branches/search',
            DETAIL: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/payment-institution-branches/{paymentInstitutionBranchId}',
            CREATE: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/payment-institution-branches',
            UPDATE: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/payment-institution-branches/{paymentInstitutionBranchId}',
            DELETE: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/payment-institution-branches/{paymentInstitutionBranchId}'
        },
        EXCHANGE_RATE: {
            GET: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/exchange-rates',
            CONVERT: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/exchange-rates/{fromcurrency}/{toCurrency}',
            DETAIL: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/exchange-rates/{exchangeRateId}',
            CREATE: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/exchange-rates',
            UPDATE: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/exchange-rates/{exchangeRateId}',
            DELETE: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/exchange-rates/{exchangeRateId}'
        },
        EXCHANGE_RATE_HISTORY: {
            GET: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/exchange-rates-history',
        },
        STATEMENT_REQUEST: {
            GET: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/statement-requests',
            DETAIL: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/statement-requests/{statementRequestId}',
            CREATE: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/statement-requests',
            UPDATE: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/statement-requests/{statementRequestId}',
            DELETE: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/statement-requests/{statementRequestId}'
        },
        ORGANIZATION_ACCOUNT: {
            GET: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/organization-accounts',
            DETAIL: REACT_APP_BILLING_AND_SUBSCRIPTION_API + '/api/v1/organizations/{organizationId}/account'
        }

    },
    HR:{
        LEAVE_TYPE: {
            GET: REACT_APP_HR_API + '/api/v1/leave-types',
            SEARCH: REACT_APP_HR_API + '/api/v1/leave-types/search',
            GET_APPLICABLE: REACT_APP_HR_API + '/api/v1/leave-types/applicable',
            DETAIL: REACT_APP_HR_API + '/api/v1/leave-types/{leaveTypeId}',
            CREATE: REACT_APP_HR_API + '/api/v1/leave-types',
            UPDATE: REACT_APP_HR_API + '/api/v1/leave-types/{leaveTypeId}',
            DELETE: REACT_APP_HR_API + '/api/v1/leave-types/{leaveTypeId}'
        },
        LEAVE_PACKAGE: {
            GET: REACT_APP_HR_API + '/api/v1/leave-packages',
            SEARCH: REACT_APP_HR_API + '/api/v1/leave-packages/search',
            DETAIL: REACT_APP_HR_API + '/api/v1/leave-packages/{leavePackageId}',
            CREATE: REACT_APP_HR_API + '/api/v1/leave-packages',
            UPDATE: REACT_APP_HR_API + '/api/v1/leave-packages/{leavePackageId}',
            DELETE: REACT_APP_HR_API + '/api/v1/leave-packages/{leavePackageId}'
        },
        LEAVE_PLAN: {
            GET: REACT_APP_HR_API + '/api/v1/leave-plans',
            DETAIL: REACT_APP_HR_API + '/api/v1/leave-plans/{leavePlanId}',
            CREATE: REACT_APP_HR_API + '/api/v1/leave-plans',
            UPDATE: REACT_APP_HR_API + '/api/v1/leave-plans/{leavePlanId}',
            DELETE: REACT_APP_HR_API + '/api/v1/leave-plans/{leavePlanId}'
        },
        LEAVE_REQUEST: {
            GET: REACT_APP_HR_API + '/api/v1/leave-requests',
            DETAIL: REACT_APP_HR_API + '/api/v1/leave-requests/{leaveRequestId}',
            CREATE: REACT_APP_HR_API + '/api/v1/leave-requests',
            UPDATE: REACT_APP_HR_API + '/api/v1/leave-requests/{leaveRequestId}',
            DELETE: REACT_APP_HR_API + '/api/v1/leave-requests/{leaveRequestId}',
            RESPONSE: REACT_APP_HR_API + '/api/v1/leave-requests/{leaveRequestId}/response',
            CANCEL: REACT_APP_HR_API + '/api/v1/leave-requests/{leaveRequestId}/cancel',
            ADJUST: REACT_APP_HR_API + '/api/v1/leave-requests/{leaveRequestId}/number-of-days',
            SUMMARY: REACT_APP_HR_API + '/api/v1/employees/{employeeId}/leave-types/{leaveTypeId}/summary'
        },
        DISCIPLINARY_ACTION_TYPE: {
            GET: REACT_APP_HR_API + '/api/v1/disciplinary-action-types',
            SEARCH: REACT_APP_HR_API + '/api/v1/disciplinary-action-types/search',
            DETAIL: REACT_APP_HR_API + '/api/v1/disciplinary-action-types/{discipActionTypeId}',
            CREATE: REACT_APP_HR_API + '/api/v1/disciplinary-action-types',
            UPDATE: REACT_APP_HR_API + '/api/v1/disciplinary-action-types/{discipActionTypeId}',
            DELETE: REACT_APP_HR_API + '/api/v1/disciplinary-action-types/{discipActionTypeId}'
        },
        DISCIPLINARY_ACTION: {
            GET: REACT_APP_HR_API + '/api/v1/disciplinary-actions',
            SEARCH: REACT_APP_HR_API + '/api/v1/disciplinary-actions/search',
            DETAIL: REACT_APP_HR_API + '/api/v1/disciplinary-actions/{discipActionId}',
            CREATE: REACT_APP_HR_API + '/api/v1/disciplinary-actions',
            UPDATE: REACT_APP_HR_API + '/api/v1/disciplinary-actions/{discipActionId}',
            DELETE: REACT_APP_HR_API + '/api/v1/disciplinary-actions/{discipActionId}'
        },
        GRIEVANCE_TYPE: {
            GET: REACT_APP_HR_API + '/api/v1/grievance-types',
            SEARCH: REACT_APP_HR_API + '/api/v1/grievance-types/search',
            DETAIL: REACT_APP_HR_API + '/api/v1/grievance-types/{grievanceTypeId}',
            CREATE: REACT_APP_HR_API + '/api/v1/grievance-types',
            UPDATE: REACT_APP_HR_API + '/api/v1/grievance-types/{grievanceTypeId}',
            DELETE: REACT_APP_HR_API + '/api/v1/grievance-types/{grievanceTypeId}'
        },
        GRIEVANCE_REPORT: {
            GET: REACT_APP_HR_API + '/api/v1/grievance-reports',
            SEARCH: REACT_APP_HR_API + '/api/v1/grievance-reports/search',
            DETAIL: REACT_APP_HR_API + '/api/v1/grievance-reports/{grievanceReportId}',
            CREATE: REACT_APP_HR_API + '/api/v1/grievance-reports',
            UPDATE: REACT_APP_HR_API + '/api/v1/grievance-reports/{grievanceReportId}',
            DELETE: REACT_APP_HR_API + '/api/v1/grievance-reports/{grievanceReportId}',
            CREATE_REPORTED_EMPLOYEE: REACT_APP_HR_API + '/api/v1/grievance-reports/{grievanceReportId}/reported-employees',
            DELETE_REPORTED_EMPLOYEE: REACT_APP_HR_API + '/api/v1/grievance-reports/{grievanceReportId}/reported-employees/{employeeId}',
        },
        COMPANY_TREE_NODE: {
            GET_TREE: REACT_APP_HR_API + '/api/v1/payroll-companies/{companyId}/tree',
            GET_NODE: REACT_APP_HR_API + '/api/v1/payroll-companies/{companyId}/tree/nodes/{nodeId}',
            CREATE: REACT_APP_HR_API + '/api/v1/payroll-companies/{companyId}/tree/nodes',
            UPDATE: REACT_APP_HR_API + '/api/v1/payroll-companies/{companyId}/tree/nodes/{nodeId}',
            UNLINK: REACT_APP_HR_API + '/api/v1/payroll-companies/{companyId}/tree/nodes/{nodeId}/employee',
            DELETE: REACT_APP_HR_API + '/api/v1/payroll-companies/{companyId}/tree/nodes/{nodeId}',
            GET_SUPERVISEES: REACT_APP_HR_API + '/api/v1/payroll-companies/{companyId}/tree/nodes/employees/supervisees'
        },
        REIMBURSEMENT_REQUEST: {
            GET: REACT_APP_HR_API + '/api/v1/reimbursement-requests',
            SEARCH: REACT_APP_HR_API + '/api/v1/reimbursement-requests/search',
            DETAIL: REACT_APP_HR_API + '/api/v1/reimbursement-requests/{reimbReqId}',
            CREATE: REACT_APP_HR_API + '/api/v1/reimbursement-requests',
            UPDATE: REACT_APP_HR_API + '/api/v1/reimbursement-requests/{reimbReqId}',
            RESPOND: REACT_APP_HR_API + '/api/v1/reimbursement-requests/{reimbReqId}/response',
            POST_UPDATE: REACT_APP_HR_API + '/api/v1/reimbursement-requests/{reimbReqId}/updates',
            COMPLETE: REACT_APP_HR_API + '/api/v1/reimbursement-requests/{reimbReqId}/completion',
            DELETE: REACT_APP_HR_API + '/api/v1/reimbursement-requests/{reimbReqId}',
        },
        EMPLOYEE_WORK_TIME: {
            GET: REACT_APP_HR_API + '/api/v1/employee-work-times',
            DETAIL: REACT_APP_HR_API + '/api/v1/employee-work-times/{empWorkTimeId}',
            CREATE: REACT_APP_HR_API + '/api/v1/employee-work-times',
            UPDATE: REACT_APP_HR_API + '/api/v1/employee-work-times/{empWorkTimeId}',
            DELETE: REACT_APP_HR_API + '/api/v1/employee-work-times/{empWorkTimeId}'
        },
        EMPLOYEE_OVERTIME_ENTRY: {
            GET: REACT_APP_HR_API + '/api/v1/employee-overtime-entries',
            DETAIL: REACT_APP_HR_API + '/api/v1/employee-overtime-entries/{empOvertimeEntryId}',
            CREATE: REACT_APP_HR_API + '/api/v1/employee-overtime-entries',
            UPDATE: REACT_APP_HR_API + '/api/v1/employee-overtime-entries/{empOvertimeEntryId}',
            DELETE: REACT_APP_HR_API + '/api/v1/employee-overtime-entries/{empOvertimeEntryId}'
        },
        COMPANY_DOCUMENT_TYPE: {
            GET: REACT_APP_HR_API + '/api/v1/company-document-types',
            SEARCH: REACT_APP_HR_API + '/api/v1/company-document-types/search',
            DETAIL: REACT_APP_HR_API + '/api/v1/company-document-types/{compDocTypeId}',
            CREATE: REACT_APP_HR_API + '/api/v1/company-document-types',
            UPDATE: REACT_APP_HR_API + '/api/v1/company-document-types/{compDocTypeId}',
            DELETE: REACT_APP_HR_API + '/api/v1/company-document-types/{compDocTypeId}'
        },
        EMPLOYEE_DOCUMENT: {
            GET: REACT_APP_HR_API + '/api/v1/employee-documents',
            DETAIL: REACT_APP_HR_API + '/api/v1/employee-documents/{empDocumentId}',
            CREATE: REACT_APP_HR_API + '/api/v1/employee-documents',
            UPDATE: REACT_APP_HR_API + '/api/v1/employee-documents/{empDocumentId}',
            DELETE: REACT_APP_HR_API + '/api/v1/employee-documents/{empDocumentId}'
        },
        ANNOUNCEMENT: {
            GET: REACT_APP_HR_API + '/api/v1/announcements',
            MY_GET: REACT_APP_HR_API + '/api/v1/announcements/me',
            SEARCH: REACT_APP_HR_API + '/api/v1/announcements/search',
            MY_SEARCH: REACT_APP_HR_API + '/api/v1/announcements/me/search',
            DETAIL: REACT_APP_HR_API + '/api/v1/announcements/{announcementId}',
            CREATE: REACT_APP_HR_API + '/api/v1/announcements',
            UPDATE: REACT_APP_HR_API + '/api/v1/announcements/{announcementId}',
            UPDATE_RESOURCE: REACT_APP_HR_API + '/api/v1/announcements/{announcementId}/resources/{resourceId}',
            DELETE: REACT_APP_HR_API + '/api/v1/announcements/{announcementId}',
        },
        EMPLOYEE_APPROVER: {
            GET: REACT_APP_HR_API + '/api/v1/employees/{employeeId}/approvers',
            DETAIL: REACT_APP_HR_API + '/api/v1/employees/{employeeId}/approvers/{empApproverId}',
            CREATE: REACT_APP_HR_API + '/api/v1/employees/{employeeId}/approvers',
            PREFLIGHT: REACT_APP_HR_API + '/api/v1/employees/{employeeId}/approvers/preflight',
            UPDATE: REACT_APP_HR_API + '/api/v1/employees/{employeeId}/approvers/{empApproverId}',
            DELETE: REACT_APP_HR_API + '/api/v1/employees/{employeeId}/approvers/{empApproverId}'
        },
    },
    PAYROLL: {
        GET_EMPLOYEE_PAYSLIPS: REACT_APP_PAYROLL_API + '/payroll-run/{employeeId}/payslips',
        GET_APPROVED_EMPLOYEE_PAYSLIPS: REACT_APP_PAYROLL_API + '/payroll-run/{employeeId}/payslips-approved',
        GET_COMPANY_PAYSLIPS: REACT_APP_PAYROLL_API + '/payroll-run/{companyId}/{payPeriodId}/payslips',
        GET_PAYROLL_RUN_PARAMS: REACT_APP_PAYROLL_API + '/payroll-run/{payrollId}/parameters',
        GET_COMPANY_PAYROLL_RUNS: REACT_APP_PAYROLL_API + '/payroll-runs',
        RUN_PAYROLL: REACT_APP_PAYROLL_API + '/{payrollRunId}/process',
        CREATE_PAYROLL_RUN: REACT_APP_PAYROLL_API + '/payroll-run/submit',
        GET_PAYROLL_EXCELL: REACT_APP_PAYROLL_API + '/payroll-run/{companyId}/{payPeriodId}/download-excel',
        GET_RECENT_PAYROLL_RUN: REACT_APP_PAYROLL_API + '/payroll-run/{companyId}/pay-period/recent',
        GET_PAYROLL_RUN_STATUS: REACT_APP_PAYROLL_API + '/payroll-run/{payrollRunId}/status',
        GET_EMPLOYEE_PAYSLIP: REACT_APP_PAYROLL_API + '/payroll-run/employee/payslip',
        GET_APPROVED_PAYROLL: REACT_APP_PAYROLL_API + '/payroll-run/{payPeriodId}/approve',
        GET_SSF_ONE_REPORT: REACT_APP_PAYROLL_API + '/payroll-run/ssf-one/report',
        GET_SSF_TWO_REPORT: REACT_APP_PAYROLL_API + '/payroll-run/ssf-two/report',
        GET_SAVING_SCHEME_REPORT: REACT_APP_PAYROLL_API + '/payroll-run/saving-scheme/report',
        DOWNLOAD_REPORT: REACT_APP_PAYROLL_API + '/payroll-run/report/download',
        RUN_MIGRATION: REACT_APP_PAYROLL_API + '/run-migration',
    },
    AUDIT: {
        AUDIT_LOG: {
            GET: REACT_APP_AUDIT_API + '/api/v1/audit-logs',
            SEARCH: REACT_APP_AUDIT_API + '/api/v1/audit-logs/search',
            DETAIL: REACT_APP_AUDIT_API + '/api/v1/audit-logs/{auditLogId}',
        },
    },
    DOCUMENT:{
        GENERATE_TOKEN: 'https://services.lucidarray.dev/keycloak/realms/services/protocol/openid-connect/token',
        FILE_UPLOAD: {
            CREATE: REACT_APP_DOCUMENT_API + '/api/v1/file-uploads',
            DELETE:REACT_APP_DOCUMENT_API + '/api/v1/file-uploads?url={fileUrl}',
        }
    },
    EXAMPLE: {
        GET_1: `https://api.github.com/repositories/207645083`
    }
}