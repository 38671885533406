import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import Label from "../../../../../../components/form_fields/Label";
import OrangeButton from "../../../../../../components/buttons/OrangeButton";
import { useCreateEmployeeUnion } from "../../../../../../services/employee-union.service";
import { UnionDto } from "../../../../../../models/union.dto";
import APISelectField from "../../../../../../components/form_fields/APISelectField";
import useUserStore from "../../../../../../state-management/useUserStore";
import { PATHS } from "../../../../../../routes/routes.paths";

interface IFormInputs {
    unionId: number;
    membershipNumber: string;
    active: boolean;
}

interface INewUnion {
	employeeId: number;
	companyId: number;
    unions: UnionDto[];
	setShowNewUnionForm: React.Dispatch<React.SetStateAction<boolean>>;
}

const NewEmployeeUnion = ({
	employeeId,
	companyId,
	unions,
	setShowNewUnionForm
}: INewUnion) => {
	const { userType } = useUserStore();
	const { mutate: createEmployeeUnion } = useCreateEmployeeUnion();
	const [uploading, setUploading] = useState(false);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<IFormInputs>();

	const onSubmit: SubmitHandler<IFormInputs> = (data) => {
		setUploading(true);

		createEmployeeUnion(
			{
				payload: {
					...data,
					employeeId,
					companyId,
				}
			},
			{
				onSuccess: () => setShowNewUnionForm(false),
				onSettled: () => setUploading(false),
			}
		)
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="lg:grid grid-cols-2 gap-x-4 gap-y-6 mt-6 space-y-4 lg:space-y-0">
				<div className="w-full">
					<Label title="Union" for="unionId" />
					<APISelectField 
						attributes={{
							id: "unionId",
						}}
						placeholder="Select Union"
						register={register}
						name="unionId"
						options={unions}
						pathname={PATHS.SUB_COMPANY_SETTINGS.UNIONS}
						required="Select union here"
						error={errors.unionId}
						valueAsNumber
						doNotLinkToResource={userType === "HR"}
					/>
					{errors.unionId && (
						<p className="text-red-500 text-sm mt-1">
							{errors.unionId.message}
						</p>
					)}
				</div>
				<div className="w-full">
					<Label title="Membership Number" for="membershipNumber" />
					<input
						placeholder={"Enter number"}
						type="text"
						{...register("membershipNumber", {
							required: "Enter membership number here",
						})}
						id="membershipNumber"
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
							errors.membershipNumber
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
					/>
					{errors.membershipNumber && (
						<p className="text-red-500 text-sm mt-1">
							{errors.membershipNumber.message}
						</p>
					)}
				</div>
				<div className="col-span-2 flex space-x-3 items-center">
					<input 
						type="checkbox"
						{...register("active", {
							required: false,
						})}
						id="active"
					/>
					<label htmlFor="active" className="max-lg:text-sm">Active</label>
				</div>
			</div>

			<div className="w-full flex items-center gap-4 lg:justify-between my-8">
				<button
					onClick={() => setShowNewUnionForm(false)}
					className="px-10 py-4 max-lg:w-full border text-gray-400 hover:text-gray-500 border-gray-300 rounded-lg text-2xs"
				>
					Close Form
				</button>
				<OrangeButton
					title={`${uploading ? "Saving..." : "Add Union"}`}
					type="submit"
					className={"h-14 px-8 max-lg:w-full"}
					disabled={uploading}
				/>
			</div>
		</form>
	);
};

export default NewEmployeeUnion;