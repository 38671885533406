import { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { compareDateFields, enumToStringConverter } from "../../../../helpers/appHelpers";
import { useCreateStatementRequest } from "../../../../services/statement-request.service";
import OrangeButton from "../../../buttons/OrangeButton";
import ModalComponent from "../../../modals/ModalFormComponent";
import Label from "../../../form_fields/Label";
import { TRANSACTION_TYPE } from "../../../../models/statement-request.dto";
import useUserStore from "../../../../state-management/useUserStore";

interface IFormInputs {
	transactionType: TRANSACTION_TYPE;
	startDate: string;
	endDate: string;
	recipientEmail: string;
}

type StatementsRequestModalFormProps = {
	openModal: boolean;
	setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
	addNewData: () => void;
}

const StatementsRequestModalForm = ({
	openModal,
	setOpenModal,
	addNewData,
}: StatementsRequestModalFormProps) => {
	const { currentUser } = useUserStore();
	const [uploading, setUploading] = useState(false);

	const {
		register,
		watch,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<IFormInputs>({
		defaultValues: {
			recipientEmail: currentUser.user.emailAddress,
		}
	});

	const { mutate: createStatementRequest } = useCreateStatementRequest();

	const onSubmit: SubmitHandler<IFormInputs> = (data) => {
		setUploading(true);

		createStatementRequest(
			{ 
				payload: {
					...data,
					organizationId: currentUser.user.organizationId,
				} 
			}, 
			{
				onSuccess: () => {
					addNewData();
					setOpenModal(false);
					reset();
				},
				onSettled: () => setUploading(false),
			}
		);
	};

	const handleClose = () => {
		reset();
		setOpenModal(false);
	};

	return (
		<ModalComponent
			width={700}
			open={openModal}
			title="Request Statement"
			handleClose={handleClose}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="grid grid-cols-2 gap-x-4 gap-y-6">
					<div className="w-full">
						<Label title="Recepient Email" for="recipientEmail" />
						<input
							type="email"
							{...register("recipientEmail", {
								required: "Enter recepient email address here",
								pattern: {
									value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
									message: "Enter a valid email address",
								},
							})}
							id="email"
							placeholder="yourname@email.com"
							className={`w-full bg-secondary-gray rounded-lg py-4 px-4 mt-2 bg text-sm ${
								errors.recipientEmail
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.recipientEmail && (
							<p className="text-red-500 text-sm mt-1">
								{errors.recipientEmail.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Transaction Type" for="transactionType" />
						<select
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
								errors.transactionType
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							{...register("transactionType", {
								required: "Select transaction type here",
							})}
						>
							<option value="">
								Select Type
							</option>
							{Object.entries(TRANSACTION_TYPE).map(([key, value]) => (
								<option key={key} value={value} >
									{enumToStringConverter(value)}
								</option>
							))}
						</select>
						{errors.transactionType && (
							<p className="text-red-500 text-sm mt-1">
								{errors.transactionType.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Start Date" for="startDate" />
						<input
							type="date"
							{...register("startDate", {
								required: "Enter start date here",
							})}
							id="startDate"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.startDate
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.startDate && (
							<p className="text-red-500 text-sm mt-1">
								{errors.startDate.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="End Date" for="endDate" />
						<input
							type="date"
							{...register("endDate", {
								required: "Enter end date here",
								validate: (value) => compareDateFields(
									watch("startDate"), 
									value,
									"Start date must be less than end date"
								),
							})}
							id="endDate"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.endDate
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.endDate && (
							<p className="text-red-500 text-sm mt-1">
								{errors.endDate.message}
							</p>
						)}
					</div>
				</div>

				<div className="w-full flex justify-end mt-10">
					<OrangeButton
						title={uploading ? "Uploading..." : "Submit"}
						className={`px-8`}
						type={"submit"}
						disabled={uploading}
					/>
				</div>
			</form>
		</ModalComponent>
	);
};

export default StatementsRequestModalForm;
