import{ useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import Label from "../../../../../components/form_fields/Label";
import OrangeButton from "../../../../../components/buttons/OrangeButton";
import { AiOutlineDownCircle } from "react-icons/ai";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import FormDescriptionInput from "../../../../../components/form_fields/FormDescriptionInput";
import { useDeleteGradeLevel, useUpdateGradeLevel } from "../../../../../services/grade-level.service";
import { CompanyLevelDto } from "../../../../../models/company-level.dto";
import { GRADE_LEVEL_TYPE, GradeLevelDto } from "../../../../../models/grade-level.dto";
import DeleteModal from "../../../../../components/modals/DeleteModal";
import { useManageAccordionsData } from "../../../../../helpers/useManageAccordions";
import FormCodeInput from "../../../../../components/form_fields/FormCodeInput";
import APISelectField from "../../../../../components/form_fields/APISelectField";
import { EmployeeBandDto } from "../../../../../models/employee-band.dto";
import { PATHS } from "../../../../../routes/routes.paths";

interface IFormInputs {
    companyLevelId: number;
	name: string;
	code: string;
    type: GRADE_LEVEL_TYPE;
	description?: string;
    employeeBandId?: number;
}

interface IEditGradeLevel {
	gradeLevel: GradeLevelDto;
	companyLevels: CompanyLevelDto[];
	employeeBands: EmployeeBandDto[];
}

const EditGradeLevel = ({ 
	gradeLevel, 
	companyLevels,
	employeeBands,
}: IEditGradeLevel) => {
    const [expanded, setExpanded] = useState(false);
	const [formDisabled, setFormDisabled] = useState(true);
	const { mutate: updateGradeLevel } = useUpdateGradeLevel();
	const { reloadAccordionsData } = useManageAccordionsData();
	const [uploading, setUploading] = useState(false);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<IFormInputs>({
		defaultValues: {
			companyLevelId: gradeLevel.companyLevelId,
			name: gradeLevel.name,
			code: gradeLevel.code,
			type: gradeLevel.type,
			description: gradeLevel.description,
			employeeBandId: gradeLevel.employeeBandId || undefined,
		}
	});

	const onSubmit: SubmitHandler<IFormInputs> = async (data: IFormInputs) => {
		setUploading(true);

		updateGradeLevel(
			{ payload: data, id: gradeLevel.id }, 
			{
				onSuccess: () => {
					reloadAccordionsData();
					setFormDisabled(true);
					setExpanded(false);
				},
				onSettled: () => setUploading(false),
			}
		)
	};
	
	const [deleting, setDeleting] = useState(false);
	const { mutate: deleteGradeLevel } = useDeleteGradeLevel();

	const handleDeleteMethod = () => {
		setDeleting(true);

		deleteGradeLevel(
			{ id: gradeLevel?.id },
			{
				onSuccess: () => {
					reloadAccordionsData();
					setOpenDeleteModal(false);
				},
				onSettled: () => setDeleting(false),
			}
		);
	};

	return (
        <Accordion
            expanded={expanded}
            onChange={() => setExpanded(prev => !prev)}
            classes={{
                root: "border border-gray-300 rounded-lg py-2 mb-2",
            }}
        >
			<AccordionSummary
				expandIcon={<AiOutlineDownCircle className="text-xl" />}
				aria-controls={gradeLevel.id + "bh-content"}
				id={gradeLevel.id + "bh-header"}
				sx={{ px: 3, border: "1px" }}
			>
				<h2 className="font-inter text-sm font-semibold">
					<span>{gradeLevel.name + " "}</span>
					<span className="capitalize">({gradeLevel.type.toLowerCase()})</span>
				</h2>
			</AccordionSummary>
			<AccordionDetails sx={{ px: 3 }}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<>
						<div className="border-b-[1.5px] pb-3 flex justify-between font-semibold text-2xs">
							<h2 className="font-semibold text-2xs">Details</h2>
							<button
								type="button"
								className={`border-none text-primary-blue text-2xs ${
									!formDisabled ? "hidden" : ""
								}`}
								onClick={() => setFormDisabled(false)}
							>
								Edit
							</button>
						</div>
						<div className="grid grid-cols-2 gap-x-4 gap-y-6 mt-4 ">
							<div className="w-full">
								<Label title="Name" for="name" />
								<input
									defaultValue={gradeLevel.name}
									disabled={formDisabled}
									placeholder={"Enter name"}
									type="text"
									{...register("name", {
										required: "Enter name here",
									})}
									id="name"
									className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
										errors.name
											? " border border-red-500 focus:border-red-500 focus:outline-red-500"
											: "border-none"
									}`}
								/>
								{errors.name && (
									<p className="text-red-500 text-sm mt-1">{errors.name.message}</p>
								)}
							</div>
							<div className="w-full">
								<Label title="Unique Code" for="code" />
								<FormCodeInput
									register={register}
									attributes={{
										defaultValue: gradeLevel.code,
										disabled: formDisabled,
										className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
											errors.code
												? " border border-red-500 focus:border-red-500 focus:outline-red-500"
												: "border-none"
										}`,
									}}
								/>
								{errors.code && (
									<p className="text-red-500 text-sm mt-1">
										{errors.code.message}
									</p>
								)}
							</div>
							<div className="w-full">
								<Label title="Company Level" for="companyLevelId" />
								<APISelectField 
									key="companyLevelId"
									attributes={{
										id: "companyLevelId",
										disabled: formDisabled
									}}
									placeholder="Select Company Level"
									register={register}
									name="companyLevelId"
									options={companyLevels}
									mapText="levelName"
									pathname={PATHS.SUB_COMPANY_SETTINGS.COMPANY_LEVELS}
									required={"Select company level here"}
									error={errors.companyLevelId}
									valueAsNumber
								/>
								{errors.companyLevelId && (
									<p className="text-red-500 text-sm mt-1">
										{errors.companyLevelId.message}
									</p>
								)}
							</div>
							<div className="w-full">
								<Label title="Type" for="type" />
								<select
									defaultValue={gradeLevel.type}
									disabled={formDisabled}
									className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
										errors.type
											? " border border-red-500 focus:border-red-500 focus:outline-red-500"
											: "border-none"
									}`}
									{...register("type", {
										required: "Select type here",
									})}
								>
									<option value="">Select Type</option>
									<option value="MINOR">Minor</option>
									<option value="MAJOR">Major</option>
								</select>
								{errors.type && (
									<p className="text-red-500 text-sm mt-1">
										{errors.type.message}
									</p>
								)}
							</div>
							<div className="w-full col-span-2">
								<Label title="Description" for="description" optional />
								<FormDescriptionInput 
									attributes={{
										defaultValue: gradeLevel.description,
										className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
											errors.description
												? " border border-red-500 focus:border-red-500 focus:outline-red-500"
												: "border-none"
										}`
									}}
									register={register}
									required={false}
									disabled={formDisabled}
								/>
							</div>
							<div className="w-full">
								<Label title="Employee Band" for="employeeBandId" optional />
								<APISelectField 
									key="employeeBandId"
									attributes={{
										id: "employeeBandId",
										disabled: formDisabled
									}}
									placeholder="Select Employee Band"
									register={register}
									name="employeeBandId"
									options={employeeBands}
									pathname={PATHS.SUB_COMPANY_SETTINGS.BANDS}
									required={false}
									error={errors.employeeBandId}
									valueAsNumber
								/>
								{errors.employeeBandId && (
									<p className="text-red-500 text-sm mt-1">
										{errors.employeeBandId.message}
									</p>
								)}
							</div>
						</div>
					</>
					
					{!formDisabled && (
						<div className="flex w-full justify-end space-x-4 items-center mt-10">
							<button
								type="button"
								className="px-12 py-4 border text-gray-400 hover:text-gray-500 border-gray-300 rounded-lg text-2xs"
								onClick={() => {
									setFormDisabled(true);
									reset();
								}}
							>
								Cancel
							</button>
							<OrangeButton
								title={`${uploading ? "Saving..." : "Save"}`}
								className={"px-12"}
								type={"submit"}
								disabled={uploading}
							/>
						</div>
					)}
					
					{formDisabled && (
						<div className="flex w-full justify-end space-x-4 items-center mt-10">
							<button
								type="button"
								onClick={() => setOpenDeleteModal(true)}
								className={`px-12 py-4 border text-white hover:shadow-sm bg-secondary-red rounded-lg text-2xs ${uploading && "opacity-50"}`}
								disabled={uploading}
							>
								Delete
							</button>
						</div>
					)}

					<DeleteModal
						openDeleteModal={openDeleteModal}
						spanContent={gradeLevel.name}
						setOpenDeleteModal={setOpenDeleteModal}
						handleDeleteMethod={handleDeleteMethod}
						deleting={deleting}
					/>
				</form>
			</AccordionDetails>
		</Accordion>
	);
};

export default EditGradeLevel;
