import { useEffect, useState } from "react"
import { useForm, SubmitHandler } from "react-hook-form"
import OrangeButton from "../../../../components/buttons/OrangeButton"
import APISelectField from "../../../../components/form_fields/APISelectField"
import MoneyInput from "../../../../components/form_fields/MoneyInput"
import ModalComponent from "../../../../components/modals/ModalFormComponent"
import { formatRate, IAddEditTableForm } from "../../../../helpers/appHelpers"
import { useManageTableWithSearchParamsData } from "../../../../helpers/useManageTable"
import { EmployeeDto } from "../../../../models/employee.dto"
import { PayPeriodDto } from "../../../../models/pay-period.dto"
import { useCreateLoan, useUpdateLoan } from "../../../../services/loan.service"
import useEditFormStore from "../../../../state-management/useEditFormStore"
import Label from "../../../../components/form_fields/Label"
import { RepaymentItems } from "../../../../models/loan.dto"
import { PATHS } from "../../../../routes/routes.paths"
import { BsTrash } from "react-icons/bs"
import { AiOutlinePlus } from "react-icons/ai";
import FormDescriptionInput from "../../../../components/form_fields/FormDescriptionInput"
import RateComponent from "../../../../components/form_fields/RateComponent"

type IFormInputs = {
    description: string
    employeeId: number
    repaymentType: "FIXED" | "VARIABLE"
    amount: number
    interestRate: number
    numberOfMonths: number
    startPayPeriodId: number
    initialPayment?: number
    finalPayment?: number
    applicablePayPeriodCount: number
    repaymentAmount?: number
    repaymentDetails?: RepaymentItems[]
}

type LoanModalFormProps = IAddEditTableForm & {
	payPeriods: PayPeriodDto[];
	employees: EmployeeDto[];
}

const LoanModalForm = ({
	open,
	setOpen,
	title,
	isEditForm,
	tableAPIRef,
	payPeriods,
	employees,
}: LoanModalFormProps) => {
	const { formDataValues } = useEditFormStore();
	const [uploading, setUploading] = useState(false);
	const [defaultRepaymentItems] = useState(
        formDataValues?.loanRepaymentInfo?.map(
            (item) => ({ payPeriodId: item.payPeriodId, amount: item.amount })
        ) || []
    );
	
	const {
		register,
		watch,
		setValue,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<IFormInputs>({
		defaultValues: {
			description: isEditForm ? formDataValues?.description : "",
			repaymentType: isEditForm ? formDataValues?.repaymentType : "",
			amount: isEditForm ? formDataValues?.amount : "",
			interestRate: isEditForm ? (Number(formDataValues?.interestRate) * 100) as any : "",
			numberOfMonths: isEditForm ? formDataValues?.numberOfMonths : "",
			initialPayment: isEditForm ? formDataValues?.initialPayment : "",
			finalPayment: isEditForm ? formDataValues?.finalPayment : "",
			repaymentAmount: isEditForm ? formDataValues?.repaymentAmount : "",
			applicablePayPeriodCount: isEditForm 
                ? formDataValues?.loanRepaymentInfo?.length || formDataValues?.applicablePayPeriodCount
                : "",
			repaymentDetails: isEditForm 
                ? formDataValues?.loanRepaymentInfo?.map(
                        (item) => ({ payPeriodId: item.payPeriodId, amount: item.amount })
                    )
                : undefined,
			startPayPeriodId: isEditForm ? formDataValues?.startPayPeriodId : "",
		}
	});
	
	const repaymentType = watch("repaymentType");
	const applicablePayPeriodCount = watch("applicablePayPeriodCount");

	const getRepaymentDetailAmount = (index: number) => {
		return watch(`repaymentDetails.${index}.amount`) || undefined
	};

    useEffect(() => {
        if (repaymentType === "VARIABLE") {
            if (isEditForm) {
                setValue(
                    "applicablePayPeriodCount", 
                    formDataValues?.loanRepaymentInfo?.length || 1,
                );
            } else {
                setValue("applicablePayPeriodCount", 1);
            }
        }
    }, [repaymentType])

	const { mutate: creatLoan } = useCreateLoan();
	const { mutate: updateLoan } = useUpdateLoan();
	const { addNewTableData } = useManageTableWithSearchParamsData();

	const onSubmit: SubmitHandler<IFormInputs> = (data: any) => {
		setUploading(true);
        formatRate(data, "interestRate");

		if (isEditForm) {
            if (data.repaymentType === "VARIABLE") {
                data.startPayPeriodId = data.repaymentDetails[0].payPeriodId;

                const { 
                    addRepaymentDetails, 
                    removeRepaymentDetails 
                } = manageRepaymentItems(data.repaymentDetails, defaultRepaymentItems);

                data = { ...data, addRepaymentDetails, removeRepaymentDetails };
                delete data.applicablePayPeriodCount;
            }
            delete data.repaymentDetails;

			updateLoan(
				{ payload: data, id: formDataValues?.id }, 
				{
					onSuccess: (updatedData) => {
						tableAPIRef?.current.updateRows([updatedData.data]);
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		} else {
            if (data.repaymentType === "VARIABLE") {
                data.startPayPeriodId = data.repaymentDetails[0].payPeriodId;
                data.repaymentDetails = data.repaymentDetails.slice(0, applicablePayPeriodCount);
            }

			creatLoan(
				{ payload: data }, 
				{
					onSuccess: () => {
						addNewTableData();
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		}
	};

	const handleClose = () => {
		reset();
		setOpen(false);
	};

	return (
		<ModalComponent
			width={700}
			open={open}
			title={title}
			handleClose={handleClose}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="grid grid-cols-2 gap-x-4 gap-y-6">
					{!isEditForm && (
						<div className="w-full">
							<Label title="Employee" for="employeeId" />
							<APISelectField 
								attributes={{
									id: "employeeId",
								}}
								placeholder="Select Employee"
								register={register}
								name="employeeId"
								options={employees}
								mapTexts={["firstName", "lastName"]}
								pathname={PATHS.EMPLOYEES}
								required="Select employee here"
								error={errors.employeeId}
								valueAsNumber
							/>
							{errors.employeeId && (
								<p className="text-red-500 text-sm mt-1">
									{errors.employeeId.message}
								</p>
							)}
						</div>
					)}
					{isEditForm && (
                        <div className="col-span-2 w-full">
                            <Label title="Description" for="description" />
                            <FormDescriptionInput 
                                register={register}
                                attributes={{
                                    className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
                                        errors.description
                                            ? " border border-red-500 focus:border-red-500 focus:outline-red-500"
                                            : "border-none"
                                    }`
                                }}
                            />
                            {errors.description && (
                                <p className="text-red-500 text-sm mt-1">
                                    {errors.description.message}
                                </p>
                            )}
                        </div>
					)}
					<div className="w-full">
						<Label title="Amount" for="amount" />
						<MoneyInput 
							attributes={{
								id: "amount",
								placeholder: "0"
							}}
							register={register}
							name="amount"
							setValue={setValue}
							defaultValue={isEditForm ? formDataValues?.amount : undefined}
							required="Enter amount here"
							error={errors.amount}
						/>
						{errors.amount && (
							<p className="text-red-500 text-sm mt-1">
								{errors.amount.message}
							</p>
						)}
					</div>
					{!isEditForm && (
                        <div className="col-span-2 w-full">
                            <Label title="Description" for="description" />
                            <FormDescriptionInput 
                                register={register}
                                attributes={{
                                    className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
                                        errors.description
                                            ? " border border-red-500 focus:border-red-500 focus:outline-red-500"
                                            : "border-none"
                                    }`
                                }}
                            />
                            {errors.description && (
                                <p className="text-red-500 text-sm mt-1">
                                    {errors.description.message}
                                </p>
                            )}
                        </div>
					)}
					<div className="w-full">
						<Label title="Interest Rate" for="interestRate" />
						<RateComponent 
							attributes={{ 
								id: "interestRate",
							}}
							register={register}
							name="interestRate"
							required="Enter interest rate here"
							error={errors.interestRate}
						/>
						{errors.interestRate && (
							<p className="text-red-500 text-sm mt-1">
								{errors.interestRate.message}
							</p>
						)}
					</div>
					{isEditForm && (
                        <div className="w-full">
                            <Label title="Number Of Months" for="numberOfMonths" />
                            <input
                                placeholder={"Enter value"}
                                type="number"
                                {...register(`numberOfMonths`, {
                                    required: "Enter number of months here",
                                    valueAsNumber: true
                                })}
                                id={`numberOfMonths`}
                                className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
                                    errors?.numberOfMonths
                                        ? " border border-red-500 focus:border-red-500 focus:outline-red-500"
                                        : "border-none"
                                }`}
                            />
                            {errors?.numberOfMonths && (
                                <p className="text-red-500 text-sm mt-1">
                                    {errors?.numberOfMonths?.message}
                                </p>
                            )}
                        </div>
					)}
					{!isEditForm && (
                        <div className="w-full">
                            <Label title="Number Of Months" for="numberOfMonths" />
                            <input
                                placeholder={"Enter value"}
                                type="number"
                                {...register(`numberOfMonths`, {
                                    required: "Enter number of months here",
                                    valueAsNumber: true
                                })}
                                id={`numberOfMonths`}
                                className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
                                    errors?.numberOfMonths
                                        ? " border border-red-500 focus:border-red-500 focus:outline-red-500"
                                        : "border-none"
                                }`}
                            />
                            {errors?.numberOfMonths && (
                                <p className="text-red-500 text-sm mt-1">
                                    {errors?.numberOfMonths?.message}
                                </p>
                            )}
                        </div>
					)}
					<div className="w-full">
						<Label title="Repayment Type" for="repaymentType" />
						<select
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
								errors.repaymentType
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							{...register("repaymentType", {
								required: "Select repayment type here",
							})}
						>
							<option value="">
								Select Type
							</option>
							<option value="FIXED">Fixed</option>
							<option value="VARIABLE">Variable</option>
						</select>
						{errors.repaymentType && (
							<p className="text-red-500 text-sm mt-1">
								{errors.repaymentType.message}
							</p>
						)}
					</div>
                    {repaymentType === "FIXED" && (<>
                        <div className="w-full">
                            <Label title="Number of Pay Periods for Deductions" for="applicablePayPeriodCount" />
                            <input
                                placeholder={"Enter value"}
                                type="number"
                                {...register(`applicablePayPeriodCount`, {
                                    required: "Enter number of pay periods here",
                                    valueAsNumber: true,
                                    validate: (value) => {
                                        if (value && value > 0) return true;
                                        return "Value must be greater than 0"
                                    }
                                })}
                                id={`applicablePayPeriodCount`}
                                className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
                                    errors?.applicablePayPeriodCount
                                        ? " border border-red-500 focus:border-red-500 focus:outline-red-500"
                                        : "border-none"
                                }`}
                            />
                            {errors?.applicablePayPeriodCount && (
                                <p className="text-red-500 text-sm mt-1">
                                    {errors?.applicablePayPeriodCount?.message}
                                </p>
                            )}
                        </div>
                        <div className="w-full">
                            <Label title="Repayment Amount" for="repaymentAmount" />
                            <MoneyInput 
                                attributes={{
                                    id: "repaymentAmount",
                                    placeholder: "0"
                                }}
                                register={register}
                                name="repaymentAmount"
                                setValue={setValue}
                                defaultValue={isEditForm ? formDataValues?.repaymentAmount : undefined}
                                required="Enter repayment payment here"
                                error={errors.repaymentAmount}
                            />
                            {errors.repaymentAmount && (
                                <p className="text-red-500 text-sm mt-1">
                                    {errors.repaymentAmount.message}
                                </p>
                            )}
                        </div>
                        <div className="w-full">
                            <Label title="Start Pay Period" for="startPayPeriodId" />
                            <APISelectField 
                                key="startPayPeriodId"
                                attributes={{
                                    id: "startPayPeriodId",
                                }}
                                placeholder="Select Pay Period"
                                register={register}
                                name="startPayPeriodId"
                                options={payPeriods}
                                mapText="code"
                                pathname={PATHS.SUB_COMPANY_SETTINGS.PAY_PERIODS}
                                required="Select start pay period here"
                                error={errors.startPayPeriodId}
                                valueAsNumber
                            />
                            {errors.startPayPeriodId && (
                                <p className="text-red-500 text-sm mt-1">
                                    {errors.startPayPeriodId.message}
                                </p>
                            )}
                        </div>
                        <div className="w-full">
                            <Label title="Initial Payment" for="initialPayment" />
                            <MoneyInput 
                                attributes={{
                                    id: "initialPayment",
                                    placeholder: "0"
                                }}
                                register={register}
                                name="initialPayment"
                                setValue={setValue}
                                defaultValue={isEditForm ? formDataValues?.initialPayment : undefined}
                                required="Enter initial payment here"
                                error={errors.initialPayment}
                            />
                            {errors.initialPayment && (
                                <p className="text-red-500 text-sm mt-1">
                                    {errors.initialPayment.message}
                                </p>
                            )}
                        </div>
                        <div className="w-full">
                            <Label title="Final Payment" for="finalPayment" />
                            <MoneyInput 
                                attributes={{
                                    id: "finalPayment",
                                    placeholder: "0"
                                }}
                                register={register}
                                name="finalPayment"
                                setValue={setValue}
                                defaultValue={isEditForm ? formDataValues?.finalPayment : undefined}
                                required="Enter final payment here"
                                error={errors.finalPayment}
                            />
                            {errors.finalPayment && (
                                <p className="text-red-500 text-sm mt-1">
                                    {errors.finalPayment.message}
                                </p>
                            )}
                        </div>
                    </>)}
				</div>
                {repaymentType === "VARIABLE" && (
                    <div className="mt-6">
                        {applicablePayPeriodCount && (
                            <h6 className="font-bold mb-2">
                                Repayment Details
                            </h6>
                        )}
                        <div className="grid grid-cols-1 gap-y-6">
                            {Array.from({ length: applicablePayPeriodCount || 0 }).map((_, index) => (
                                <div key={index} className="flex items-center gap-4">
                                    <h6 className="font-bold whitespace-nowrap text-sm flex flex-col gap-3">
                                        <p>Period {index + 1}</p>
                                        {(index + 1 === applicablePayPeriodCount && applicablePayPeriodCount > 1) && (
                                            <button 
                                                type="button"
                                                onClick={() => setValue("applicablePayPeriodCount", applicablePayPeriodCount - 1)}
                                            >
                                                <BsTrash className="text-xl hover:scale-[1.1]" />
                                            </button>
                                        )}
                                    </h6>
                                    <div className="w-full">
                                        <APISelectField 
                                            key={`repaymentDetails.${index}.payPeriodId`}
                                            attributes={{
                                                id: `repaymentDetails.${index}.payPeriodId`,
                                            }}
                                            placeholder="Select Pay Period"
                                            register={register}
                                            name={`repaymentDetails.${index}.payPeriodId`}
                                            options={payPeriods}
                                            mapText="code"
                                            pathname={PATHS.SUB_COMPANY_SETTINGS.PAY_PERIODS}
                                            required="Select pay period here"
                                            error={errors.repaymentDetails && errors.repaymentDetails[index]?.payPeriodId}
                                            valueAsNumber
                                        />
                                        {errors.repaymentDetails && errors.repaymentDetails[index]?.payPeriodId && (
                                            <p className="text-red-500 text-sm mt-1">
                                                {errors.repaymentDetails[index]?.payPeriodId?.message}
                                            </p>
                                        )}
                                    </div>
                                    <div className="w-full">
                                        <MoneyInput 
                                            attributes={{
                                                id: `repaymentDetails.${index}.amount`,
                                                placeholder: "Enter repayment amount"
                                            }}
                                            register={register}
                                            name={`repaymentDetails.${index}.amount`}
                                            setValue={setValue}
                                            required={"Enter amount here"}
                                            defaultValue={isEditForm ? getRepaymentDetailAmount(index) : undefined}
                                            error={errors.repaymentDetails && errors.repaymentDetails[index]?.amount}
                                        />
                                        {errors.repaymentDetails && errors.repaymentDetails[index]?.amount && (
                                            <p className="text-red-500 text-sm mt-1">
                                                {errors.repaymentDetails[index]?.amount?.message}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div
                            className="w-fit flex items-center text-primary-blue cursor-pointer mt-4"
                            onClick={() => setValue("applicablePayPeriodCount", applicablePayPeriodCount + 1)}
                        >
                            <AiOutlinePlus className="mr-1 text-base" />
                            <span className="text-sm">Add New</span>
                        </div>
                    </div>
                )}

				<div className="w-full flex justify-end mt-10">
					<OrangeButton
						title={`${
							uploading 
							? "Uploading..." 
							: isEditForm 
								? "Update Loan" 
								: "Create Loan"
						}`}
						className={`px-8`}
						type="submit"
						disabled={uploading}
					/>
				</div>
			</form>
		</ModalComponent>
	);
};

export default LoanModalForm;

const manageRepaymentItems = (newData: RepaymentItems[], oldData: RepaymentItems[]) => {
	const addRepaymentDetails: RepaymentItems[] = [];
	const removeRepaymentDetails: RepaymentItems[] = [];

	newData.map(repaymentItem => {
		const foundItem = Boolean(
            oldData.find((oldItem) => 
                oldItem.amount === repaymentItem.amount && 
                oldItem.payPeriodId === repaymentItem.payPeriodId
            )
        );
		if (!foundItem) addRepaymentDetails.push(repaymentItem);
	})
	oldData.map(repaymentItem => {
		const foundItem = Boolean(
            newData.find((newItem) => 
                newItem.amount === repaymentItem.amount && 
                newItem.payPeriodId === repaymentItem.payPeriodId
            )
        );
		if (!foundItem) removeRepaymentDetails.push(repaymentItem);
	})

	return {
		addRepaymentDetails: addRepaymentDetails.length ? addRepaymentDetails : undefined,
		removeRepaymentDetails: removeRepaymentDetails.length ? removeRepaymentDetails : undefined,
	}
}