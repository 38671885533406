import { Modal, Box } from "@mui/material";
import { IoMdClose } from "react-icons/io";
import APISearchInput from "../../../search/APISearchInput";
import CustomPagination from "../../../table/CustomPagination";
import CustomStyledTable from "../../../table/CustomStyledTable";
import TableFilter from "../../../table/filter/TableFilter";
import { ModalStyle } from "../../../../helpers/appHelpers";
import { useManageTableWithStateVariables } from "../../../../helpers/useManageTable";
import { AuditLogDto, AuditLogOrderBy } from "../../../../models/audit-log.dto";
import { useGetAuditLogSearch, useGetAuditLog } from "../../../../services/audit-log.service";
import useCompanyStore from "../../../../state-management/useCompanyStore";
import useUserStore from "../../../../state-management/useUserStore";
import { AuditLogColumns } from "../../../../pages/shared/reports/data";
import { GridColDef } from "@mui/x-data-grid";

type OrgAuditLogsProps = {
	openModal: boolean;
	setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const OrgAuditLogs = ({
	openModal,
	setOpenModal,
}: OrgAuditLogsProps) => {
	const { currentUser, userType } = useUserStore();
    const { savedCompanies } = useCompanyStore();
	
	const { mutate: searchAuditLogs } = useGetAuditLogSearch();
	const {
        tableAPIRef,
        defaultTableData,
        tableData,
		tableFilter,
        isLoading,
        page,
        pageCount,
		setTableData,
		setTableFilter,
		setSearching,
        handlePageChange,
	} = useManageTableWithStateVariables<AuditLogDto>({ 
		fetchHook: useGetAuditLog,
		options: { 
			organizationId: currentUser.user!.organizationId,
			orderBy: AuditLogOrderBy.TIMESTAMP_DESC,
		}
	});

	const columnsWithCompany: GridColDef[] = [
		{
			field: "company",
			headerName: "Company",
			width: 280,
			editable: true,
			headerClassName: "super-app-theme--header",
			valueGetter: (params) => params.value?.name || "--",
		},
		...AuditLogColumns.map((column) => {
			switch(column.field) {
				case "timestamp":
					return { ...column, width: 300 }
				case "user":
					return { ...column, width: 280 }
				case "action":
					return { ...column, width: 400 }
				case "modifiedAt":
					return { ...column, width: 280 }
				default:
					return column
			}
		}),
	]

	return (
		<Modal
			open={openModal}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={ModalStyle} width={1260} fontSize={"13px"}>
				<div className="flex justify-between items-center pt-4 pb-6">
					<div className="flex items-center gap-5">
						<h1 className="font-bold text-lg">Audit Logs</h1>
						<div className="flex items-center gap-3">
							<APISearchInput 
								fallbackData={defaultTableData}
								setTableData={setTableData}
								mutate={searchAuditLogs}
								setSearching={setSearching}
								popupTable
							/>
							<TableFilter
								fields={
									userType === "ORGANIZATION" 
										? [
											{
												title: "",
												fieldName: "",
												fieldType: "SORT",
												options: [
													{
														title: "Timestamp",
														fieldName: "timestamp",
													},
												]
											},
											{
												title: "Company",
												fieldName: "companyId",
												fieldType: "SELECT_FIELD",
												options: savedCompanies,
											},
										]
										: [
											{
												title: "",
												fieldName: "",
												fieldType: "SORT",
												options: [
													{
														title: "Timestamp",
														fieldName: "timestamp",
													},
												]
											},
										]
								}
								externalFilter={tableFilter}
								setExternalFilter={setTableFilter}
								setSearching={setSearching}
							/>
						</div>
					</div>
					<button onClick={() => setOpenModal(false)}>
						<IoMdClose className="text-xl hover:scale-[1.1]" />
					</button>
				</div>
				<hr className="mb-6" />
				<CustomStyledTable
					rows={tableData}
					columns={
						userType === "ORGANIZATION" 
							? columnsWithCompany 
							: AuditLogColumns
					}
					apiRef={tableAPIRef}
					loading={isLoading}
					pageCount={pageCount}
					pagination={
						<CustomPagination 
							pageCount={pageCount} 
							pageState={page}
							handleChange={handlePageChange}
						/>
					}
				/>
			</Box>
		</Modal>
	);
};
 
export default OrgAuditLogs;