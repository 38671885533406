import {HttpClient} from "../requests";
import ENDPOINTS from "../endpoints";
import { 
    AuditLogDto,
    AuditLogPaginator, 
    QueryAuditLogDto, 
    SearchAuditLogDto
} from "../../../models/audit-log.dto";

export class Audit {
    getAuditLog = (query?: QueryAuditLogDto) => HttpClient
        .get<AuditLogPaginator>(ENDPOINTS.AUDIT.AUDIT_LOG.GET, query);
    getAuditLogSearch = (query?: SearchAuditLogDto) => HttpClient
        .get<AuditLogPaginator>(ENDPOINTS.AUDIT.AUDIT_LOG.SEARCH, query);
    getAuditLogById = (auditLogId: number) => HttpClient
        .get<{ data: AuditLogDto }>(ENDPOINTS.AUDIT.AUDIT_LOG.DETAIL
            .replace('{auditLogId}', String(auditLogId)));
}