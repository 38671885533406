import { PaginatorInfo } from "./generic.dto";

export type Payslips = {
  id: number;
  companyId: number;
  employeeId: number;
  payPeriodId: number;
  basicPayAmount: number;
  basicPayPerPayPeriod: number;
  allowanceAmount: number;
  overtimeAmount: number;
  bonusAmount: number;
  grossAmount: number;
  employeePensionAmount: number;
  employerPensionAmount: number;
  employeeSavingSchemeAmount: number;
  employerSavingSchemeAmount: number;
  backpayAmount: number;
  partialSalaryAmount: number;
  salaryAdvanceAmount: number;
  unionDeductionsAmount: number;
  loanRepaymentAmount: number;
  taxAmount: number;
  netPayAmount: number;
  employee: EmployeeDao;
  salaryItems: SalaryItemDto[];
  payPeriod: PayPeriod
}
	
export type SalaryItemDto = {
  id: number;
  name: string;
  type: string;
  amount: number;
  balance: number
}

type EmployeeDao = {
  id: number;
  title: string;
  firstName: string;
  lastName: string;
  otherNames: string;
  companyId: number;
  notchId: number;
  bandId: number;
  status: string;
  ssn: string;
  taxIdentificationNumber: string;
  majorGradeLevelId: number;
  minorGradeLevelId: number;
  employmentDate: string;
  terminationDate: string;
}

export type PayrollPeriodDto = {
  payPeriodId: number
  companyId: number;
  initiatedBy: number;
  considerEmployeesWithExpiredContract: boolean;
  basic: boolean;
  considerAllEmployees: boolean;
  applicableAllowanceIds: number[];
  applicableBenefitIds: number[];
  applicableEmployeeIds: number[];
  applicableEmployeeBandIds: number[];
}

export type PayrollRuns =  {
  id: number;
  payPeriodId: number;
  payPeriod: PayPeriod;
  companyId: number;
  totalCost: number;
  initiatedBy: number;
  createdAt: string;
  numberOfEmployees: number;
  processedEmployees: number;
  status: PayrollRunStatus;
  isApproved: boolean;
}

export enum PayrollRunStatus {
  SUBMITTED = "SUBMITTED",
  PROCESSING = "PROCESSING",
  COMPLETED = "COMPLETED",
  ERROR = "ERROR",
}

export class CreatePayrollDto {
  payPeriodId!: number
  companyId!: number;
  considerEmployeesWithExpiredContracts!: boolean;
  considerAllComponents!: boolean;
  basic!: boolean;
  considerAllEmployees!: boolean;
  applicableAllowanceIds!: number[];
  applicableBenefitIds!: number[];
  applicableEmployeeIds!: number[];
  applicableEmployeeBandIds!: number[];
  applicableBonusIds!: number[];
}

export class QueryParam {
  adminId?: number;
}

export class CreatePayrollObject {
    payrollRunId!: number
}
  
export type PayPeriod = {
  id: number;
  organizationId: string;
  companyId: number;
  code: string;
  year: string;
  taxCodeId: number;
  startDate: string;
  endDate:string;
  sequenceNumber: number;
  timePeriod: string,
  daysInPayPeriod: number;
}

export class QueryPayrollDto {
  company_id?: number;
  adminId?: number;

  page?: number = 1;
  limit?: number;
}

export class QueryCompanyPayslipsDto {
  adminId?: number;
  
  page?: number = 1;
  limit?: number;
}

export class QueryEmployeePayslipsDto {
  adminId?: number;
  employeeId?: number;
  payPeriodId?: number;
}

export type PayrollRunStatusObject = {
  processed: number;
  total: number;
}

export type MigrationDto = {
  timestamp: Date;
  operation: string;
  exception: string;
  licenseFailed: boolean;
  initialSchemaVersion: string;
  targetSchemaVersion: string;
  schemaName: string;
  migrations: migration[];
  migrationsExecuted: number;
  success: boolean;
  flywayVersion: string;
  database: string;
  warnings: string[];
  totalMigrationTime: number;
} 

type migration = {
  category: string;
  version: string;
  description: string;
  type: string;
  filepath: string;
  executionTime: number;
}
export class QueryReportDto {
  adminId?: number;
  companyId?: number;
  payPeriodId?: number;
}

export type SSFResponseDto = {
	id: string;
	name: string;
	ssn: string;
	basicSalary: number;
	employeeContribution: number;
	employerContribution: number;
	total: number;
	tierAmount: number;
}

export type  SavingSchemeReportResponseDto = {
	id: string;
	name: string;
	employeeContribution: number;
	accountCode: string;
	branchCode: string;
}

export class DownloadReportQueryDto {
  adminId?: number;
  type?: ReportType;
  companyId?: number;
  payPeriodId?: number;
}

export enum ReportType {
  SAVING_SCHEME = 'SAVING_SCHEME',
  SSF_TIER_ONE = 'SSF_TIER_ONE',
  SSF_TIER_TWO = 'SSF_TIER_TWO',
}
export interface PayslipPaginator extends PaginatorInfo<Payslips> {}
export interface PayrollRunsPaginator extends PaginatorInfo<PayrollRuns> {}
export interface SSFResponsePaginator extends PaginatorInfo<SSFResponseDto> {}
export interface SavingSchemeReportResponsePaginator extends PaginatorInfo<SavingSchemeReportResponseDto> {}
export interface DownloadReportPaginator extends PaginatorInfo<String> {}
