import {PaginatorInfo} from "./generic.dto";

export const PAY_PERIOD_TIME_TYPE = {
    DAILY: 'DAILY',
    WEEKLY: 'WEEKLY',
    FORTNIGHTLY: 'FORTNIGHTLY',
    MONTHLY: 'MONTHLY',
    QUARTERLY: 'QUARTERLY',
    BIANNUALLY: 'BIANNUALLY',
    ANNUALLY: 'ANNUALLY'
};

export type PAY_PERIOD_TIME_TYPE = keyof typeof PAY_PERIOD_TIME_TYPE


type PayPeriod = {
    id: number
    organizationId: string
    companyId: number
    code: string
    year: number
    taxCodeId: number
    startDate: string
    endDate: string
    sequenceNumber: number
    timePeriod: PAY_PERIOD_TIME_TYPE
    createdAt: string
    modifiedAt: string | null
}

export interface PayPeriodDto extends PayPeriod {
}

export class CreatePayPeriodDto {
    organizationId!: string
    companyId!: number
    code!: string
    taxCodeId!: number
    year!: number
    startDate!: string
    endDate!: string
    sequenceNumber!: number
    timePeriod!: PAY_PERIOD_TIME_TYPE
}

export class UpdatePayPeriodDto {
    organizationId?: string
    companyId?: number
    code?: string
    year?: number
    taxCodeId?: number
    startDate?: string
    endDate?: string
    sequenceNumber?: number
    timePeriod?: PAY_PERIOD_TIME_TYPE
}

export interface PayPeriodPaginator extends PaginatorInfo<PayPeriodDto> {}

export class QueryPayPeriodDto {
    organizationId?: string;
    companyId?: number;
    code?: string;
    year?: number;
    taxCodeId?: number;
    'year.gte'?: number;
    'year.lte'?: number;
    'startDate.gte'?: string;
    'startDate.lte'?: string;
    'endDate.gte'?: string;
    'endDate.lte'?: string;
    sequenceNumber?: number;
    timePeriod?: PAY_PERIOD_TIME_TYPE;
    page?: number = 1;
    limit?: number;
    orderBy?: PayPeriodOrderBy = PayPeriodOrderBy.CREATED_AT_ASC;
  }
  
  export class SearchPayPeriodDto {
    q?: string;
    page?: number = 1;
    limit?: number;
    orderBy?: PayPeriodOrderBy = PayPeriodOrderBy.CREATED_AT_ASC;
  }
  
  export enum PayPeriodOrderBy {
    CREATED_AT_ASC = 'createdAt:asc',
    CREATED_AT_DESC = 'createdAt:desc',
    MODIFIED_AT_ASC = 'modifiedAt:asc',
    MODIFIED_AT_DESC = 'modifiedAt:desc',
    CODE_ASC = 'code:asc',
    CODE_DESC = 'code:desc',
    YEAR_ASC = 'year:asc',
    YEAR_DESC = 'year:desc',
    START_DATE_ASC = 'startDate:asc',
    START_DATE_DESC = 'startDate:desc',
    END_DATE_ASC = 'endDate:asc',
    END_DATE_DESC = 'endDate:desc',
    SEQUENCE_NUMBER_ASC = 'sequenceNumber:asc',
    SEQUENCE_NUMBER_DESC = 'sequenceNumber:desc',
  }