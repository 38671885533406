import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import Label from "../../../../../components/form_fields/Label";
import OrangeButton from "../../../../../components/buttons/OrangeButton";
import FormDescriptionInput from "../../../../../components/form_fields/FormDescriptionInput";
import { CompanyLevelDto } from "../../../../../models/company-level.dto";
import { EmployeeBandDto } from "../../../../../models/employee-band.dto";
import { useCreateJobTitle } from "../../../../../services/job-title.service";
import APISelectField from "../../../../../components/form_fields/APISelectField";
import useUserStore from "../../../../../state-management/useUserStore";
import { DisabilitiesDto } from "../../../../../models/disabilities.dto";
import useCompanyStore from "../../../../../state-management/useCompanyStore";
import { useManageAccordionsData } from "../../../../../helpers/useManageAccordions";
import FormCodeInput from "../../../../../components/form_fields/FormCodeInput";
import MultiSelectField from "../../../../../components/form_fields/MultiSelectField";
import { PATHS } from "../../../../../routes/routes.paths";

interface IFormInputs {
	name: string;
	code: string;
	description: string;
    employeeBandId: number;
    companyLevelId: number;
    maximumAge?: number;
    minimumAge?: number;
    minimumExperienceYears?: number;
    acceptDisability: boolean;
    acceptedDisabilities?: number[];
}

interface INewJobTitleForm {
	companyLevels: CompanyLevelDto[];
	employeeBands: EmployeeBandDto[];
	disabilities: DisabilitiesDto[];
	setShowNewJobTitleForm: React.Dispatch<React.SetStateAction<boolean>>;
}

const NewJobTitleForm = ({
	companyLevels,
	employeeBands,
	disabilities,
	setShowNewJobTitleForm,
}: INewJobTitleForm) => {
	const { currentUser } = useUserStore();
	const { currentCompany } = useCompanyStore();
	const [uploading, setUploading] = useState(false);
	 
	const {
		register,
		watch,
		handleSubmit,
		formState: { errors, isSubmitted },
	} = useForm<IFormInputs>();

	const maximumAge = watch("maximumAge");
	const acceptDisability = watch("acceptDisability");
	const [acceptedDisabilities, setAcceptedDisabilities] = useState<number[]>([]);
	const { mutate: createJobTitle } = useCreateJobTitle();
	const { addNewAccordionsData } = useManageAccordionsData();
	
	const onSubmit: SubmitHandler<IFormInputs> = (data) => {
		if (acceptDisability && !acceptedDisabilities.length) return;
		
		setUploading(true);

		createJobTitle(
			{
				payload: {
					...data,
					acceptedDisabilities: acceptedDisabilities.length ? acceptedDisabilities : undefined,
					companyId: currentCompany?.id as number,
					organizationId: currentUser.user.organization?.id as string,
				}
			},
			{
				onSuccess: () => {
					addNewAccordionsData();
					setShowNewJobTitleForm(false);
				},
				onSettled: () => setUploading(false),
			}
		)
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="grid grid-cols-2 gap-x-4 gap-y-6 mt-6">
				<div className="w-full">
					<Label title="Name" for={`name`} />
					<input
						placeholder={"Enter Name"}
						type="text"
						{...register(`name`, {
							required: "Enter name here",
						})}
						id={`name`}
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
							errors?.name
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
					/>
					{errors?.name && (
						<p className="text-red-500 text-sm mt-1">
							{errors?.name?.message}
						</p>
					)}
				</div>
				<div className="w-full">
					<Label title="Unique Code" for="code" />
					<FormCodeInput
						register={register}
						attributes={{
							className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
								errors.code
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`,
						}}
					/>
					{errors?.code && (
						<p className="text-red-500 text-sm mt-1">
							{errors?.code?.message}
						</p>
					)}
				</div>
				<div className="col-span-2 w-full">
					<Label title="Description" for={`description`} />
					<FormDescriptionInput 
						attributes={{
							className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
								errors.description
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`
						}}
						register={register}
						required="Enter brief description here"
					/>
					{errors?.description && (
						<p className="text-red-500 text-sm mt-1">
							{errors?.description?.message}
						</p>
					)}
				</div>
				<div className="w-full">
					<Label title="Employee Band" for="employeeBandId" />
					<APISelectField 
						key="employeeBandId"
						attributes={{
							id: "employeeBandId",
						}}
						placeholder="Select Employee Band"
						register={register}
						name="employeeBandId"
						options={employeeBands}
						pathname={PATHS.SUB_COMPANY_SETTINGS.BANDS}
						required={"Select employee band here"}
						error={errors.employeeBandId}
						valueAsNumber
					/>
					{errors.employeeBandId && (
						<p className="text-red-500 text-sm mt-1">
							{errors.employeeBandId.message}
						</p>
					)}
				</div>
				<div className="w-full">
					<Label title="Company Level" for="companyLevelId" />
					<APISelectField 
						key="companyLevelId"
						attributes={{
							id: "companyLevelId",
						}}
						placeholder="Select Company Level"
						register={register}
						name="companyLevelId"
						options={companyLevels}
						mapText="levelName"
						pathname={PATHS.SUB_COMPANY_SETTINGS.COMPANY_LEVELS}
						required={"Select company level here"}
						error={errors.companyLevelId}
						valueAsNumber
					/>
					{errors.companyLevelId && (
						<p className="text-red-500 text-sm mt-1">
							{errors.companyLevelId.message}
						</p>
					)}
				</div>
				<div className="col-span-2 flex space-x-3 items-center my-2.5">
					<input 
						type="checkbox"
						{...register("acceptDisability")}
						id="acceptDisability"
					/>
					<label htmlFor="acceptDisability">Accept Disability</label>
				</div>
				<div className="w-full">
					<Label title="Minimum Age" for={`minimumAge`} optional />
					<input
						placeholder={"Enter minimum age"}
						type="number"
						{...register(`minimumAge`, {
							required: false,
							valueAsNumber: true,
							validate: (value) => {
								if (value && maximumAge && value > maximumAge) {
									return "Must be less than the maximum age"
								}
								return true
							}
						})}
						id={`minimumAge`}
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
							errors?.minimumAge
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
					/>
					{errors?.minimumAge && (
						<p className="text-red-500 text-sm mt-1">
							{errors?.minimumAge?.message}
						</p>
					)}
				</div>
				<div className="w-full">
					<Label title="Maximum Age" for={`maximumAge`} optional />
					<input
						placeholder={"Enter maximum age"}
						type="number"
						{...register(`maximumAge`, {
							required: false,
							valueAsNumber: true
						})}
						id={`maximumAge`}
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
							errors?.maximumAge
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
					/>
					{errors?.maximumAge && (
						<p className="text-red-500 text-sm mt-1">
							{errors?.maximumAge?.message}
						</p>
					)}
				</div>
				<div className="w-full">
					<Label title="Minimum Experience Years" for={`minimumExperienceYears`} optional />
					<input
						placeholder={"Enter minimum experience years"}
						type="number"
						{...register(`minimumExperienceYears`, {
							required: false,
							valueAsNumber: true
						})}
						id={`minimumExperienceYears`}
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
							errors?.minimumExperienceYears
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
					/>
					{errors?.minimumExperienceYears && (
						<p className="text-red-500 text-sm mt-1">
							{errors?.minimumExperienceYears?.message}
						</p>
					)}
				</div>
				{acceptDisability && (
					<div className="w-full">
						<Label title="Accepted Disabilities" for="acceptedDisabilities" />
						<MultiSelectField
							key={"acceptedDisabilities"}
							options={disabilities}
							setResourceIds={setAcceptedDisabilities}
							placeholder={"Select Disabilities"}
							required={acceptDisability && isSubmitted}
						/>
					</div>
				)}
			</div>

			<div className="w-full flex items-center justify-between my-8">
				<button
					onClick={() => setShowNewJobTitleForm(false)}
					className="px-10 py-4 border text-gray-400 hover:text-gray-500 border-gray-300 rounded-lg text-2xs"
				>
					Close Form
				</button>
				<OrangeButton
					title={`${uploading ? "Saving..." : "Add Job Title"}`}
					type="submit"
					className={"h-14 px-8"}
					disabled={uploading}
				/>
			</div>
		</form>
	);
};

export default NewJobTitleForm;
