import { AiOutlinePlus } from "react-icons/ai";
import EditPayPeriod from "./EditPayPeriod";
import NewPayPeriod from "./NewPayPeriod";
import SkewLoader from "react-spinners/SkewLoader";
import { useState } from "react";
import { PayPeriodDto } from "../../../../../models/pay-period.dto";
import { useGetPayPeriod } from "../../../../../services/pay-period.service";
import { APP_CONFIG } from "../../../../../helpers/appHelpers";
import { useGetTaxCode } from "../../../../../services/tax-code.service";
import { TaxCodeOrderBy } from "../../../../../models/tax-code.dto";
import useCompanyStore from "../../../../../state-management/useCompanyStore";
import CustomPagination from "../../../../../components/table/CustomPagination";
import { useManageAccordions } from "../../../../../helpers/useManageAccordions";

const PayPeriods = () => {
	const { currentCompany } = useCompanyStore();
	const [showNewPayPeriodForm, setShowNewPayPeriodForm] = useState(false);

	const {
		accordionsData: payPeriods,
		isLoading,
		pageCount,
    } = useManageAccordions<PayPeriodDto>({
		fetchHook: useGetPayPeriod,
		options: { companyId: currentCompany?.id }
	});

	const { 
		data: taxCodes,
		isLoading: loadingTaxCodes, 
	} = useGetTaxCode({
		countryId: currentCompany?.countryId as number,
		limit: APP_CONFIG.PAGE_LIMIT,
		orderBy: TaxCodeOrderBy.CREATED_AT_DESC
    });
	
	return (
		<div>
			<div className="border-b-[1.5px] pb-3 mb-4 flex w-full justify-between text-sm">
				<h2 className="font-semibold">Available Pay Periods</h2>
				{!showNewPayPeriodForm && (
					<button
						onClick={() => setShowNewPayPeriodForm(true)}
						className="border-none flex items-center text-primary-blue"
					>
						<AiOutlinePlus className="mr-2 text-lg" />
						Add New Pay Period
					</button>
				)}
			</div>
			
			{showNewPayPeriodForm && (
				<NewPayPeriod 
					taxCodes={taxCodes?.data || []}
					setShowNewPayPeriodForm={setShowNewPayPeriodForm} 
				/>
			)}
			
			{(isLoading && !payPeriods.length) && (
				<div className="text-center mt-10 py-10">
					<SkewLoader color="#F58B00" />
				</div>
			)}

			{!loadingTaxCodes && (
				<>
				{payPeriods.map((payPeriod) => (
					<EditPayPeriod 
						key={payPeriod.id}
						payPeriod={payPeriod} 
						taxCodes={taxCodes?.data || []}
					/>
				))}
				</>
			)}

			{(!isLoading && !payPeriods.length) && (
				<div>
					<h3 className="text-center text-sm font-semibold mt-10">
						Sorry no pay periods added yet. Click 'Add New Pay Period' to begin.
					</h3>
				</div>
			)}

			<div className="w-full flex justify-end mt-8">
				<CustomPagination pageCount={pageCount} />
			</div>
		</div>
	);
};

export default PayPeriods;
