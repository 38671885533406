import {useQuery, useInfiniteQuery, useQueryClient, useMutation} from "react-query";
import {toast} from 'react-toastify';
import API from './utils/api';
import { PasswordPolicy, SetPasswordPolicyDto } from "../models/password-policy.dto";
import ENDPOINTS from "./utils/endpoints";

export function useGetPasswordPolicy() {
    const url = ENDPOINTS.USER.PASSWORD_POLICY.GET
    const request = API.UserAPI.getPasswordPolicy();

    const {data, isLoading, error} = useQuery<{ data: PasswordPolicy }, Error>(
        [url],
        (() => request)
    );

    return {
        data: data,
        isLoading,
        error,
    };
}

export function useGetDefaultPasswordPolicy() {
    const url = ENDPOINTS.USER.PASSWORD_POLICY.GET_DEFAULT
    const request = API.UserAPI.getDefaultPasswordPolicy();

    const {data, isLoading, error} = useQuery<{ data: PasswordPolicy }, Error>(
        [url],
        (() => request),
    );

    return {
        data: data,
        isLoading,
        error,
    };
}

export function useSetPasswordPolicy() {
    const queryClient = useQueryClient();

    return useMutation(
        ({payload}: {payload: SetPasswordPolicyDto}) => API.UserAPI.setPasswordPolicy(payload),
        {
            onSuccess: () => {
                toast.success('Record saved successfully');
                queryClient.invalidateQueries(ENDPOINTS.USER.PASSWORD_POLICY.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useClearPasswordPolicy() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ }: {  }) => API.UserAPI.clearPasswordPolicy(),
        {
            onSuccess: () => {
                toast.success('Restored policy to the default successfully');
                queryClient.invalidateQueries(ENDPOINTS.USER.PASSWORD_POLICY.GET);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useGetUserOrgPasswordPolicy() {

    return useMutation(
        ({ username }: { username: string }) => API.UserAPI.getUserOrgPasswordPolicy(username),
        {
            onSuccess: () => {},
            onError: API.Handlers.onErrorHandler,
        }
    )
}