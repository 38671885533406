import React from "react";
import Header from "../header";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../routes/routes.paths";
import useCompanyStore from "../../state-management/useCompanyStore";

interface CompanySetupLayoutProps {
	children?: React.ReactNode;
	pathname?: string;
}

const CompanySetupLayout = ({
	children,
	pathname,
}: CompanySetupLayoutProps) => {
	const navigate = useNavigate();
	const { savedCompanies } = useCompanyStore();

	return (
        <div className={"flex h-[100svh] w-screen relative overflow-y-auto"}>
            <div
                className={`grow ${
                    (pathname === PATHS.COMPANY_ACCOUNT_SETUP ||
                    pathname === PATHS.SUBSCRIPTION_PAYMENT)
                        ? "w-full"
                        : "flex flex-col"
                }`}
            >
                <div className="fixed z-50 w-full">
                    <Header 
                        companies={savedCompanies}
                        onMenuClick={() => {}} 
                    />
                </div>
                <div className="p-10 grow bg-white overflow-x-hidden overflow-y-auto mt-[50px] w-full">
                    {children}
                </div>
            </div>
        </div>
	);
};

export default CompanySetupLayout;
