import { useState } from "react";
import ModalComponent from "../../../../components/modals/ModalFormComponent";
import Label from "../../../../components/form_fields/Label";
import { SubmitHandler, useForm } from "react-hook-form";
import { useCreateOvertime, useUpdateOvertime } from "../../../../services/overtime.service";
import useEditFormStore from "../../../../state-management/useEditFormStore";
import { enumToStringConverter, IAddEditTableForm } from "../../../../helpers/appHelpers";
import FormDescriptionInput from "../../../../components/form_fields/FormDescriptionInput";
import APISelectField from "../../../../components/form_fields/APISelectField";
import { EmployeeBandDto } from "../../../../models/employee-band.dto";
import OrangeButton from "../../../../components/buttons/OrangeButton";
import { useManageTableWithSearchParamsData } from "../../../../helpers/useManageTable";
import useCompanyStore from "../../../../state-management/useCompanyStore";
import { OvertimePaymentTier } from "../../../../models/overtime.dto";
import { CompanyCurrencyConfigDto } from "../../../../models/company-currency.dto";
import MoneyInput from "../../../../components/form_fields/MoneyInput";
import { OVERTIME_PAYMENT_TIER_TYPE } from "../../../../models/overtime-payment-tier.dto";
import { AiOutlinePlus } from "react-icons/ai";
import { BsTrash } from "react-icons/bs";
import FormCodeInput from "../../../../components/form_fields/FormCodeInput";
import { PATHS } from "../../../../routes/routes.paths";

type CreateOvertimePaymentTier = Omit<OvertimePaymentTier, "type"> & { paymentType: string };
type IFormInputs = {
	name: string;
	code: string;
	description: string;
	minHoursRequired: number;
	maxHoursPermitted: number;
	employeeBandId: number;
	taxable: boolean;
	active: boolean;
	paymentTiersCount?: number;
	currencyId?: number;
	overtimePaymentTiers?: CreateOvertimePaymentTier[];
}

type OvertimeModalFormProps = IAddEditTableForm & {
	employeeBands: EmployeeBandDto[];
	companyCurrencies: CompanyCurrencyConfigDto[];
}

const OvertimeModalForm = ({
	open,
	setOpen,
	title,
	isEditForm,
	tableAPIRef,
	employeeBands,
	companyCurrencies,
}: OvertimeModalFormProps) => {
	const { currentCompany } = useCompanyStore();
	const { formDataValues } = useEditFormStore();
	const [paymentTiersCount, setPaymentTiersCount] = useState(0);
	const [uploading, setUploading] = useState(false);
	
	const {
		register,
		handleSubmit,
		setValue,
		reset,
		formState: { errors },
	} = useForm<IFormInputs>({
		defaultValues: {
			name: isEditForm ? formDataValues?.name : "",
			code: isEditForm ? formDataValues?.code : "",
			description: isEditForm ? formDataValues?.description : "",
			minHoursRequired: isEditForm ? formDataValues?.minHoursRequired : "",
			maxHoursPermitted: isEditForm ? formDataValues?.maxHoursPermitted : "",
			employeeBandId: isEditForm ? formDataValues?.employeeBandId : "",
			taxable: isEditForm ? formDataValues?.taxable : "",
			active: isEditForm ? formDataValues?.active : "",
		}
	});

	const { mutate: createOvertime } = useCreateOvertime();
	const { mutate: updateOvertime } = useUpdateOvertime();
	const { addNewTableData } = useManageTableWithSearchParamsData();

	const onSubmit: SubmitHandler<IFormInputs> = (data: any) => {
		setUploading(true);

		if (isEditForm) {
			updateOvertime(
				{ payload: data, id: formDataValues?.id }, 
				{
					onSuccess: (updatedData) => {
						tableAPIRef?.current.updateRows([updatedData.data]);
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		} else {
			const overtimePaymentTiers = data.overtimePaymentTiers?.map(
				({ paymentType, ...others }: CreateOvertimePaymentTier) => ({ ...others, type: paymentType })
			);

			data.overtimePaymentTiers = paymentTiersCount ? overtimePaymentTiers.slice(0, paymentTiersCount) : undefined;
			delete data.paymentTiersCount;
			delete data.currencyId;

			createOvertime(
				{
					payload: {
						...data,
						companyId: currentCompany?.id,
					}
				}, 
				{
					onSuccess: () => {
						addNewTableData();
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		}
	};

	const handleClose = () => {
		setOpen(false);
		reset();
	};

	return (
		<ModalComponent open={open} title={title} handleClose={handleClose}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="grid grid-cols-2 gap-x-4 gap-y-6">
					<div className="w-full">
						<Label title="Name" for="name" />
						<input
							defaultValue={isEditForm ? formDataValues?.name : ""}
							placeholder={"Enter name"}
							type="text"
							{...register("name", {
								required: "Enter name here",
							})}
							id="name"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.name
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.name && (
							<p className="text-red-500 text-sm mt-1">{errors.name.message}</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Unique Code" for="code" />
						<FormCodeInput
							register={register}
							attributes={{
								className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
									errors.code
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`,
							}}
						/>
						{errors.code && (
							<p className="text-red-500 text-sm mt-1">
								{errors.code.message}
							</p>
						)}
					</div>
					<div className="w-full col-span-2">
						<Label title="Description" for="description" />
						<FormDescriptionInput 
							register={register}
							attributes={
								{
								defaultValue: isEditForm ? formDataValues?.description : "",
								className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
									errors.description
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`
							}}
						/>
						{errors.description && (
							<p className="text-red-500 text-sm mt-1">
								{errors.description.message}
							</p>
						)}
					</div>
					<div className="flex flex-col w-full">
						<Label
							title="Minimum Number of Hours Required"
							for="minHoursRequired"
						/>
						<div className="flex items-center mt-2 h-14">
							<input
								defaultValue={isEditForm ? formDataValues?.minHoursRequired : undefined}
								placeholder={"0"}
								type="number"
								{...register("minHoursRequired", {
									required: "Enter minimum number of hours required here",
									valueAsNumber: true,
									validate: (value, formData) => {
										const maxHoursPermitted = formData.maxHoursPermitted;
										if (maxHoursPermitted && value >= maxHoursPermitted) {
											return "Must be less than the max hours permitted"
										}
										return true
									}
								})}
								id="minHoursRequired"
								className={`w-full bg-secondary-gray rounded-l-lg h-full px-5 text-sm ${
									errors.minHoursRequired
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
							/>
							<div className="h-full w-36 text-sm bg-black rounded-r-lg text-white flex items-center justify-center">
								Hours
							</div>
						</div>
						{errors.minHoursRequired && (
							<p className="text-red-500 text-sm mt-1">
								{errors.minHoursRequired.message}
							</p>
						)}
					</div>
					<div className="flex flex-col w-full">
						<Label
							title="Maximum Number of Hours Permitted"
							for="maxHoursPermitted"
						/>
						<div className="flex items-center mt-2 h-14">
							<input
								defaultValue={isEditForm ? formDataValues?.maxHoursPermitted : undefined}
								placeholder={"0"}
								type="number"
								{...register("maxHoursPermitted", {
									required: "Enter maximum number of hours permitted here",
									valueAsNumber: true,
								})}
								id="maxHoursPermitted"
								className={`w-full bg-secondary-gray rounded-l-lg h-full px-5 text-sm ${
									errors.maxHoursPermitted
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
							/>
							<div className="h-full w-36 text-sm bg-black rounded-r-lg text-white flex items-center justify-center">
								Hours
							</div>
						</div>
						{errors.maxHoursPermitted && (
							<p className="text-red-500 text-sm mt-1">
								{errors.maxHoursPermitted.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Employee Band" for="employeeBandId" />
						<APISelectField 
							key="employeeBandId"
							attributes={{
								id: "employeeBandId",
								defaultValue: isEditForm ? formDataValues?.employeeBandId : undefined
							}}
							placeholder="Select Employee Band"
							register={register}
							name="employeeBandId"
							options={employeeBands}
							pathname={PATHS.SUB_COMPANY_SETTINGS.BANDS}
							required={"Select employee band here"}
							error={errors.employeeBandId}
							valueAsNumber
						/>
						{errors.employeeBandId && (
							<p className="text-red-500 text-sm mt-1">
								{errors.employeeBandId.message}
							</p>
						)}
					</div>
					<div className="col-span-2 flex space-x-3 items-center">
						<input 
							type="checkbox"
							{...register("taxable", {
								required: false,
							})}
							id="taxable"
						/>
						<label htmlFor="taxable">Taxable</label>
					</div>
					<div className="col-span-2 flex space-x-3 items-center">
						<input 
							type="checkbox"
							{...register("active", {
								required: false,
							})}
							id="active"
						/>
						<label htmlFor="active">Active</label>
					</div>
				</div>
				{!isEditForm && (
					<div className="mt-6">
						<h3 className="font-semibold text-base flex items-center gap-1.5">
							<span>Set Payment Tier(s) </span>
						</h3>
						{Array.from({ length: paymentTiersCount }).map((_, index) => (
							<div key={index} className="flex items-start gap-4 mt-6">
								<h6 className="font-bold whitespace-nowrap text-sm flex flex-col gap-3">
									<p>Tier {index + 1}</p>
									{(index + 1 === paymentTiersCount) && (
										<button 
											type="button"
											onClick={() => setPaymentTiersCount(prev => prev - 1)}
										>
											<BsTrash className="text-xl hover:scale-[1.1]" />
										</button>
									)}
								</h6>
								<div className="grid grid-cols-2 gap-x-4 gap-y-6">
									<div className="w-full">
										<Label title="Payment Type" for={`overtimePaymentTiers.${index}.paymentType`} />
										<select
											className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
													errors.overtimePaymentTiers && errors.overtimePaymentTiers[index]?.paymentType
													? " border border-red-500 focus:border-red-500 focus:outline-red-500"
													: "border-none"
											}`}
											{...register(`overtimePaymentTiers.${index}.paymentType`, {
												required: "Select payment type here",
											})}
										>
											<option value="">
												Select Type
											</option>
											{Object.entries(OVERTIME_PAYMENT_TIER_TYPE).map(([key, value]) => (
												<option key={key} value={value} >
													{enumToStringConverter(value)}
												</option>
											))}
										</select>
										{errors.overtimePaymentTiers && errors.overtimePaymentTiers[index]?.paymentType && (
											<p className="text-red-500 text-sm mt-1">
												{errors.overtimePaymentTiers[index]?.paymentType?.message}
											</p>
										)}
									</div>
									<div className="w-full">
										<Label title="Currency" for={`overtimePaymentTiers.${index}.currencyId`} />
										<select
											className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
													errors.overtimePaymentTiers && errors.overtimePaymentTiers[index]?.currencyId
													? " border border-red-500 focus:border-red-500 focus:outline-red-500"
													: "border-none"
											}`}
											{...register(`overtimePaymentTiers.${index}.currencyId`, {
												required: "Select currency here",
												valueAsNumber: true,
											})}
										>
											<option value="">
												Select Currency
											</option>
											{companyCurrencies?.map((item) => (
												<option key={item?.id} value={item?.id}>
													{item?.currency?.name} ({item?.currency?.code})
												</option>
											))}
										</select>
										{errors.overtimePaymentTiers && errors.overtimePaymentTiers[index]?.currencyId && (
											<p className="text-red-500 text-sm mt-1">
												{errors.overtimePaymentTiers[index]?.currencyId?.message}
											</p>
										)}
									</div>
									<div className="w-full">
										<Label title="Fixed Component" for={`overtimePaymentTiers.${index}.fixedComponent`} />
										<MoneyInput 
											attributes={{
												id: `overtimePaymentTiers.${index}.fixedComponent`,
												placeholder: "Enter value"
											}}
											register={register}
											name={`overtimePaymentTiers.${index}.fixedComponent`}
											setValue={setValue}
											required={"Enter fixed component here"}
											error={errors.overtimePaymentTiers && errors.overtimePaymentTiers[index]?.fixedComponent}
										/>
										{errors.overtimePaymentTiers && errors.overtimePaymentTiers[index]?.fixedComponent && (
											<p className="text-red-500 text-sm mt-1">
												{errors.overtimePaymentTiers[index]?.fixedComponent?.message}
											</p>
										)}
									</div>
									<div className="w-full">
										<Label title="Factor Component" for={`overtimePaymentTiers.${index}.factorComponent`} />
										<MoneyInput 
											attributes={{
												id: `overtimePaymentTiers.${index}.factorComponent`,
												placeholder: "Enter value"
											}}
											register={register}
											name={`overtimePaymentTiers.${index}.factorComponent`}
											setValue={setValue}
											required={"Enter factor component here"}
											error={errors.overtimePaymentTiers && errors.overtimePaymentTiers[index]?.factorComponent}
										/>
										{errors.overtimePaymentTiers && errors.overtimePaymentTiers[index]?.factorComponent && (
											<p className="text-red-500 text-sm mt-1">
												{errors.overtimePaymentTiers[index]?.factorComponent?.message}
											</p>
										)}
									</div>
									<div className="flex flex-col w-full">
										<Label
											title="Minimum Number of Hours"
											for={`overtimePaymentTiers.${index}.minHours`}
										/>
										<div className="flex items-center mt-2 h-14">
											<input
												placeholder={"0"}
												type="number"
												{...register(`overtimePaymentTiers.${index}.minHours`, {
													required: "Enter minimum number of hours here",
													valueAsNumber: true,
													validate: (value, formData) => {
														const maxHoursPermitted = formData.maxHoursPermitted;
														const minHoursRequired = formData.minHoursRequired;
														const maxHours = (formData.overtimePaymentTiers || [])[index]?.maxHours;
														
														if (maxHours && value >= maxHours) {
															return "Must be less than the max hours"
														}
														if (value > maxHoursPermitted || value < minHoursRequired) {
															return `Value must be between ${minHoursRequired} and ${maxHoursPermitted}`
														}
														return true
													}
												})}
												id="minHours"
												className={`w-full bg-secondary-gray rounded-l-lg h-full px-5 text-sm ${
													errors.overtimePaymentTiers && errors.overtimePaymentTiers[index]?.minHours
														? " border border-red-500 focus:border-red-500 focus:outline-red-500"
														: "border-none"
												}`}
											/>
											<div className="h-full w-36 text-sm bg-black rounded-r-lg text-white flex items-center justify-center">
												Hours
											</div>
										</div>
										{errors.overtimePaymentTiers && errors.overtimePaymentTiers[index]?.minHours && (
											<p className="text-red-500 text-sm mt-1">
												{errors.overtimePaymentTiers[index]?.minHours?.message}
											</p>
										)}
									</div>
									<div className="flex flex-col w-full">
										<Label
											title="Maximum Number of Hours"
											for={`overtimePaymentTiers.${index}.maxHours`}
										/>
										<div className="flex items-center mt-2 h-14">
											<input
												placeholder={"0"}
												type="number"
												{...register(`overtimePaymentTiers.${index}.maxHours`, {
													required: "Enter maximum number of hours here",
													valueAsNumber: true,
													validate: (value, formData) => {
														const maxHoursPermitted = formData.maxHoursPermitted;
														const minHoursRequired = formData.minHoursRequired;

														if (value > maxHoursPermitted || value < minHoursRequired) {
															return `Value must be between ${minHoursRequired} and ${maxHoursPermitted}`
														}
														return true
													}
												})}
												id="maxHours"
												className={`w-full bg-secondary-gray rounded-l-lg h-full px-5 text-sm ${
													errors.overtimePaymentTiers && errors.overtimePaymentTiers[index]?.maxHours
														? " border border-red-500 focus:border-red-500 focus:outline-red-500"
														: "border-none"
												}`}
											/>
											<div className="h-full w-36 text-sm bg-black rounded-r-lg text-white flex items-center justify-center">
												Hours
											</div>
										</div>
										{errors.overtimePaymentTiers && errors.overtimePaymentTiers[index]?.maxHours && (
											<p className="text-red-500 text-sm mt-1">
												{errors.overtimePaymentTiers[index]?.maxHours?.message}
											</p>
										)}
									</div>
								</div>
							</div>
						))}
						<div
							className="w-fit flex items-center text-primary-blue cursor-pointer mt-6"
							onClick={() => setPaymentTiersCount(prev => prev + 1)}
						>
							<AiOutlinePlus className="mr-1 text-base" />
							<span className="text-sm">Add Payment Tier</span>
						</div>
					</div>
				)}
				
				<div className="w-full flex justify-end mt-8">
					<OrangeButton
						type="submit"
						title={
							uploading
							? "Uploading..."
							: isEditForm
								? "Update Overtime Type"
								: "Create Overtime Type"
						}
						className="bg-primary-mango text-white h-14 px-8 rounded-lg text-sm"
						disabled={uploading}
					/>
				</div>
			</form>
		</ModalComponent>
	);
};

export default OvertimeModalForm;
