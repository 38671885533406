import { GridActionsCellItem } from "@mui/x-data-grid";
import { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import ActionPopover from "../../../../../components/popovers/actionPopover";
import useEditFormStore from "../../../../../state-management/useEditFormStore";
import { Columns } from "./data";
import HolidayModalForm from "./modal";
import { useDeleteHoliday, useGetHoliday, useGetHolidaySearch } from "../../../../../services/holiday.service";
import useUserStore from "../../../../../state-management/useUserStore";
import { HolidayDto } from "../../../../../models/holiday.dto";
import APISearchInput from "../../../../../components/search/APISearchInput";
import CustomPagination from "../../../../../components/table/CustomPagination";
import DeleteModal from "../../../../../components/modals/DeleteModal";
import CustomStyledTable from "../../../../../components/table/CustomStyledTable";
import { useManageTableWithStateVariables } from "../../../../../helpers/useManageTable";

const Holidays = () => {
	const { currentUser } = useUserStore();
	const { formDataValues, setFormData } = useEditFormStore();
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [openModal, setOpenModal] = useState(false);
	const [isEditForm, setIsEditForm] = useState(false);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);

	const { mutate: searchHoliday } = useGetHolidaySearch();
	const {
        tableAPIRef,
		defaultTableData,
        tableData,
        isLoading,
        page,
        pageCount,
		setTableData,
		setSearching,
        addNewData,
		reloadData,
        handlePageChange,
	} = useManageTableWithStateVariables<HolidayDto>({ 
		fetchHook: useGetHoliday,
		options: {
			organizationId: currentUser.user.organizationId,
			orderBy: undefined,
		}
	});

	const handleClose = () => setAnchorEl(null);
	const handleOpenModal = () => {
		setAnchorEl(null);
		setIsEditForm(false);
		setOpenModal(true);
	};
	
	const [deleting, setDeleting] = useState(false);
	const { mutate: deleteHoliday } = useDeleteHoliday();

	const handleDeleteMethod = () => {
		setDeleting(true);

		deleteHoliday(
			{ id: formDataValues?.id },
			{
				onSuccess: () => {
					reloadData();
					setOpenDeleteModal(false);
				},
				onSettled: () => setDeleting(false),
			}
		);
	};

	const additionalColumns = [
		{
			field: "actions",
			type: "actions",
			headerName: "Actions",
			width: 150,
			headerClassName: "super-app-theme--header",
			cellClassName: "actions ",
			getActions: (params: any) => {
				return [
					<GridActionsCellItem
						icon={<BiDotsHorizontalRounded />}
						label="actions"
						onClick={(e: any) => {
							// console.log(params.row, "params");
							setFormData(params?.row);
							setAnchorEl(e.currentTarget);
						}}
					/>
				];
			},
		},
	];

	const columnsWithAdditions = Columns.map(
		(obj) => additionalColumns.find((o) => o.field === obj.field) || obj
	);

	return (
		<div>
			<h2 className="text-2sm font-semibold mb-3">Holidays</h2>
			<hr />
			<div className="flex items-center justify-between mt-7 mb-8">
				<APISearchInput
					fallbackData={defaultTableData}
					setTableData={setTableData}
					mutate={searchHoliday}
					setSearching={setSearching}
					popupTable
				/>
				<div
					className="flex items-center text-primary-blue text-sm cursor-pointer w-fit"
					onClick={handleOpenModal}
				>
					<AiOutlinePlus className="mr-1 text-sm" />
					Add New Holiday
				</div>
			</div>
			<CustomStyledTable
				rows={tableData}
				columns={columnsWithAdditions}
				apiRef={tableAPIRef}
				loading={isLoading}
				pageCount={pageCount}
				pagination={
					<CustomPagination 
						pageCount={pageCount} 
						pageState={page}
						handleChange={handlePageChange}
					/>
				}
				setAnchorEl={setAnchorEl}
			/>
			<ActionPopover
				id={anchorEl ? "simple-popover" : undefined}
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				handleClose={handleClose}
				setOpenDeleteModal={setOpenDeleteModal}
				setOpenEdit={setIsEditForm}
				setOpenModal={setOpenModal}
			/>
			<DeleteModal
				openDeleteModal={openDeleteModal}
				spanContent={formDataValues?.name}
				setOpenDeleteModal={setOpenDeleteModal}
				handleDeleteMethod={handleDeleteMethod}
				deleting={deleting}
			/>
			{openModal && (
				<HolidayModalForm
					open={openModal}
					setOpen={setOpenModal}
					title={
						isEditForm
						? "Update Holiday"
						: "Add New Holiday"
					}
					isEditForm={isEditForm}
					tableAPIRef={tableAPIRef}
					addNewData={addNewData}
				/>
			)}
		</div>
	);
};

export default Holidays;
