import { useMemo } from "react";
import AdminLayout from "../../components/layouts/AdminLayout";
import HumanResourceLayout from "../../components/layouts/HumanResourceLayout";
import PayrollCompanyLayout from "../../components/layouts/PayrollCompanyLayout";
import SelfserviceLayout from "../../components/layouts/SelfserviceLayout";
import useUserStore from "../../state-management/useUserStore";

enum ErrorType {
    NOT_FOUND = "NOT_FOUND",
    FORBIDDEN = "FORBIDDEN",
}

const ErrorPage = ({ errorType }: { errorType: string }) => {
	const { userType } = useUserStore();
	const LAYOUT = useMemo(() => {
		switch(userType) {
			case "ORGANIZATION": 
				return PayrollCompanyLayout;
			case "ADMIN": 
				return AdminLayout;
			case "HR": 
				return HumanResourceLayout;
			default:
				return SelfserviceLayout;
		}
	}, [userType])

    return (
        <LAYOUT pageHeader="">
            <div className="grow flex justify-center items-center text-center">
                <div className="-translate-y-1/2">
                    <h1 className="text-9xl max-md:text-5xl font-bold">404</h1>
                    <p>
                        {errorType === ErrorType.FORBIDDEN 
                            ? "ACCESS DENIED! You can only access the pages outlined on the dashboard."
                            : "ERROR! The page you are looking for does not exist." 
                        } 
                    </p>
                </div>
            </div>
        </LAYOUT>
    );
}
 
export default ErrorPage;