import { GridColDef } from "@mui/x-data-grid";
import { enumToStringConverter, formatDateAndTime, moneyFormat } from "../../../helpers/appHelpers";
import { DataAction } from "../../../models/employee-allowance-history.dto";

export const AuditLogColumns: GridColDef[] = [
	{
		field: "timestamp",
		headerName: "Timestamp",
		width: 320,
		editable: true,
		headerClassName: "super-app-theme--header",
		sortComparator: (date1, date2) => new Date(date1).getTime() - new Date(date2).getTime(),
		valueGetter: (params) => formatDateAndTime(params),
	},
	{
		field: "user",
		headerName: "User",
		width: 320,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value?.name || "User",
	},
	{
		field: "action",
		headerName: "Action",
		width: 450,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	// {
	// 	field: "input",
	// 	headerName: "Input",
	// 	width: 220,
	// 	editable: true,
	// 	headerClassName: "super-app-theme--header",
	// 	valueGetter: (params) => params.value || "--",
	// },
	{
		field: "modifiedAt",
		headerName: "Modified At",
		width: 320,
		editable: true,
		headerClassName: "super-app-theme--header",
		sortComparator: (date1, date2) => new Date(date1).getTime() - new Date(date2).getTime(),
		valueGetter: (params) => params.value ? formatDateAndTime(params) : "--",
	},
];

export const EmployeeEngagementHistoryColumns: GridColDef[] = [
	{
		field: "recordedAt",
		headerName: "Recorded At",
		width: 300,
		editable: true,
		headerClassName: "super-app-theme--header",
		sortComparator: (date1, date2) => new Date(date1).getTime() - new Date(date2).getTime(),
		valueGetter: (params) => formatDateAndTime(params),
	},
	{
		field: "jobTitle",
		headerName: "Job Title",
		width: 350,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value?.name,
	},
	{
		field: "contractType",
		headerName: "Contract Type",
		width: 350,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value?.name,
	},
	{
		field: "basicSalary",
		headerName: "Basic Salary",
		width: 350,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter(params) {
			const amount = params.value;
			const currency = params.row.currency;
			return amount ? `${currency?.code || ""} ${moneyFormat(amount)}` : "--";
		},
	},
	{
		field: "actor",
		headerName: "Actor",
		width: 280,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value?.name || "User",
	},
];

export const EmployeeBasicSalaryHistoryColumns: GridColDef[] = [
	{
		field: "doneAt",
		headerName: "Recorded At",
		width: 300,
		editable: true,
		headerClassName: "super-app-theme--header",
		sortComparator: (date1, date2) => new Date(date1).getTime() - new Date(date2).getTime(),
		valueGetter: (params) => formatDateAndTime(params),
	},
	{
		field: "previousValue",
		headerName: "Previous Value",
		width: 450,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter(params) {
			const amount = params.value;
			const currency = params.row.previousCurrency;
			return amount ? `${currency?.code || ""} ${moneyFormat(amount)}` : "--";
		},
	},
	{
		field: "currentValue",
		headerName: "Current Value",
		width: 450,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter(params) {
			const amount = params.value;
			const currency = params.row.currentCurrency;
			return amount ? `${currency?.code || ""} ${moneyFormat(amount)}` : "--";
		},
	},
	{
		field: "actor",
		headerName: "Actor",
		width: 280,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value?.name || "User",
	},
];

export const EmployeeJobTitleHistoryColumns: GridColDef[] = [
	{
		field: "doneAt",
		headerName: "Recorded At",
		width: 300,
		editable: true,
		headerClassName: "super-app-theme--header",
		sortComparator: (date1, date2) => new Date(date1).getTime() - new Date(date2).getTime(),
		valueGetter: (params) => formatDateAndTime(params),
	},
	{
		field: "previousValue",
		headerName: "Previous Value",
		width: 450,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value?.name,
	},
	{
		field: "currentValue",
		headerName: "Current Value",
		width: 450,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value?.name,
	},
	{
		field: "actor",
		headerName: "Actor",
		width: 280,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value?.name || "User",
	},
];

export const EmployeeBandHistoryColumns: GridColDef[] = [
	{
		field: "doneAt",
		headerName: "Recorded At",
		width: 300,
		editable: true,
		headerClassName: "super-app-theme--header",
		sortComparator: (date1, date2) => new Date(date1).getTime() - new Date(date2).getTime(),
		valueGetter: (params) => formatDateAndTime(params),
	},
	{
		field: "previousValue",
		headerName: "Previous Value",
		width: 450,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value?.name,
	},
	{
		field: "currentValue",
		headerName: "Current Value",
		width: 450,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value?.name,
	},
	{
		field: "actor",
		headerName: "Actor",
		width: 280,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value?.name || "User",
	},
];

export const EmployeeNotchHistoryColumns: GridColDef[] = [
	{
		field: "doneAt",
		headerName: "Recorded At",
		width: 300,
		editable: true,
		headerClassName: "super-app-theme--header",
		sortComparator: (date1, date2) => new Date(date1).getTime() - new Date(date2).getTime(),
		valueGetter: (params) => formatDateAndTime(params),
	},
	{
		field: "previousValue",
		headerName: "Previous Value (Employee Band)",
		width: 450,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value?.code + 
			(params.value?.employeeBand ? `(${params.value?.employeeBand?.name})` : ""),
	},
	{
		field: "currentValue",
		headerName: "Current Value (Employee Band)",
		width: 450,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value?.code + 
			(params.value?.employeeBand ? `(${params.value?.employeeBand?.name})` : ""),
	},
	{
		field: "actor",
		headerName: "Actor",
		width: 280,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value?.name || "User",
	},
];

export const EmployeeAllowanceHistoryColumns: GridColDef[] = [
	{
		field: "doneAt",
		headerName: "Recorded At",
		width: 300,
		editable: true,
		headerClassName: "super-app-theme--header",
		sortComparator: (date1, date2) => new Date(date1).getTime() - new Date(date2).getTime(),
		valueGetter: (params) => formatDateAndTime(params),
	},
	{
		field: "previousValue",
		headerName: "Previous Value",
		width: 360,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value?.name,
	},
	{
		field: "currentValue",
		headerName: "Current Value",
		width: 360,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value?.name,
	},
	{
		field: "action",
		headerName: "Action",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
		renderCell(params) {
			return (
				<div 
					className="rounded-lg px-4 py-2 min-w-[80px] text-center relative font-semibold overflow-hidden"
					style={{ color: getAllowanceActionColor(params.value) }}
				>
					<div 
						className="absolute inset-0" 
						style={{ 
							background: getAllowanceActionColor(params.value), 
							opacity: "15%"
						}}
					/>
					<p>{enumToStringConverter(params.value)}</p>
				</div>
			);
		},
	},
	{
		field: "actor",
		headerName: "Actor",
		width: 280,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value?.name || "User",
	},
];

export const getAllowanceActionColor = (status: DataAction) => {
	switch(status) {
		case "ADDITION":
			return "#4CAF50"
		case "REMOVAL":
			return "#F44336"
		case "UPDATE": 
			return "#2196F3"
		default: 
			return "#BF21F3"
	}
};

export const EmployeeQualificationHistoryColumns: GridColDef[] = [
	{
		field: "doneAt",
		headerName: "Recorded At",
		width: 300,
		editable: true,
		headerClassName: "super-app-theme--header",
		sortComparator: (date1, date2) => new Date(date1).getTime() - new Date(date2).getTime(),
		valueGetter: (params) => formatDateAndTime(params),
	},
	{
		field: "previousValue",
		headerName: "Previous Value",
		width: 450,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value?.qualification,
	},
	{
		field: "currentValue",
		headerName: "Current Value",
		width: 450,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value?.qualification,
	},
	{
		field: "actor",
		headerName: "Actor",
		width: 280,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value?.name || "User",
	},
];

export const EmployeeDisengagementHistoryColumns: GridColDef[] = [
	{
		field: "recordedAt",
		headerName: "Recorded At",
		width: 300,
		editable: true,
		headerClassName: "super-app-theme--header",
		sortComparator: (date1, date2) => new Date(date1).getTime() - new Date(date2).getTime(),
		valueGetter: (params) => formatDateAndTime(params),
	},
	{
		field: "reason",
		headerName: "Reason",
		width: 450,
		editable: true,
		headerClassName: "super-app-theme--header",
	},
	{
		field: "jobTitle",
		headerName: "Job Title",
		width: 300,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value?.name,
	},
	{
		field: "contractType",
		headerName: "Contract Type",
		width: 300,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value?.name,
	},
	{
		field: "actor",
		headerName: "Actor",
		width: 280,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value?.name || "User",
	},
];