import React from "react";
import { useSearchParams } from "react-router-dom";

interface ICustomTab {
	view: string;
	setView: (view: string) => void;
	searchParam?: string;
	children?: React.ReactNode;
	tabColumns: any[];
	popup?: boolean;
}

const CustomTab = ({
	view,
	setView,
	searchParam,
	children,
	tabColumns,
	popup,
}: ICustomTab) => {
	const [_, setSearchParams] = useSearchParams();

	const changeView = (item: any) => {
		setView(item.view);
		if (!popup) {
			setSearchParams({ [searchParam ? searchParam : "view"]: item.view });
		}
	};

	return (
		<>
			<div className="flex flex-col">
				<div className="flex space-x-6 border-b overflow-auto children-scroll-bar whitespace-nowrap w-full mobile-full-width max-lg:pl-4">
					{tabColumns.map((item: any, key) => (
						<button
							key={key}
							onClick={() => changeView(item)}
							className={`flex pb-3 max-lg:text-sm ${
								view === item.view
									? "border-b-2 border-primary-mango text-charcoal"
									: "text-gray-400"
							}`}
						>
							{item.name}
						</button>
					))}
				</div>
			</div>
			{children}
		</>
	);
};

export default CustomTab;
