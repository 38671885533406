import { useContext, useState, useEffect, useRef } from "react";
import Header from "../header";
import {
	AiOutlinePlus,
	AiOutlineGift,
	AiOutlineFieldTime,
} from "react-icons/ai";
import { Avatar, Typography } from "@mui/material";
import { BsPeople, BsFolder2Open } from "react-icons/bs";
import { TbReportMoney } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { IoChatbubbleEllipsesOutline } from "react-icons/io5";
import { MdOutlineSavings } from "react-icons/md";
import { RxDashboard } from "react-icons/rx";
import { IoSettingsOutline } from "react-icons/io5";
import { PATHS } from "../../routes/routes.paths";
import { toast } from "react-toastify";
import SkewLoader from "react-spinners/SkewLoader";
import useUserStore from "../../state-management/useUserStore";
import { LoadingCompanies } from "../../routes";
import useCompanyStore from "../../state-management/useCompanyStore";
import { useQueryClient } from "react-query";
import { LuBookMarked } from "react-icons/lu";
import { MdOutlinePayments } from "react-icons/md";
import { RiDiscountPercentLine } from "react-icons/ri";
import UpdateAccountConfigModal from "../modals/UpdateAccountConfigModal";
import SidebarItem from "./resources/SidebarItem";
import { CustomizedTooltip } from "../../helpers/appHelpers";
import { useCheckUserPermissions } from "../../helpers/useCheckUserPermissions";

const DEFAULT_COMPANY_IMAGE = "/assets/logos/zeplin.png";

interface IDashboardProps {
	children?: React.ReactNode;
}

const bgColor = "linear-gradient(0deg, rgba(204,247,247,1) 0%, rgba(255,255,255,0.665703781512605) 70%)";

const PayrollCompanyLayout = ({ children }: IDashboardProps) => {
	const pathname = window.location.pathname;
	const navigate = useNavigate();
	const { currentUser } = useUserStore();
	const userOrganization = currentUser.user.organization;
	const checkUserPermissions = useCheckUserPermissions();
	const queryClient = useQueryClient();
	const { 
		currentCompany, 
		savedCompanies, 
		setCurrentCompany,
	} = useCompanyStore();
	const anchorRef = useRef<any>(null);
	const isLoadingCompanies = useContext(LoadingCompanies);
	const [appKey, setAppKey] = useState(Math.random() + 2);
	const [showSidebar, setShowSidebar] = useState(
		sessionStorage.getItem("showSidebar") ? false : true
	);
	const [openUpgradeModal, setOpenUpgradeModal] = useState(false);
	const [upgradeModalContent, setUpgradeModalContent] = useState("");
	const [onClickActionButton, setOnClickActionButton] = useState<(() => void) | null>(null);
	const highestSubscriptionTierId = sessionStorage.getItem("highestSubscriptionTierId");
	
	const toggleSidebar = () => {
		setShowSidebar(prev => {
			if (prev) {
				sessionStorage.setItem("showSidebar", "false");
			} else {
				sessionStorage.removeItem("showSidebar");
			}
			return !prev
		})
	};

	// Rerender app when company changes
	useEffect(() => {
		setAppKey(Math.random() + 2);
	}, [currentCompany]); 

	const handlePageNavigation = (pathname: string) => {
		if (currentCompany && savedCompanies.length) {
			navigate(pathname);
		} else {
			toast.info("Please select a company to proceed");
		}
	};

	const handleCreateCompanyNavigation = () => {
		if (!userOrganization?.config) {
			toast.info("Organization configurations not found! Kindly sign out and sign back in to fix this.");
			return
		}
		
		const maxCompanies = userOrganization?.config.maxCompanies as number;

		if ((maxCompanies < 0) || maxCompanies > savedCompanies.length) {
			navigate(PATHS.COMPANY_ACCOUNT_SETUP);
			return
		};

		if (userOrganization?.billingType.name.toLowerCase() === "contract") {
			setOpenUpgradeModal(true);
			// setOnClickActionButton(() => navigate(-1));
			setUpgradeModalContent("Maximum company limit reached! Kindly contact the Akatua team to increase limit.");
			return
		}

		if (highestSubscriptionTierId === userOrganization?.subsciptionTier.id) {
			setOpenUpgradeModal(true);
			// setOnClickActionButton(() => navigate(-1));
			setUpgradeModalContent(`Maximum company limit reached! Kindly contact the Akatua team to increase 
				limit as you are currently on the highest subscription package.`
			);
			return
		}

		setOpenUpgradeModal(true);
		setOnClickActionButton(() => navigate(PATHS.CREATE_PAYROLL_COMPANY + "?upgradeRequired=true"));
		setUpgradeModalContent("Maximum company limit reached! Upgrade to higher subscription package to continue.");
	};

	return (
		<>
			{(isLoadingCompanies && !currentCompany) ? (
				<div className="flex items-center justify-center w-full h-[100svh]">
					<SkewLoader color="#F58B00" />
				</div>
			) : (
				<div className={"flex h-[100svh] w-full overflow-hidden"}>
					{/* Both Sidebars */}
					<>
						{/* Side bar one */}
						<section className="h-full py-4 px-3.5 flex flex-col gap-3 items-center bg-white border-r overflow-y-auto">
							{savedCompanies.map((company) => (
								<CustomizedTooltip key={company.id} title={company.name} placement="right" arrow>
									<Typography> 
										<div
											key={company.id}
											className={`p-3 bg-white rounded-lg border border-gray-300 cursor-pointer ${
												company.id === currentCompany?.id
													? "border-primary-blue border-2 shadow-md"
													: "hover:shadow-sm"
											}`}
											onClick={() => {
												if (company.id !== currentCompany?.id) {
													queryClient.clear();
													setCurrentCompany(company);
												}
											}}
										>
											<img
												src={company.logoUrl || DEFAULT_COMPANY_IMAGE}
												alt={company.name}
												className="h-[28px] w-[28px] object-cover object-center"
											/>
										</div>
									</Typography>
								</CustomizedTooltip>
							))}
							<button
								ref={anchorRef}
								aria-describedby="stay-open-popper"
								onClick={handleCreateCompanyNavigation}
								className={`flex items-center justify-center w-[52px] h-[52px] border border-gray-300 rounded-lg text-primary-blue hover:bg-primary-blue
									hover:text-white ${pathname === PATHS.CREATE_PAYROLL_COMPANY && "bg-primary-blue text-white hover:opacity-90"}
								`}
							>
								<AiOutlinePlus />
							</button>
						</section>
						{/* Side bar two */}
						<section className={`h-full ${showSidebar ? "w-[300px] px-6" : "w-[80px] pr-4 pl-5"} border-r py-6 
							overflow-y-auto overflow-x-hidden sidebar-two-scroll flex flex-col justify-between`
						}>
							<div>
								<div className="border-b pb-3 mb-5">
									<div className={`flex items-center mb-4 ${showSidebar && "space-x-4"}`}>
										<div className="border border-gray-300 h-10 w-10 flex items-center justify-center rounded-lg">
											<img
												src={currentCompany?.logoUrl || DEFAULT_COMPANY_IMAGE}
												alt={currentCompany?.name}
												className="h-full w-full rounded-lg"
											/>
										</div>
										{showSidebar && (
											<h1 className="text-2sm font-bold max-w-[195px] truncate">
												{currentCompany?.name}
											</h1>
										)}
									</div>
									<div className="text-white">
										<div
											className={`w-full rounded-lg flex py-2 items-center text-white mb-3 ${
												showSidebar && "bg-primary-blue justify-start px-3 space-x-3"
											}`}
										>
											<Avatar
												alt={currentUser?.user?.firstName}
												src={currentUser?.user?.imageUrl}
												sx={{ width: 40, height: 40 }}
											/>
											{showSidebar && (
												<div className="min-w-0">
													<h3 className="text-[11px] lg:text-xs font-bold">
														{currentUser?.user?.firstName +
															" " +
															currentUser?.user?.lastName}
													</h3>
													<p className="text-[8px] lg:text-[10px] mt-[2px] overflow-hidden truncate font-normal pr-3">
														{currentUser?.user?.emailAddress}
													</p>
												</div>
											)}
										</div>
										<SidebarItem 
											title="Overview"
											path={PATHS.OVERVIEW}
											Icon={RxDashboard}
											showSidebar={showSidebar}
										/>
									</div>
								</div>
								{showSidebar && (
									<h2 className="font-bold text-2sm px-4 mb-5">Payroll Management</h2>
								)}
								<div className="flex flex-col space-y-4 border-b pb-5">
									<SidebarItem 
										title="Employees"
										path={PATHS.EMPLOYEES}
										Icon={BsPeople}
										showSidebar={showSidebar}
										handleNavigation={handlePageNavigation}
									/>
									{checkUserPermissions(["salary:read", "salary:write"]) && (<>
										<SidebarItem 
											title="Payroll"
											path={PATHS.PAYROLL}
											Icon={TbReportMoney}
											showSidebar={showSidebar}
											handleNavigation={handlePageNavigation}
										/>
										<SidebarItem 
											title="Pay Advances"
											path={PATHS.PAY_ADVANCES}
											Icon={MdOutlinePayments}
											showSidebar={showSidebar}
											handleNavigation={handlePageNavigation}
										/>
									</>)}
									<SidebarItem 
										title="Benefits & Allowances"
										path={PATHS.BENEFIT_AND_ALLOWANCE}
										Icon={AiOutlineGift}
										showSidebar={showSidebar}
										handleNavigation={handlePageNavigation}
									/>
									<SidebarItem 
										title="Savings & Back Pay"
										path={PATHS.SAVINGS_AND_BACKPAY}
										Icon={MdOutlineSavings}
										showSidebar={showSidebar}
										handleNavigation={handlePageNavigation}
									/>
									<SidebarItem 
										title="Overtime & Bonuses"
										path={PATHS.OVERTIME_AND_BONUSES}
										Icon={AiOutlineFieldTime}
										showSidebar={showSidebar}
										handleNavigation={handlePageNavigation}
									/>
									<SidebarItem 
										title="Deductions & Loans"
										path={PATHS.DEDUCTIONS_AND_LOANS}
										Icon={RiDiscountPercentLine}
										showSidebar={showSidebar}
										handleNavigation={handlePageNavigation}
									/>
									<SidebarItem 
										title="Work Time"
										path={PATHS.WORK_TIME}
										Icon={LuBookMarked}
										showSidebar={showSidebar}
										handleNavigation={handlePageNavigation}
									/>
									<SidebarItem 
										title="Reports"
										path={PATHS.REPORTS}
										Icon={BsFolder2Open}
										showSidebar={showSidebar}
										handleNavigation={handlePageNavigation}
									/>
								</div>
							</div>
							<div className="flex flex-col space-y-4 py-5">
								<SidebarItem 
									title="Company Settings"
									path={PATHS.COMPANY_SETTINGS}
									Icon={IoSettingsOutline}
									showSidebar={showSidebar}
									handleNavigation={handlePageNavigation}
								/>
								<SidebarItem 
									title="Support"
									path={PATHS.SUPPORT}
									Icon={IoChatbubbleEllipsesOutline}
									showSidebar={showSidebar}
									handleNavigation={handlePageNavigation}
								/>
							</div>
						</section>
					</>
					{/* header + children */}
					<section 
						style={{
							width: showSidebar ? "calc(100% - 380px)": "calc(100% - 160px)",
						}}
						className="z-[1]"
					>
						<div className={`sticky top-0 z-50 w-full`}>
							<Header 
								companies={savedCompanies}
								onMenuClick={toggleSidebar} 
							/>
						</div>
						<div
							key={appKey}
							style={{ 
								background: bgColor,
								height: "calc(100% - 70px)",
							}}
							className="px-10 py-8 overflow-y-auto w-full"
						>
							<div className="w-full h-full flex flex-col max-w-[2000px] mx-auto">
								{children}
							</div>
						</div>
					</section>
					<UpdateAccountConfigModal 
						open={openUpgradeModal}
						content={upgradeModalContent}
						handleClose={() => setOpenUpgradeModal(false)}
						onClickActionButton={onClickActionButton}
					/>
				</div>
			)}
		</>
	);
};

export default PayrollCompanyLayout;
