import { AiOutlinePlus } from "react-icons/ai";
import EditJobTitle from "./EditJobTitle";
import NewJobTitle from "./NewJobTitle";
import SkewLoader from "react-spinners/SkewLoader";
import { useState } from "react";
import { JobTitleDto } from "../../../../../models/job-titles.dto";
import { useGetJobTitle } from "../../../../../services/job-title.service";
import { CompanyLevelOrderBy } from "../../../../../models/company-level.dto";
import { DisabilitiesOrderBy } from "../../../../../models/disabilities.dto";
import { EmployeeBandOrderBy } from "../../../../../models/employee-band.dto";
import { useGetCompanyLevel } from "../../../../../services/company-level.service";
import { useGetDisabilities } from "../../../../../services/disabilities.service";
import { useGetEmployeeBand } from "../../../../../services/employee-band.service";
import useUserStore from "../../../../../state-management/useUserStore";
import useCompanyStore from "../../../../../state-management/useCompanyStore";
import { useManageAccordions } from "../../../../../helpers/useManageAccordions";
import CustomPagination from "../../../../../components/table/CustomPagination";
import { APP_CONFIG } from "../../../../../helpers/appHelpers";

const JobTitles = () => {
	const { currentUser } = useUserStore();
	const { currentCompany } = useCompanyStore();
	const [showNewJobTitleForm, setShowNewJobTitleForm] = useState(false);
	
	const {
		accordionsData: jobTitles,
		isLoading,
		pageCount,
    } = useManageAccordions<JobTitleDto>({
		fetchHook: useGetJobTitle,
		options: { companyId: currentCompany?.id }
	});

	const { 
		data: companyLevels, 
		isLoading: loadingCompanyLevels, 
	} = useGetCompanyLevel({
		companyId: currentCompany?.id,
        limit: APP_CONFIG.PAGE_LIMIT,
		orderBy: CompanyLevelOrderBy.LEVEL_NAME_ASC
    });
	const { 
		data: employeeBands,
		isLoading: loadingEmployeeBands, 
	} = useGetEmployeeBand({
		companyId: currentCompany?.id,
        limit: APP_CONFIG.PAGE_LIMIT,
		orderBy: EmployeeBandOrderBy.NAME_ASC
    });
	const { 
		data: disabilities,
		isLoading: loadingDisabilities, 
	} = useGetDisabilities({
		organizationId: currentUser.user.organization?.id,
        limit: APP_CONFIG.PAGE_LIMIT,
		orderBy: DisabilitiesOrderBy.NAME_ASC
    });
	
	return (
		<div>
			<div className="border-b-[1.5px] pb-3 mb-4 flex w-full justify-between text-sm">
				<h2 className="font-semibold">Available Job Titles</h2>
				{!showNewJobTitleForm && (
					<button
						onClick={() => setShowNewJobTitleForm(true)}
						className="border-none flex items-center text-primary-blue"
					>
						<AiOutlinePlus className="mr-2 text-lg" />
						Add New Job Title
					</button>
				)}
			</div>
			
			{showNewJobTitleForm && (
				<NewJobTitle 
					companyLevels={companyLevels?.data || []}
					employeeBands={employeeBands?.data || []}
					disabilities={disabilities?.data || []}
					setShowNewJobTitleForm={setShowNewJobTitleForm} 
				/>
			)}
			
			{(isLoading && !jobTitles.length) && (
				<div className="text-center mt-10 py-10">
					<SkewLoader color="#F58B00" />
				</div>
			)}

			{(!loadingCompanyLevels && !loadingEmployeeBands && !loadingDisabilities) && (
				<>
				{jobTitles.map((jobTitle) => (
					<EditJobTitle 
						key={jobTitle.id}
						jobTitle={jobTitle} 
						companyLevels={companyLevels?.data || []}
						employeeBands={employeeBands?.data || []}
						disabilities={disabilities?.data || []}
					/>
				))}
				</>
			)}

			{(!isLoading && !jobTitles.length) && (
				<div>
					<h3 className="text-center text-sm font-semibold mt-10">
						Sorry no job titles added yet. Click 'Add New Job Title' to begin.
					</h3>
				</div>
			)}

			<div className="w-full flex justify-end mt-8">
				<CustomPagination pageCount={pageCount} />
			</div>
		</div>
	);
};

export default JobTitles;
