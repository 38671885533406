import { useState } from "react";
import { APP_CONFIG, CustomizedTooltip } from "../../../../helpers/appHelpers";
import useEditFormStore from "../../../../state-management/useEditFormStore";
import CustomStyledTable from "../../../../components/table/CustomStyledTable";
import useCompanyStore from "../../../../state-management/useCompanyStore";
import TableFilter from "../../../../components/table/filter/TableFilter";
import { EmployeeOrderBy } from "../../../../models/employee.dto";
import { useGetEmployee } from "../../../../services/employee.service";
import { useGetPayPeriod } from "../../../../services/pay-period.service";
import OrangeButton from "../../../../components/buttons/OrangeButton";
import { EmployeeBandOrderBy } from "../../../../models/employee-band.dto";
import { useGetEmployeeBand } from "../../../../services/employee-band.service";
import { CompanyPayrollRunColumns, CompanyPayrollRunColumnVisibilityModel } from "../data";
import { useGetCompanyPayrollRuns } from "../../../../services/payrol.service";
import { useManageTableWithSearchParams } from "../../../../helpers/useManageTable";
import { PayrollRuns, PayrollRunStatus } from "../../../../models/payroll.dto";
import { Popover, Typography } from "@mui/material";
import CreatePayrollModalForm from "../modals/CreatePayrollModalForm";
import { useGetAllowance } from "../../../../services/allowance.service";
import { AllowanceOrderBy } from "../../../../models/allowance.dto";
import { useGetBenefit } from "../../../../services/benefit.service";
import { BenefitOrderBy } from "../../../../models/benefit.dto";
import { useGetBonus } from "../../../../services/bonus.service";
import { BonusOrderBy } from "../../../../models/bonus.dto";
import ManageTableColumns from "../../../../components/table/ManageTableColumns";
import { useColumnVisibilityModel } from "../../../../helpers/useColumnVisibilityModel";
import { VscRunAll } from "react-icons/vsc";
import { MdOutlineReplay } from "react-icons/md";
import { MdDoNotDisturb } from "react-icons/md";
import { FiUpload } from "react-icons/fi";
import PayrollActionModal from "../modals/PayrollActionModal";

type ActionMode = "RUN_PAYROLL" | "APPROVE_PAYROLL";

const CompanyPayrollRuns = () => {
	const { currentCompany } = useCompanyStore();
	const { setFormData } = useEditFormStore();
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [openCreatePayrollModal, setOpenCreatePayrollModal] = useState(false);
	const [openPayrollActionModal, setOpenPayrollActionModal] = useState(false);
	const [payrollActionMode, setPayrollActionMode] = useState<ActionMode>("RUN_PAYROLL");
	const [isEditForm, setIsEditForm] = useState(false);
	const {
        tableKey,
        columnVisibilityModel,
        setColumnVisibilityModel,
    } = useColumnVisibilityModel(CompanyPayrollRunColumnVisibilityModel);
	
	const {
		tableAPIRef,
		tableData,
		isLoading,
		pageCount,
		setTableData,
    } = useManageTableWithSearchParams<PayrollRuns>({
		fetchHook: useGetCompanyPayrollRuns,
		options: {
			company_id: currentCompany?.id,
			orderBy: undefined,
		}
	});
	
	// Fetched data for select fields requiring data from database
	const { data: payPeriods } = useGetPayPeriod({ 
		companyId: currentCompany?.id,
		limit: APP_CONFIG.PAGE_LIMIT,
		orderBy: "createdAt:desc" as any,
	});
	const { data: employees } = useGetEmployee({ 
		companyId: currentCompany?.id,
		limit: APP_CONFIG.PAGE_LIMIT,
		status: "ACTIVE",
		orderBy: EmployeeOrderBy.FIRST_NAME_ASC,
	});
	const { data: employeeBands } = useGetEmployeeBand({ 
		companyId: currentCompany?.id,
		limit: APP_CONFIG.PAGE_LIMIT,
		orderBy: EmployeeBandOrderBy.NAME_ASC,
	});
	const { data: allowances } = useGetAllowance({ 
		companyId: currentCompany?.id,
		limit: APP_CONFIG.PAGE_LIMIT,
		orderBy: AllowanceOrderBy.NAME_ASC,
	});
	const { data: benefits } = useGetBenefit({ 
		companyId: currentCompany?.id,
		limit: APP_CONFIG.PAGE_LIMIT,
		orderBy: BenefitOrderBy.NAME_ASC,
	});
	const { data: bonuses } = useGetBonus({ 
		companyId: currentCompany?.id,
		limit: APP_CONFIG.PAGE_LIMIT,
		orderBy: BonusOrderBy.NAME_ASC,
	});

	const handleOpenModal = () => {
		setIsEditForm(false);
		setOpenCreatePayrollModal(true);
	};

	const additionalColumns = [
		{
			field: "actions",
			headerName: "Actions",
			width: 160,
			headerClassName: "super-app-theme--header",
            renderCell: (params) => {
                const status = params.row.status as PayrollRunStatus;
                const isApproved = params.row.isApproved;
                return (
					<div className={`flex items-center text-xl ${!isApproved && "text-primary-blue"}`}>
						<CustomizedTooltip 
							title={
								isApproved
									? "Cannot re-run payroll because the payslips have been published"
									: status === PayrollRunStatus.SUBMITTED 
										? "Run Payroll" 
										: status === PayrollRunStatus.PROCESSING
											? "Recent run still processing"
											: "Re-run Payroll"
							}
							placement="top"
							arrow
						>
							<Typography>
								<button 
									className="py-2 px-4 rounded-full flex items-center gap-3 hover:bg-gray-200"
									onClick={() => {
										setFormData(params.row);
										setPayrollActionMode("RUN_PAYROLL");
										setOpenPayrollActionModal(true);
										setAnchorEl(null);
									}}
									disabled={isApproved || (status === PayrollRunStatus.PROCESSING)}
								>
									{status === PayrollRunStatus.SUBMITTED ? (
										<VscRunAll />
									) : status === PayrollRunStatus.PROCESSING ? (
										<MdDoNotDisturb />
									) : (
										<MdOutlineReplay />
									)}
								</button>
							</Typography>
						</CustomizedTooltip>

						{status === PayrollRunStatus.COMPLETED && (
							<CustomizedTooltip 
								title={isApproved ? "Payslips already published" : "Publish Payslips" }
								placement="top" 
								arrow 
							>
								<Typography>
									<button 
										className="py-2 px-4 rounded-full flex items-center gap-3 hover:bg-gray-200"
										onClick={() => {
											setPayrollActionMode("APPROVE_PAYROLL");
											setOpenPayrollActionModal(true);
											setAnchorEl(null);
										}}
										disabled={isApproved}
									>
										<FiUpload />
									</button>
								</Typography>
							</CustomizedTooltip>
						)}
					</div>
                )
			},
		},
	];

	const columnsWithAdditions = CompanyPayrollRunColumns.map(
		(obj) => additionalColumns.find((o) => o.field === obj.field) || obj
	);

	return (
		<>
			<div className="flex justify-between items-center mt-4 mb-8">
				<div className="flex items-center gap-3">
					<TableFilter
						fields={[]}
					/>
					<ManageTableColumns 
						columns={CompanyPayrollRunColumns}
						defaultVisibilityModel={CompanyPayrollRunColumnVisibilityModel}
						visibilityModel={columnVisibilityModel}
						setVisibilityModel={setColumnVisibilityModel}
					/>
				</div>
                <OrangeButton
                    title="Create Payroll Run"
                    className="bg-primary-mango text-white h-14 px-8 rounded-lg text-sm"
                    onClick={handleOpenModal}
                />
			</div>
			<CustomStyledTable
				key={tableKey}
				rows={tableData}
				columns={columnsWithAdditions}
				apiRef={tableAPIRef}
				columnVisibilityModel={columnVisibilityModel}
				loading={isLoading}
				pageCount={pageCount}
				setAnchorEl={setAnchorEl}
				conditionalRowAction={(params: any) => {
					return params.row.status === PayrollRunStatus.COMPLETED 
						&& params.field !== "actions" 
						&& !params.row.isApproved;
				}}
			/>
			<Popover
				id={anchorEl ? "simple-popover" : undefined}
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={() => setAnchorEl(null)}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				PaperProps={{
					style: {
						width: "auto",
						borderRadius: 8,
						display: "flex",
						justifyContent: "center",
					},
				}}
			>
				<div className="flex flex-col justify-center p-3 text-secondary-darkgray">
					<div
						onClick={() => {
							setPayrollActionMode("APPROVE_PAYROLL");
							setOpenPayrollActionModal(true);
							setAnchorEl(null);
						}}
						className="table-action-popover"
					>
						<FiUpload />
						<p className="text-2sm">Publish Payslips</p>
					</div>
				</div>
			</Popover>
			{openPayrollActionModal && (
				<PayrollActionModal
					openModal={openPayrollActionModal}
					setOpenModal={setOpenPayrollActionModal}
					actionMode={payrollActionMode}
				/>
			)}
			{openCreatePayrollModal && (
				<CreatePayrollModalForm
					open={openCreatePayrollModal}
					setOpen={setOpenCreatePayrollModal}
					title={
						isEditForm ? "Update Payroll Run" : "Create Payroll Run"
					}
					isEditForm={isEditForm}
					tableAPIRef={tableAPIRef}
					payPeriods={payPeriods?.data || []}
					employees={employees?.data || []}
					employeeBands={employeeBands?.data || []}
					allowances={allowances?.data || []}
					benefits={benefits?.data || []}
					bonuses={bonuses?.data || []}
				/>
			)}
		</>
	);
};

export default CompanyPayrollRuns;
