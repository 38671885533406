const COMPANY_SETTINGS = "/dashboard/company-settings";
const SYSTEM_CONFIGURATIONS = "/admin/system-configurations";
const PROFILE_DETAILS = "/selfservice/profile-details";

export const PATHS = {
	// Authentication
	SIGN_UP: "/signup",
	SIGN_IN: "/",
	FORGOT_PASSWORD: "/forgot-password",
	RESET_PASSWORD: "/reset-password",
	CREATE_PASSWORD: "/create-password",
	SET_PASSWORD: "/set-password",
	SIGN_UP_CONFIRMATION: "/signup-confirmation",

	// Payroll Company Portal
	COMPANY_ACCOUNT_SETUP: "/dashboard/company-account-setup",
	CREATE_PAYROLL_COMPANY: "/dashboard/create-payroll-company",
	SUBSCRIPTION_PAYMENT: "/dashboard/subscription-payment",
	OVERVIEW: "/dashboard/overview",
	EMPLOYEES: "/dashboard/employees",
	PAYROLL: "/dashboard/payroll",
	PAY_ADVANCES: "/dashboard/pay-advances",
	BENEFIT_AND_ALLOWANCE: "/dashboard/benefits_and_allowance",
	OVERTIME_AND_BONUSES: "/dashboard/overtime_and_bonuses",
	SAVINGS_AND_BACKPAY: "/dashboard/savings_and_backpay",
	DEDUCTIONS_AND_LOANS: "/dashboard/deductions_and_loans",
	WORK_TIME: "/dashboard/work-time",
	REPORTS: "/dashboard/reports",
	COMPANY_SETTINGS: COMPANY_SETTINGS,
	SUB_COMPANY_SETTINGS: {
		COMPANY_DETAILS: COMPANY_SETTINGS + "?view=companyDetails",
		COMPANY_LEVELS: COMPANY_SETTINGS + "?view=companyLevels",
		COMPANY_CURRENCY: COMPANY_SETTINGS + "?view=companyCurrency",
		COST_CENTERS: COMPANY_SETTINGS + "?view=costCenters",
		REGIONS: COMPANY_SETTINGS + "?view=regions",
		DIVISIONS: COMPANY_SETTINGS + "?view=divisions",
		STATIONS: COMPANY_SETTINGS + "?view=stations",
		TRIBES: COMPANY_SETTINGS + "?view=tribes",
		DEPARTMENTS: COMPANY_SETTINGS + "?view=departments",
		GRADE_LEVELS: COMPANY_SETTINGS + "?view=gradeLevels",
		BANDS: COMPANY_SETTINGS + "?view=bands",
		WORK_DAYS_IN_A_PERIOD: COMPANY_SETTINGS + "?view=workDaysInAPeriod",
		CUSTOM_TAX_RELIEF: COMPANY_SETTINGS + "?view=customTaxRelief",
		PAY_PERIODS: COMPANY_SETTINGS + "?view=payPeriods",
		JOB_TITLES: COMPANY_SETTINGS + "?view=jobTitles",
		ERP_INTEGRATION_MAPPINGS: COMPANY_SETTINGS + "?view=erpIntegrationMappings",
		DISCIPLINARY_TYPES: COMPANY_SETTINGS + "?view=disciplinaryTypes",
		GRIEVANCE_TYPES: COMPANY_SETTINGS + "?view=grievanceTypes",
		ASSET_TYPES: COMPANY_SETTINGS + "?view=assetTypes",
		UNIONS: COMPANY_SETTINGS + "?view=unions",
	},
	SUPPORT: "/dashboard/support",

	// Admin Dashboard Portal
	ADMIN_ACCOUNTS_MANAGEMENT: "/admin/accounts-management",
	ADMIN_SYSTEM_CONFIGURATIONS: SYSTEM_CONFIGURATIONS,
	ADMIN_SUB_SYSTEM_CONFIGURATIONS: {
		SUBSCRIPTION_PACKAGES: SYSTEM_CONFIGURATIONS + "?view=subscriptionPackages",
		PROMOTIONS: SYSTEM_CONFIGURATIONS + "?view=promotions",
		COUNTRIES: SYSTEM_CONFIGURATIONS + "?view=countries",
		CURRENCIES: SYSTEM_CONFIGURATIONS + "?view=currencies",
		NATIONALITIES: SYSTEM_CONFIGURATIONS + "?view=nationalities",
		TAX_CODES: SYSTEM_CONFIGURATIONS + "?view=taxCodes",
		TAX_RULES: SYSTEM_CONFIGURATIONS + "?view=taxRules",
		TAX_RELIEF_TYPES: SYSTEM_CONFIGURATIONS + "?view=taxReliefTypes",
		LEAVE_TYPES: SYSTEM_CONFIGURATIONS + "?view=leaveTypes",
		INDUSTRIES: SYSTEM_CONFIGURATIONS + "?view=industries",
		PENSIONS: SYSTEM_CONFIGURATIONS + "?view=pensions",
		BENEFIT_TYPES: SYSTEM_CONFIGURATIONS + "?view=benefitTypes",
		PAYMENT_INSTITUTIONS: SYSTEM_CONFIGURATIONS + "?view=paymentInstitutions",
		PAYMENT_INSTITUTION_BRANCHES: SYSTEM_CONFIGURATIONS + "?view=paymentInstitutionBranches",
		EXCHANGE_RATES: SYSTEM_CONFIGURATIONS + "?view=exchangeRates",
	},
	ADMIN_BILLING: "/admin/credit-notes",
	ADMIN_SUPPORT: "/admin/support",

	// Self Service Portal
	SELF_SERVICE_DASHBOARD: "/selfservice/dashboard",
	SELF_SERVICE_PROFILE_DETAILS: PROFILE_DETAILS,
	SELF_SERVICE_SUB_PROFILE_DETAILS: {
		PERSONAL_DETAILS: PROFILE_DETAILS + "?view=personalDetailsView",
		EMPLOYEE_RELATIVES: PROFILE_DETAILS + "?view=employeeRelativesView",
		PAYMENT_INFO: PROFILE_DETAILS + "?view=paymentInfoView",
		UNION_MEMBERSHIP: PROFILE_DETAILS + "?view=unionMembershipView",
		EMPLOYEE_DOCUMENTS: PROFILE_DETAILS + "?view=employmentDocumentsView",
	},
	SELF_SERVICE_PAYSLIPS: "/selfservice/payslips",
	SELF_SERVICE_WORK_TIME: "/selfservice/work-time",
	SELF_SERVICE_LEAVE_MANAGEMENT: "/selfservice/leave-management",
	SELF_SERVICE_REIMBURSEMENT: "/selfservice/reimbursements",
	SELF_SERVICE_SUPPORT: "/selfservice/support",

	//HR Portal
	HR_EMPLOYEE_MANAGEMENT: "/hr/employee-management",
	HR_PAYROLL_MANAGEMENT: "/hr/payroll-management",
	HR_PAY_ADVANCES: "/hr/pay-advances",
	HR_ORGANISATIONAL_TREE: "/hr/organisational-tree",
	HR_WORK_TIME: "/hr/work-time",
	HR_DISCIPLINARY_GRIEVANCE: "/hr/disciplinary-and-grievance",
	HR_LEAVE_MANAGEMENT: "/hr/leave-management",
	HR_REIMBURSEMENTS: "/hr/reimbursements",
	HR_BONUS_MANAGEMENT: "/hr/bonus-management",
	HR_ANNOUNCEMENTS: "/hr/announcements",
	HR_DOCUMENT_TYPES: "/hr/document-types",
	HR_REPORTS: "/hr/reports",
	HR_SUPPORT: "/hr/support-service",

	// Others
	FORBIDDEN: "/forbidden",
};