import APISearchInput from "../../../../components/search/APISearchInput";
import CustomStyledTable from "../../../../components/table/CustomStyledTable";
import TableFilter from "../../../../components/table/filter/TableFilter";
import { useManageTableWithSearchParams } from "../../../../helpers/useManageTable";
import { AuditLogOrderBy, AuditLogDto } from "../../../../models/audit-log.dto";
import { useGetAuditLogSearch, useGetAuditLog } from "../../../../services/audit-log.service";
import useCompanyStore from "../../../../state-management/useCompanyStore";
import { AuditLogColumns } from "../data";

const AuditLogs = () => {
	const { currentCompany } = useCompanyStore();
	const { mutate: searchAuditLogs } = useGetAuditLogSearch();
	const {
		tableAPIRef,
		fetchedTableData,
		tableData,
		isLoading,
		pageCount,
		setTableData,
    } = useManageTableWithSearchParams<AuditLogDto>({
		fetchHook: useGetAuditLog,
		options: { 
			companyId: currentCompany?.id,
			// organizationId: currentCompany!.organizationId,
			orderBy: AuditLogOrderBy.TIMESTAMP_DESC,
		}
	});

    return (
		<>
			<div className="flex justify-between items-center mt-4 mb-8">
				<div className="flex items-center gap-3">
					<APISearchInput 
						fallbackData={fetchedTableData}
						setTableData={setTableData}
						mutate={searchAuditLogs}
					/>
					<TableFilter
						fields={[
							{
								title: "",
								fieldName: "",
								fieldType: "SORT",
								options: [
									{
										title: "Timestamp",
										fieldName: "timestamp",
									},
								]
							},
							// {
							// 	title: "Time Unit",
							// 	fieldName: "timeUnit",
							// 	fieldType: "CHECKBOX_LIST",
							// 	options: { HOUR: "HOUR", DAY: "DAY" }
							// }
						]}
					/>
				</div>
			</div>
			<CustomStyledTable
				rows={tableData}
				columns={AuditLogColumns}
				apiRef={tableAPIRef}
				loading={isLoading}
				pageCount={pageCount}
			/>
		</>
    );
}
 
export default AuditLogs;