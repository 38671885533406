import {HttpClient} from "../requests";
import ENDPOINTS from "../endpoints";
import { 
    CreatePayrollDto, 
    CreatePayrollObject, 
    DownloadReportPaginator, 
    DownloadReportQueryDto, 
    MigrationDto, 
    PayPeriod, 
    PayrollRuns, 
    PayrollRunsPaginator, 
    PayrollRunStatusObject, 
    PayslipPaginator, 
    QueryCompanyPayslipsDto, 
    QueryEmployeePayslipsDto, 
    QueryParam, 
    QueryPayrollDto, 
    QueryReportDto, 
    SavingSchemeReportResponsePaginator, 
    SSFResponsePaginator 
} from "../../../models/payroll.dto";

export class Payroll {
    getEmployeePayslips = (employeeId: number, query?: QueryParam) => HttpClient
        .get<PayslipPaginator>(ENDPOINTS.PAYROLL.GET_EMPLOYEE_PAYSLIPS
            .replace('{employeeId}', String(employeeId)), query);
    getApprovedEmployeePayslips = (employeeId: number, query?: QueryParam) => HttpClient
        .get<PayslipPaginator>(ENDPOINTS.PAYROLL.GET_APPROVED_EMPLOYEE_PAYSLIPS
            .replace('{employeeId}', String(employeeId)), query);
    getCompanyPayslips = (companyId: number, payPeriodId: number, query?: QueryCompanyPayslipsDto) => HttpClient
        .get<PayslipPaginator>(ENDPOINTS.PAYROLL.GET_COMPANY_PAYSLIPS
            .replace('{companyId}', String(companyId))
            .replace('{payPeriodId}', String(payPeriodId)), query);
    getPayrollRunParams = (payrollId: number, query: QueryParam) => HttpClient
        .get<{ data: PayrollRuns }>(ENDPOINTS.PAYROLL.GET_PAYROLL_RUN_PARAMS
            .replace('{payrollId}', String(payrollId)), query);
    getCompanyPayrollRuns = (query?: QueryPayrollDto) => HttpClient
        .get<PayrollRunsPaginator>(ENDPOINTS.PAYROLL.GET_COMPANY_PAYROLL_RUNS, query);
    runPayrollParams = (payrollRunId: number, query: QueryParam) => HttpClient
        .get<{ data: string }>(ENDPOINTS.PAYROLL.RUN_PAYROLL
            .replace('{payrollRunId}', String(payrollRunId)), query);
    createPayrollRun = (payload: CreatePayrollDto, query: QueryParam) => HttpClient
        .post<{ data: CreatePayrollObject }>(ENDPOINTS.PAYROLL.CREATE_PAYROLL_RUN, payload, query);
    
    getPayrollExcell = (companyId: number, payPeriodId: number, query: QueryParam) => HttpClient
        .get<any>(ENDPOINTS.PAYROLL.GET_PAYROLL_EXCELL
            .replace('{companyId}', String(companyId))
            .replace('{payPeriodId}', String(payPeriodId)), query);
    getRecentPayrollRun = (companyId: number, query: QueryParam) => HttpClient
        .get<{ data: PayPeriod }>(ENDPOINTS.PAYROLL.GET_RECENT_PAYROLL_RUN.replace('{companyId}', String(companyId)), query);
    getEmployeePayslip = (query: QueryEmployeePayslipsDto) => HttpClient
        .get<{ data: string }>(ENDPOINTS.PAYROLL.GET_EMPLOYEE_PAYSLIP, query);
    getPayrollRunStatus = (payrollRunId: number, query: QueryParam) => HttpClient
        .get<{ data: PayrollRunStatusObject }>(ENDPOINTS.PAYROLL.GET_PAYROLL_RUN_STATUS
            .replace('{payrollRunId}', String(payrollRunId)), query);
    approvePayroll = (payPeriodId: number, query: QueryParam) => HttpClient
        .get<{ data: any }>(ENDPOINTS.PAYROLL.GET_APPROVED_PAYROLL
            .replace('{payPeriodId}', String(payPeriodId)), query);
    runMigration = () => HttpClient.get<MigrationDto>(ENDPOINTS.PAYROLL.RUN_MIGRATION);
    getSSFOneReport = (query: QueryReportDto) => HttpClient
        .get<SSFResponsePaginator>(ENDPOINTS.PAYROLL.GET_SSF_ONE_REPORT, query);
    getSSFTwoReport = (query: QueryReportDto) => HttpClient
        .get<SSFResponsePaginator>(ENDPOINTS.PAYROLL.GET_SSF_TWO_REPORT, query);
    getSavingSchemeReport = (query: QueryReportDto) => HttpClient
        .get<SavingSchemeReportResponsePaginator>(ENDPOINTS.PAYROLL.GET_SAVING_SCHEME_REPORT, query);
    downloadReport = (query: DownloadReportQueryDto) => HttpClient.get<DownloadReportPaginator>(ENDPOINTS.PAYROLL.DOWNLOAD_REPORT, query);
    }