import { Popover } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { IoCaretDown } from "react-icons/io5";
import OrangeButton from "../../../../components/buttons/OrangeButton";
import CustomStyledTable from "../../../../components/table/CustomStyledTable";
import { APP_CONFIG, formatDate } from "../../../../helpers/appHelpers";
import { useManageTableWithSearchParams } from "../../../../helpers/useManageTable";
import { PayPeriodDto } from "../../../../models/pay-period.dto";
import { ReportType, SavingSchemeReportResponseDto, SSFResponseDto } from "../../../../models/payroll.dto";
import { useGetPayPeriod } from "../../../../services/pay-period.service";
import useCompanyStore from "../../../../state-management/useCompanyStore";
import API from '../../../../services/utils/api';
import ENDPOINTS from "../../../../services/utils/endpoints";
import { useAsyncEffect, useLockFn } from "ahooks";
import { 
    useGetSSFOneReport, 
    useGetSSFTwoReport, 
    useGetSavingSchemeReport 
} from "../../../../services/payrol.service";
import { useSearchParams } from "react-router-dom";
import { SSFReportColumns, SavingSchemeColumns } from "../data";
import { toast } from "react-toastify";

type ReportTable = {
    title: string;
    tableData: any[];
    isLoadingData: boolean;
    tableColumns: GridColDef[];
    pageCount: number;
    type: ReportType;
}

const CompanyPayrollReports = ({ defaultPayPeriod }: { defaultPayPeriod: any }) => {
	const { currentCompany } = useCompanyStore();
	const [_, setSearchParams] = useSearchParams();
	const [payPeriodAnchorEl, setPayPeriodAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [reportTableAnchorEl, setReportTableAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [activePayPeriod, setActivePayPeriod] = useState<PayPeriodDto>();
	const [excelFile, setExcelFile] = useState<Blob | null>(null);
	const [fetchingExcelFile, setFetchingExcelFile] = useState(false);
	const [downloadingFile, setDownloadingFile] = useState(false);

	useEffect(() => {
		if (!defaultPayPeriod || activePayPeriod) return;
		setActivePayPeriod(defaultPayPeriod as any);
	}, [defaultPayPeriod])
	
	const {
		tableData: ssfOneReportData,
		isLoading: ssfOneReportLoading,
		pageCount: ssfOneReportPageCount,
	} = useManageTableWithSearchParams<SSFResponseDto>({
		fetchHook: useGetSSFOneReport,
		options: { 
            companyId: currentCompany?.id,
            payPeriodId: (activePayPeriod?.id as number) || defaultPayPeriod?.id,
            orderBy: undefined, 
        },
		reloadDeps: [activePayPeriod],
	});
	const {
		tableData: ssfTwoReportData,
		isLoading: ssfTwoReportLoading,
		pageCount: ssfTwoReportPageCount,
	} = useManageTableWithSearchParams<SSFResponseDto>({
		fetchHook: useGetSSFTwoReport,
		options: { 
            companyId: currentCompany?.id,
            payPeriodId: (activePayPeriod?.id as number) || defaultPayPeriod?.id,
            orderBy: undefined, 
        },
		reloadDeps: [activePayPeriod],
	});
	const {
		tableData: savingSchemeReportData,
		isLoading: savingSchemeReportLoading,
		pageCount: savingSchemeReportPageCount,
	} = useManageTableWithSearchParams<SavingSchemeReportResponseDto>({
		fetchHook: useGetSavingSchemeReport,
		options: { 
            companyId: currentCompany?.id,
            payPeriodId: (activePayPeriod?.id as number) || defaultPayPeriod?.id,
            orderBy: undefined, 
        },
		reloadDeps: [activePayPeriod],
	});
    
    const reportTables: ReportTable[] = [
        {
            title: "SSF Tier One",
            tableData: ssfOneReportData,
            isLoadingData: ssfOneReportLoading,
            tableColumns: SSFReportColumns,
            pageCount: ssfOneReportPageCount,
            type: ReportType.SSF_TIER_ONE,
        },
        {
            title: "SSF Tier Two",
            tableData: ssfTwoReportData,
            isLoadingData: ssfTwoReportLoading,
            tableColumns: SSFReportColumns,
            pageCount: ssfTwoReportPageCount,
            type: ReportType.SSF_TIER_TWO,
        },
        {
            title: "Saving Scheme",
            tableData: savingSchemeReportData,
            isLoadingData: savingSchemeReportLoading,
            tableColumns: SavingSchemeColumns,
            pageCount: savingSchemeReportPageCount,
            type: ReportType.SAVING_SCHEME,
        },
    ];
	
	const [activeReportTable, setActiveReportTable] = useState(reportTables[0]);
	const { 
        data: payPeriods, 
        isLoading: loadingPayPeriods 
    } = useGetPayPeriod({ 
		companyId: currentCompany?.id,
		limit: APP_CONFIG.PAGE_LIMIT,
		orderBy: "createdAt:desc" as any,
	});

	useAsyncEffect(useLockFn(async () => {
        if (!activePayPeriod) return;
        setFetchingExcelFile(true);

		try {
			const data = await API.PayrollAPI.downloadReport({
				companyId: currentCompany!.id, 
				payPeriodId: activePayPeriod!.id || defaultPayPeriod?.id,
                type: activeReportTable.type,
            });
            const blob = new Blob([data as any], { type: "application/vnd.ms-excel;charset=utf-8" });
            setExcelFile(blob);
		} catch (error) {
            setExcelFile(null);
            API.Handlers.onErrorHandler(error, null, null);
        } finally {
            setFetchingExcelFile(false);
        }
	}), [activePayPeriod, activeReportTable])

	const handleDownload = async () => {
        if (!excelFile) return;
        setDownloadingFile(true);

        try {
            const downloadLocation = ENDPOINTS.PAYROLL.DOWNLOAD_REPORT +
                `?companyId=${currentCompany!.id}` +
                `&payPeriodId=${activePayPeriod!.id || defaultPayPeriod?.id}` +
                `&type=${activeReportTable.type}`;

            const response = await fetch(downloadLocation, { method: 'GET' });

            if (!response.ok) {
                setDownloadingFile(false);
                toast.error("Download failed! Please try again.");
            }

            const contentDisposition = response.headers.get('Content-Disposition');
            const filename = contentDisposition
                ? contentDisposition.split('filename=')[1]
                : `${activePayPeriod!.code || defaultPayPeriod?.code}_${activeReportTable.title}.xlsx`;

            const blob = await response.blob();

            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = filename;
            link.click();
            window.URL.revokeObjectURL(link.href);
        } finally {
            setDownloadingFile(false);
        }
	};

    const handleReportTableChange = (reportTable: ReportTable) => {
        const currentParams = new URLSearchParams(window.location.search);
        currentParams.set("page", "1");
        setSearchParams(currentParams);
        setActiveReportTable(reportTable);
        setReportTableAnchorEl(null);
    };

	return (
		<>
			<div className="flex justify-between items-center mt-4 mb-8">
                <div className="flex items-center gap-3">
                    <>
                        <button
                            onClick={(e: any) => setPayPeriodAnchorEl(e.currentTarget)}
                            className="relative border border-gray-300 rounded-lg text-2xs py-3 px-3 flex items-center gap-3"
                        >
                            <div className="absolute top-0 -translate-y-1/2 left-2 px-2 bg-white text-[11px]">Pay Period</div>
                            {activePayPeriod?.id ? (
                                <span className="max-w-full truncate">
                                    {activePayPeriod.code + " "} 
                                    <span className="text-xs">
                                        ({formatDate(activePayPeriod.startDate)} - {formatDate(activePayPeriod.endDate)})
                                    </span>
                                </span>
                            ):(
                                <span className="pr-8">Select Option</span>
                            )}
                            <IoCaretDown />
                        </button>
                        <Popover
                            open={Boolean(payPeriodAnchorEl)}
                            anchorEl={payPeriodAnchorEl}
                            onClose={() => setPayPeriodAnchorEl(null)}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            PaperProps={{
                                style: {
                                    height: "auto",
                                    maxHeight: "500px",
                                    fontSize: "15px",
                                    color: "#4F4F4F",
                                    borderRadius: 10,
                                    background: "white",
                                    boxShadow: "-10px -5px 30px 0px rgba(0, 0, 0, 0.15)",
                                    overflow: "hidden",
                                }
                            }}
                        >
                            <div className="py-1 flex flex-col">
                                {loadingPayPeriods ? <p>Loading...</p> : null}
                                {(!payPeriods?.data.length && !loadingPayPeriods) ? <p>No Pay Periods found</p> : null}

                                {payPeriods?.data.map((payPeriod) => (
                                    <button 
                                        key={payPeriod.id}
                                        className={`w-full pl-4 pr-7 py-2 text-start text-gray-700 
                                            ${activePayPeriod?.id === payPeriod.id 
                                                ? "bg-gray-200" 
                                                : "hover:bg-gray-100"
                                            }
                                        `} 
                                        role="menuitem"
                                        onClick={() => {
                                            setActivePayPeriod(payPeriod);
                                            setPayPeriodAnchorEl(null);
                                        }}
                                    >
                                        {payPeriod.code + " "} 
                                        <span className="text-xs">
                                            ({formatDate(payPeriod.startDate)} - {formatDate(payPeriod.endDate)})
                                        </span>
                                    </button>
                                ))}
                            </div>
                        </Popover>
                    </>
                    <>
                        <button
                            onClick={(e: any) => setReportTableAnchorEl(e.currentTarget)}
                            className="relative border border-gray-300 rounded-lg text-2xs py-3 px-3 flex items-center gap-3"
                        >
                            <div className="absolute top-0 -translate-y-1/2 left-2 px-2 bg-white text-[11px]">Report Type</div>
                            <span className="pr-8">{activeReportTable.title}</span>
                            <IoCaretDown />
                        </button>
                        <Popover
                            open={Boolean(reportTableAnchorEl)}
                            anchorEl={reportTableAnchorEl}
                            onClose={() => setReportTableAnchorEl(null)}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            PaperProps={{
                                style: {
                                    height: "auto",
                                    maxHeight: "500px",
                                    fontSize: "15px",
                                    color: "#4F4F4F",
                                    borderRadius: 10,
                                    background: "white",
                                    boxShadow: "-10px -5px 30px 0px rgba(0, 0, 0, 0.15)",
                                    overflow: "hidden",
                                }
                            }}
                        >
                            <div className="py-1 flex flex-col">
                                {reportTables.map((reportTable) => (
                                    <button 
                                        key={reportTable.title}
                                        className={`w-full pl-4 pr-7 py-2 text-start text-gray-700 
                                            ${activeReportTable.title === reportTable.title 
                                                ? "bg-gray-200" 
                                                : "hover:bg-gray-100"
                                            }
                                        `} 
                                        role="menuitem"
                                        onClick={() => handleReportTableChange(reportTable)}
                                    >
                                        {reportTable.title}
                                    </button>
                                ))}
                            </div>
                        </Popover>
                    </>
                </div>
				<OrangeButton
					title={
                        fetchingExcelFile 
                        ? "Checking File Availability..." 
                        : downloadingFile
                            ? "Downloading..."
                            : "Download Excel File"
                    }
					className="bg-primary-mango text-white h-14 px-8 rounded-lg text-sm"
					onClick={handleDownload}
					disabled={fetchingExcelFile || downloadingFile || Boolean(excelFile === null)}
				/>
			</div>
			<CustomStyledTable
                key={activeReportTable.title}
                rows={!activePayPeriod?.id ? [] : activeReportTable.tableData}
                columns={activeReportTable.tableColumns}
                loading={activeReportTable.isLoadingData}
				pageCount={activeReportTable.pageCount}
				apiRef={null}
			/>
		</>
	);
}
 
export default CompanyPayrollReports;