export const NewEmployeeTabColumns = [
	{
		id: 1,
		view: "personalDetails",
		name: "Personal Details",
	},
	{
		id: 2,
		view: "employmentDetails",
		name: "Employment Details",
	},
	{
		id: 3,
		view: "paymentInfo",
		name: "Payment Info",
	},
];

export const EditEmployeeTabColumns = [
	{
		id: 1,
		view: "personalDetails",
		name: "Personal Details",
	},
	{
		id: 2,
		view: "employmentDetails",
		name: "Employment Details",
	},
	{
		id: 3,
		view: "paymentInfo",
		name: "Payment Info",
	},
	{
		id: 4,
		view: "employeeRelatives",
		name: "Employee Relatives",
	},
	{
		id: 6,
		view: "qualifications",
		name: "Qualifications",
	},
	{
		id: 7,
		view: "unionMembership",
		name: "Union Membership",
	},
	{
		id: 8,
		view: "assetAssignment",
		name: "Asset Assignment",
	},
	{
		id: 9,
		view: "requestApprovers",
		name: "Request Approvers",
	},
	{
		id: 10,
		view: "documents",
		name: "Documents",
	},
];

export const PayrollTabColumns = [
	{
		id: 1,
		view: "companyPayrollRuns",
		name: "Payroll Runs",
	},
	{
		id: 2,
		view: "companyPayslips",
		name: "Payslips",
	},
	{
		id: 3,
		view: "companyPayrollReports",
		name: "Payroll Reports",
	},
];

export const BenefitsnAllowancesTabColumns = [
	{
		id: 1,
		view: "allowances",
		name: "Allowances",
	},
	{
		id: 2,
		view: "benefits",
		name: "Benefits",
	},
	{
		id: 3,
		view: "loanBenefits",
		name: "Loan Benefits",
	},
];

export const SavingsAndBackpayTabColumns = [
	{
		id: 1,
		view: "savings",
		name: "Saving Scheme",
	},
	{
		id: 2,
		view: "backpay",
		name: "Back Pay",
	},
];

export const OvertimeAndBonusTabColumns = [
	{
		id: 1,
		view: "overtime",
		name: "Overtime",
	},
	{
		id: 2,
		view: "bonuses",
		name: "Bonuses",
	},
];

export const DeductionsAndLoansTabColumns = [
	{
		id: 1,
		view: "explicitDeductions",
		name: "Explicit Deductions",
	},
	{
		id: 2,
		view: "loans",
		name: "Loans",
	},
];

export const profiileDetailsTabColumns = [
	{
		id: 1,
		name: "Personal Details",
		view: "personalDetailsView",
	},
	{
		id: 2,
		name: "Employee Relatives",
		view: "employeeRelativesView",
	},
	{
		id: 3,
		name: "Payment Info",
		view: "paymentInfoView",
	},
	{
		id: 4,
		name: "Union Membership",
		view: "unionMembershipView",
	},
	{
		id: 5,
		name: "Employment Documents",
		view: "employmentDocumentsView",
	},
];

export const TimeManagementTabColumns = [
	{
		id: 1,
		name: "Leave Requests",
		view: "leaveRequestsView",
	},
	{
		id: 2,
		name: "Leave Plans",
		view: "leavePlansView",
	},
	{
		id: 3,
		name: "Supervisees Requests",
		view: "superviseesLeaveRequestsView",
	},
]

export const ReimbursementTabColumns = [
	{
		id: 1,
		name: "Your Claims",
		view: "yourClaimsView",
	},
	{
		id: 3,
		name: "Supervisees Claims",
		view: "superviseesClaimsView",
	},
]

export const EditEmployeeManagementTabColumns = [
	{
		id: 1,
		view: "personalDetails",
		name: "Personal Details",
	},
	{
		id: 2,
		view: "employmentDetails",
		name: "Employment Details",
	},
	{
		id: 3,
		view: "paymentInfo",
		name: "Payment Info",
	},
	{
		id: 4,
		view: "employeeRelatives",
		name: "Employee Relatives",
	},
	{
		id: 6,
		view: "qualifications",
		name: "Qualifications",
	},
	{
		id: 7,
		view: "unionMembership",
		name: "Union Membership",
	},
	{
		id: 8,
		view: "assetAssignment",
		name: "Asset Assignment",
	},
	{
		id: 8,
		view: "deductions",
		name: "Deductions",
	},
	{
		id: 9,
		view: "assignLeavePackages",
		name: "Leave Packages",
	},
];

export const leaveManagement = [
	{
		id: 1,
		name: "Leave Requests",
		view: "leaveRequestsView",
		component: "LeaveRequestsView",
	},
	{
		id: 2,
		name: "Leave Packages",
		view: "leavePackagesView",
		component: "LeavePackagesView",
	},
];

export const WorkTimeTabColumns = [
	{
		id: 1,
		name: "Work Time",
		view: "workTime",
	},
	{
		id: 2,
		name: "Overtime Management",
		view: "overtimeManagement",
	},
]

export const EmployeeWorkTimeTabColumns = [
	{
		id: 1,
		name: "Work Time",
		view: "workTime",
	},
	{
		id: 2,
		name: "Overtime",
		view: "overtime",
	},
]

export const PayAdvancesTabColumns = [
	{
		id: 1,
		name: "Salary Advance",
		view: "salaryAdvance",
	},
	{
		id: 2,
		name: "Partial Salary",
		view: "partialSalary",
	},
]

export const TaxRulesTabColumns = [
	{
		id: 1,
		name: "Rates",
		view: "rate",
	},
	{
		id: 2,
		name: "Benefits",
		view: "benefit",
	},
	{
		id: 3,
		name: "Reliefs",
		view: "relief",
	},
]