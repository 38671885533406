import{ useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import Label from "../../../../../components/form_fields/Label";
import OrangeButton from "../../../../../components/buttons/OrangeButton";
import { AiOutlineDownCircle } from "react-icons/ai";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { FREQUENCY, compareDateFields, enumToStringConverter } from "../../../../../helpers/appHelpers";
import { PAY_PERIOD_TIME_TYPE, PayPeriodDto } from "../../../../../models/pay-period.dto";
import { TaxCodeDto } from "../../../../../models/tax-code.dto";
import { useDeletePayPeriod, useUpdatePayPeriod } from "../../../../../services/pay-period.service";
import DeleteModal from "../../../../../components/modals/DeleteModal";
import { useManageAccordionsData } from "../../../../../helpers/useManageAccordions";
import FormCodeInput from "../../../../../components/form_fields/FormCodeInput";

interface IFormInputs {
    code: string;
    taxCodeId: number;
    year: number;
    startDate: string;
    endDate: string;
    sequenceNumber: number;
    timePeriod: PAY_PERIOD_TIME_TYPE;
}

interface IEditPayPeriod {
	payPeriod: PayPeriodDto;
	taxCodes: TaxCodeDto[];
}

const EditPayPeriod = ({ payPeriod, taxCodes }: IEditPayPeriod) => {
    const [expanded, setExpanded] = useState(false);
	const [formDisabled, setFormDisabled] = useState(true);
	const { mutate: updatePayPeriod } = useUpdatePayPeriod();
	const { reloadAccordionsData } = useManageAccordionsData();
	const [uploading, setUploading] = useState(false);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);

	const {
		register,
		watch,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<IFormInputs>({
		defaultValues: {
			code: payPeriod.code,
			year: payPeriod.year,
			taxCodeId: payPeriod.taxCodeId,
			sequenceNumber: payPeriod.sequenceNumber,
			startDate: payPeriod.startDate?.slice(0, 10),
			endDate: payPeriod.endDate?.slice(0, 10),
			timePeriod: payPeriod.timePeriod,
		}
	});

	const onSubmit: SubmitHandler<IFormInputs> = async (data: IFormInputs) => {
		setUploading(true);

		updatePayPeriod(
			{ payload: data, id: payPeriod.id }, 
			{
				onSuccess: () => {
					reloadAccordionsData();
					setFormDisabled(true);
					setExpanded(false);
				},
				onSettled: () => setUploading(false),
			}
		)
	};
	
	const [deleting, setDeleting] = useState(false);
	const { mutate: deletePayPeriod } = useDeletePayPeriod();

	const handleDeleteMethod = () => {
		setDeleting(true);

		deletePayPeriod(
			{ id: payPeriod?.id },
			{
				onSuccess: () => {
					reloadAccordionsData();
					setOpenDeleteModal(false);
				},
				onSettled: () => setDeleting(false),
			}
		);
	};

	return (
        <Accordion
            expanded={expanded}
            onChange={() => setExpanded(prev => !prev)}
            classes={{
                root: "border border-gray-300 rounded-lg py-2 mb-2",
            }}
        >
			<AccordionSummary
				expandIcon={<AiOutlineDownCircle className="text-xl" />}
				aria-controls={payPeriod.id + "bh-content"}
				id={payPeriod.id + "bh-header"}
				sx={{ px: 3, border: "1px" }}
			>
				<h2 className="font-inter text-sm font-semibold">
					{payPeriod.code}
				</h2>
			</AccordionSummary>
			<AccordionDetails sx={{ px: 3 }}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<>
						<div className="border-b-[1.5px] pb-3 flex justify-between font-semibold text-2xs">
							<h2 className="font-semibold text-2xs">Details</h2>
							<button
								type="button"
								className={`border-none text-primary-blue text-2xs ${
									!formDisabled ? "hidden" : ""
								}`}
								onClick={() => setFormDisabled(false)}
							>
								Edit
							</button>
						</div>
						<div className="grid grid-cols-2 gap-x-4 gap-y-6 mt-4 ">
							<div className="w-full">
								<Label title="Unique Code" for="code" />
								<FormCodeInput
									register={register}
									attributes={{
										disabled: formDisabled,
										className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
											errors.code
												? " border border-red-500 focus:border-red-500 focus:outline-red-500"
												: "border-none"
										}`,
									}}
								/>
								{errors.code && (
									<p className="text-red-500 text-sm mt-1">
										{errors.code.message}
									</p>
								)}
							</div>
							<div className="w-full">
								<Label title="Year" for={`year`} />
								<input
									placeholder={"Enter year"}
									type="number"
									{...register(`year`, {
										required: "Enter year here",
										valueAsNumber: true
									})}
									id={`year`}
									maxLength={4}
									disabled={formDisabled}
									className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
										errors?.year
											? " border border-red-500 focus:border-red-500 focus:outline-red-500"
											: "border-none"
									}`}
								/>
								{errors?.year && (
									<p className="text-red-500 text-sm mt-1">
										{errors?.year?.message}
									</p>
								)}
							</div>
							<div className="w-full">
								<Label title="Tax Code" for="taxCodeId" />
								<select
									disabled={formDisabled}
									className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm mb-2 ${
										errors.taxCodeId
											? " border border-red-500 focus:border-red-500 focus:outline-red-500"
											: "border-none"
									}`}
									{...register("taxCodeId", {
										required: "Enter tax code Id here",
									})}
								>
									<option value="">
										Select Tax Code
									</option>
									{taxCodes.map((taxCode) => (
										<option 
											key={taxCode.id} 
											value={taxCode.id} 
										>
											{taxCode.code}
										</option>
									))}
								</select>
								{errors.taxCodeId && (
									<p className="text-red-500 text-sm mt-1">
										{errors.taxCodeId.message}
									</p>
								)}
							</div>
							<div className="w-full">
								<Label title="Sequence Number" for={`sequenceNumber`} />
								<input
									placeholder={"Enter sequence number"}
									type="number"
									{...register(`sequenceNumber`, {
										required: "Enter sequence number here",
										valueAsNumber: true
									})}
									id={`sequenceNumber`}
									disabled={formDisabled}
									className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
										errors?.sequenceNumber
											? " border border-red-500 focus:border-red-500 focus:outline-red-500"
											: "border-none"
									}`}
								/>
								{errors?.sequenceNumber && (
									<p className="text-red-500 text-sm mt-1">
										{errors?.sequenceNumber?.message}
									</p>
								)}
							</div>
							<div className="w-full">
								<Label title="Start Date" for="startDate" />
								<input
									type="date"
									{...register("startDate", {
										required: "Enter start date here",
									})}
									id="startDate"
									disabled={formDisabled}
									className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
										errors.startDate
											? " border border-red-500 focus:border-red-500 focus:outline-red-500"
											: "border-none"
									}`}
								/>
								{errors.startDate && (
									<p className="text-red-500 text-sm mt-1">
										{errors.startDate.message}
									</p>
								)}
							</div>
							<div className="w-full">
								<Label title="End Date" for="endDate" />
								<input
									type="date"
									{...register("endDate", {
										required: "Enter end date here",
										validate: (value) => compareDateFields(
											watch("startDate") || "", 
											value || ""
										),
									})}
									id="endDate"
									disabled={formDisabled}
									className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
										errors.endDate
											? " border border-red-500 focus:border-red-500 focus:outline-red-500"
											: "border-none"
									}`}
								/>
								{errors.endDate && (
									<p className="text-red-500 text-sm mt-1">
										{errors.endDate.message}
									</p>
								)}
							</div>
							<div className="w-full">
								<Label title="Time Period" for="timePeriod" />
								<select
									disabled={formDisabled}
									className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm mb-2 ${
										errors.timePeriod
											? " border border-red-500 focus:border-red-500 focus:outline-red-500"
											: "border-none"
									}`}
									{...register("timePeriod", {
										required: "Enter time period here",
									})}
								>
									<option value="">
										Select Time Period
									</option>
									{FREQUENCY.map((item, index) => (
										<option 
											key={index} 
											value={item} 
										>
											{enumToStringConverter(item)}
										</option>
									))}
								</select>
								{errors.timePeriod && (
									<p className="text-red-500 text-sm mt-1">
										{errors.timePeriod.message}
									</p>
								)}
							</div>
						</div>
					</>
					
					{!formDisabled && (
						<div className="flex w-full justify-end space-x-4 items-center mt-10">
							<button
								type="button"
								className="px-12 py-4 border text-gray-400 hover:text-gray-500 border-gray-300 rounded-lg text-2xs"
								onClick={() => {
									setFormDisabled(true);
									reset();
								}}
							>
								Cancel
							</button>
							<OrangeButton
								title={`${uploading ? "Saving..." : "Save"}`}
								className={"px-12"}
								type={"submit"}
								disabled={uploading}
							/>
						</div>
					)}
					
					{formDisabled && (
						<div className="flex w-full justify-end space-x-4 items-center mt-10">
							<button
								type="button"
								onClick={() => setOpenDeleteModal(true)}
								className={`px-12 py-4 border text-white hover:shadow-sm bg-secondary-red rounded-lg text-2xs ${uploading && "opacity-50"}`}
								disabled={uploading}
							>
								Delete
							</button>
						</div>
					)}

					<DeleteModal
						openDeleteModal={openDeleteModal}
						spanContent={payPeriod.code}
						setOpenDeleteModal={setOpenDeleteModal}
						handleDeleteMethod={handleDeleteMethod}
						deleting={deleting}
					/>
				</form>
			</AccordionDetails>
		</Accordion>
	);
};

export default EditPayPeriod;
