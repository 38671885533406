import { useRef, useState, useEffect } from "react";
import { CustomizedTooltip } from "../helpers/appHelpers";

const TruncatedListItem = ({ text, tooltipMaxWidth = "none" }) => {
    const itemRef = useRef<HTMLParagraphElement>(null);
    const [isTruncated, setIsTruncated] = useState(false);

    useEffect(() => {
        if (itemRef.current) {
            setIsTruncated(itemRef.current.scrollWidth > itemRef.current.clientWidth);
        }
    }, [text]);

    return (
        <CustomizedTooltip 
            title={isTruncated ? text : ''}
            placement="top"
            maxWidth={tooltipMaxWidth}
            arrow
            disableInteractive
        >
            <p ref={itemRef} className="truncate">{text}</p>
        </CustomizedTooltip>
    );
};
 
export default TruncatedListItem;