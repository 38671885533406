import { FaCircle, FaCheck } from "react-icons/fa";
import { PasswordPolicyPublicDto } from "../models/password-policy.dto";
import { useState, useEffect } from "react";
  
const countMatches = (regex: RegExp, str: string) => (str.match(regex) || []).length;

export function validatePassword(password: string, policy: PasswordPolicyPublicDto): boolean {
    const {
        minLength,
        maxLength,
        minUppercaseCount,
        minLowercaseCount,
        minDigitCount,
        minSymbolCount,
    } = policy;
  
    // Count characters matching specific patterns
    const uppercaseCount = countMatches(/[A-Z]/g, password);
    const lowercaseCount = countMatches(/[a-z]/g, password);
    const digitCount = countMatches(/[0-9]/g, password);
    const symbolCount = countMatches(/[^A-Za-z0-9]/g, password);
  
    if (minLength !== null && password.length < minLength) {
        return false;
    }
    if (maxLength !== null && password.length > maxLength) {
        return false;
    }
    if (minUppercaseCount !== null && uppercaseCount < minUppercaseCount) {
        return false;
    }
    if (minLowercaseCount !== null && lowercaseCount < minLowercaseCount) {
        return false;
    }
    if (minDigitCount !== null && digitCount < minDigitCount) {
        return false;
    }
    if (minSymbolCount !== null && symbolCount < minSymbolCount) {
        return false;
    }
  
    return true;
}

type PasswordRequirementModalProps = {
	passwordPolicy: PasswordPolicyPublicDto;
    password: string;
}
  
export const PasswordRequirementModal = ({
	passwordPolicy,
	password,
}: PasswordRequirementModalProps) => {
    const [matches, setMatches] = useState<Record<string, boolean>>({});

    const handleUpdateMatches = (
        policyParam: number | null,
        passwordReqCount: number,
        matchField: string,
    ) => {
        if (policyParam !== null && passwordReqCount >= policyParam) {
            setMatches(prev => ({ ...prev, [matchField]: true }));
        } else {
            setMatches(prev => ({ ...prev, [matchField]: false }));
        }
    }

    useEffect(() => {
        if (!password) {
            setMatches({});
            return
        }

        const {
            minLength,
            maxLength,
            minUppercaseCount,
            minLowercaseCount,
            minDigitCount,
            minSymbolCount,
        } = passwordPolicy;
      
        // Count characters matching specific patterns
        const uppercaseCount = countMatches(/[A-Z]/g, password);
        const lowercaseCount = countMatches(/[a-z]/g, password);
        const digitCount = countMatches(/[0-9]/g, password);
        const symbolCount = countMatches(/[^A-Za-z0-9]/g, password);
      
        if (maxLength !== null && password.length <= maxLength) {
            setMatches(prev => ({ ...prev, maxLength: true }));
        } else {
            setMatches(prev => ({ ...prev, maxLength: false }));
        }

        handleUpdateMatches(minLength, password.length, "minLength");
        handleUpdateMatches(minUppercaseCount, uppercaseCount, "minUppercaseCount");
        handleUpdateMatches(minLowercaseCount, lowercaseCount, "minLowercaseCount");
        handleUpdateMatches(minDigitCount, digitCount, "minDigitCount");
        handleUpdateMatches(minSymbolCount, symbolCount, "minSymbolCount");
    }, [password])

	return (
        <div className="flex justify-center rounded-[16px] border border-[#D4D4D4] bg-white
            shadow-[-10px_-5px_30px_0px_rgba(0,0,0,0.15)] absolute top-[100%] left-0 z-[999999]"
        >
            <ul className="px-3 py-3 text-xs">
                {passwordPolicy.minLength ? (
                    <li className={`flex items-center ${matches["minLength"] ? "opacity-50" : ""}`}>
                        {matches["minLength"]  
                            ? <FaCheck className="text-[8px] mr-[5px]"/>
                            : <FaCircle className="text-[5px] mr-[5px]"/>
                        }
                        <span>Must be {passwordPolicy.minLength} chracters long.</span>
                    </li>
                ): null}
                {passwordPolicy.maxLength ? (
                    <li className={`flex items-center ${matches["maxLength"] ? "opacity-50" : ""}`}>
                        {matches["maxLength"]  
                            ? <FaCheck className="text-[8px] mr-[5px]"/>
                            : <FaCircle className="text-[5px] mr-[5px]"/>
                        }
                        <span>Must not be more than {passwordPolicy.maxLength} chracters.</span>
                    </li>
                ): null}
                {passwordPolicy.minLowercaseCount ? (
                    <li className={`flex items-center ${matches["minLowercaseCount"] ? "opacity-50" : ""}`}>
                        {matches["minLowercaseCount"]  
                            ? <FaCheck className="text-[8px] mr-[5px]"/>
                            : <FaCircle className="text-[5px] mr-[5px]"/>
                        }
                        <span>Must contain {passwordPolicy.minLowercaseCount} small letter (a...z).</span>
                    </li>
                ): null}
                {passwordPolicy.minUppercaseCount ? (
                    <li className={`flex items-center ${matches["minUppercaseCount"] ? "opacity-50" : ""}`}>
                        {matches["minUppercaseCount"]  
                            ? <FaCheck className="text-[8px] mr-[5px]"/>
                            : <FaCircle className="text-[5px] mr-[5px]"/>
                        }
                        <span>Must contain {passwordPolicy.minUppercaseCount} capital letter (A...Z).</span>
                    </li>
                ): null}
                {passwordPolicy.minSymbolCount ? (
                    <li className={`flex items-center ${matches["minSymbolCount"] ? "opacity-50" : ""}`}>
                        {matches["minSymbolCount"]  
                            ? <FaCheck className="text-[8px] mr-[5px]"/>
                            : <FaCircle className="text-[5px] mr-[5px]"/>
                        }
                        <span>Must contain {passwordPolicy.minSymbolCount} special character (!...&).</span>
                    </li>
                ): null}
                {passwordPolicy.minDigitCount ? (
                    <li className={`flex items-center ${matches["minDigitCount"] ? "opacity-50" : ""}`}>
                        {matches["minDigitCount"]  
                            ? <FaCheck className="text-[8px] mr-[5px]"/>
                            : <FaCircle className="text-[5px] mr-[5px]"/>
                        }
                        <span>Must contain {passwordPolicy.minDigitCount} number (0...9) </span>
                    </li>
                ): null}
            </ul>
        </div>
	);
};