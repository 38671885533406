import SelfserviceLayout from "../../../components/layouts/SelfserviceLayout";
import { Helmet } from "react-helmet-async";
import { BsDownload } from "react-icons/bs";
import Search from "../../../components/search/SearchInput";
import CustomPagination from "../../../components/table/CustomPagination";
import CustomStyledTable from "../../../components/table/CustomStyledTable";
import { Columns, PayslipsColumnVisibilityModel } from "./data";
import { useManageTableWithSearchParams } from "../../../helpers/useManageTable";
import { Payslips, QueryParam } from "../../../models/payroll.dto";
import { useGetApprovedEmployeePaysips, useGetEmployeePayslipPdf } from "../../../services/payrol.service";
import useUserStore from "../../../state-management/useUserStore";
import { SkewLoader } from "react-spinners";
import ManageTableColumns from "../../../components/table/ManageTableColumns";
import { useColumnVisibilityModel } from "../../../helpers/useColumnVisibilityModel";
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import { formatDate, moneyFormat } from "../../../helpers/appHelpers";

const SelfservicePayslips = () => {
	const companyProfile = (useUserStore().currentUser.user.companyProfiles || [])[0];
	const {
        tableKey,
        columnVisibilityModel,
        setColumnVisibilityModel,
    } = useColumnVisibilityModel(PayslipsColumnVisibilityModel);

	const {
        tableAPIRef,
        fetchedTableData,
        tableData,
        isLoading,
        pageCount,
        setTableData,
    } = useManageTableWithSearchParams<Payslips>({
		fetchHook: (options: QueryParam) => useGetApprovedEmployeePaysips(companyProfile?.employeeId as number, options),
		options: { ordeyBy: undefined }
	});
	
	const { mutate: fetchPayslipPdf, isLoading: fetchingPayslipPdf } = useGetEmployeePayslipPdf();

	const handleDownloadPayslip = (payslip: Payslips) => {
		fetchPayslipPdf(
			{
				options: { 
					employeeId: payslip.employee?.id, 
					payPeriodId: payslip.payPeriodId, 
				}
			}, 
			{
				onSuccess: (data) => {
					if (data.data) {
						const fileName = "payslip_" + (payslip.id || "") + ".pdf";
						saveAs(data.data, fileName);
						return
					}
					toast.error("Payslip pdf not found");
				}, 
			}
		)
	};

	const additionalColumns = [
		{
			field: "actions",
			headerName: "Actions",
			width: 180,
			headerClassName: "super-app-theme--header",
            renderCell: (params) => {
                return (
                    <button 
                        disabled={fetchingPayslipPdf} 
                        className="py-2 px-4 rounded-[6px] flex items-center gap-3 text-primary-blue text-2sm hover:bg-gray-200"
						onClick={() => handleDownloadPayslip(params.row)}
                    >
                        <BsDownload className="font-bold" />
                        <p>Download</p>
                    </button>
                )
			},
		},
	];

	const columnsWithAdditions = Columns.map(
		(obj) => additionalColumns.find((o) => o.field === obj.field) || obj
	);
	
	return (
		<SelfserviceLayout pageHeader="Payslips">
			<Helmet>
				<title>Payslips | Self Service Flow </title>
			</Helmet>
			<div className="mb-10 lg:mb-0">
				<div className="lg:w-fit w-full flex items-center gap-3 lg:justify-end mb-6">
					<Search fallbackData={fetchedTableData} setTableData={setTableData} />
					<ManageTableColumns 
						columns={Columns}
						defaultVisibilityModel={PayslipsColumnVisibilityModel}
						visibilityModel={columnVisibilityModel}
						setVisibilityModel={setColumnVisibilityModel}
					/>
				</div>
				<div className="mobile-full-width max-lg:pl-4 max-lg:hidden">
					<CustomStyledTable
						key={tableKey}
						rows={tableData}
						columns={columnsWithAdditions}
						apiRef={tableAPIRef}
						columnVisibilityModel={columnVisibilityModel}
						loading={isLoading}
						pageCount={pageCount}
					/>
				</div>
				<div className="lg:hidden w-full flex flex-col gap-6">
					{(isLoading && !tableData.length) && (
						<div className="text-center py-16">
							<SkewLoader color="#F58B00" />
						</div>
					)}

					{(!isLoading && !tableData.length) && (
						<p className="text-center text-sm font-semibold py-10">
							No payslips found
						</p>
					)}

					{tableData.map((payslip) => (
						<div key={payslip.id} className="w-full px-4 py-6 rounded-[10px] bg-[#F2F2F4] flex flex-col gap-4">
							<div className="w-full flex items-center justify-between">
								<h6 className="text-2sm font-bold text-secondary-darkgray">Pay Period</h6>
								<p className="text-sm font-medium text-charcoal">
									{formatDate(payslip.payPeriod?.startDate)} - {formatDate(payslip.payPeriod?.endDate)}
								</p>
							</div>
							<div className="w-full flex items-center justify-between">
								<h6 className="text-2sm font-bold text-secondary-darkgray">Basic Pay</h6>
								<p className="text-sm font-medium text-charcoal">{moneyFormat(payslip.basicPayAmount)}</p>
							</div>
							<div className="w-full flex items-center justify-between">
								<h6 className="text-2sm font-bold text-secondary-darkgray">Gross Pay</h6>
								<p className="text-sm font-medium text-charcoal">{moneyFormat(payslip.grossAmount)}</p>
							</div>
							<div className="w-full flex items-center justify-between">
								<h6 className="text-2sm font-bold text-secondary-darkgray">Net Pay</h6>
								<p className="text-sm font-medium text-charcoal">{moneyFormat(payslip.bonusAmount)}</p>
							</div>
							<button 
								onClick={() => handleDownloadPayslip(payslip)}
								className="flex gap-3 text-sm font-medium text-primary-blue"
								disabled={fetchingPayslipPdf} 
							>
								<BsDownload className="text-xl" />
								Download
							</button>
						</div>
					))}
				</div>
				<div className="lg:hidden flex justify-end mt-8">
					<CustomPagination pageCount={pageCount} />
				</div>
			</div>
		</SelfserviceLayout>
	);
};

export default SelfservicePayslips;