import { useEffect, useState } from "react";
import ModalComponent from "../../modals/ModalFormComponent";
import Label from "../../form_fields/Label";
import { SubmitHandler, useForm } from "react-hook-form";
import OrangeButton from "../../buttons/OrangeButton";
import { 
	useChangePassword, 
	useGetAuthDefaultPasswordPolicy, 
	useGetAuthPasswordPolicyById 
} from "../../../services/auth.service";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { PasswordPolicyPublicDto } from "../../../models/password-policy.dto";
import useUserStore from "../../../state-management/useUserStore";
import { PasswordRequirementModal, validatePassword } from "../../../helpers/useValidatePassword";

interface IChangePasswordModalProps {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	title: string;
}

interface IFormInputs {
	currentPassword: string;
	newPassword: string;
	confirmPassword: string;
}

const ChangePasswordModal = ({
	open,
	setOpen,
	title,
}: IChangePasswordModalProps) => {
	const { currentUser } = useUserStore();
    const [isShown, setIsShown] = useState(false);
	const [uploading, setUploading] = useState(false);
    const [passwordPolicy, setPasswowrdPolicy] = useState<PasswordPolicyPublicDto>();
    const [passwordRequirementPopup, setPasswordRequirementPopup] = useState(false);
    const [validPassword, setValidPassword] = useState(false);

    const { mutate: getAuthPasswordPolicyById } = useGetAuthPasswordPolicyById();
    const { mutate: getAuthDefaultPasswordPolicy } = useGetAuthDefaultPasswordPolicy();

    useEffect(() => {
		const organizationRole = currentUser.user.organizationRole;

        if (organizationRole?.primary) {
            getAuthDefaultPasswordPolicy(
                {}, { onSuccess: (data) => setPasswowrdPolicy(data.data) }
            )
        } else {
            getAuthPasswordPolicyById(
                { organizationId: currentUser.user.organizationId },
                { onSuccess: (data) => setPasswowrdPolicy(data.data) }
            )
        }
    }, [])

	const {
		register,
		watch,
		handleSubmit,
		getValues,
		formState: { errors },
	} = useForm<IFormInputs>();
	
    const newPassword = watch("newPassword");
	const { mutate: changePassword } = useChangePassword();
	
    useEffect(() => {
        if (!newPassword || !passwordPolicy) {
            setValidPassword(false);
            return
        }

        const isValid = validatePassword(newPassword, passwordPolicy);
        setValidPassword(isValid);
    }, [newPassword])

	const onSubmit: SubmitHandler<IFormInputs> = (data) => {
		setUploading(true);

		changePassword(
			{
				payload: {
					password: data.currentPassword,
					newPassword: data.newPassword
				}
			},
			{
				onSuccess: () => handleClose(),
				onSettled: () => setUploading(false),
			}
		)
	};

	const handleClose = () => setOpen(false);

	return (
		<ModalComponent
			width={window.innerWidth < 1024 ? "90%" : 600}
			open={open}
			title={title}
			handleClose={handleClose}
		>
			<div className="space-y-4 mt-4">
				<div className="w-full">
					<Label title="Current Password" for="currentPassword" />
					<div className="relative">
						<input
							type={!isShown ? "password" : "text"}
							id={"currentPassword"}
							placeholder={"Enter current password"}
							className={`w-full bg-secondary-gray rounded-lg py-4 px-4 mt-2 bg text-sm  ${
								errors.currentPassword
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							{...register("currentPassword", {
								required: "Enter your current password",
							})}
						/>
						<div
							className={`absolute top-[26px] right-6 block `}
							onClick={() => setIsShown(prev => !prev)}
						>
							{!isShown ? (
								<BsEyeSlash className="text-gray-400 text-lg"/>
							) : (
								<BsEye className="text-gray-400 text-lg"/>
							)}
						</div>
					</div>
					{errors.currentPassword && (
						<p className="text-red-500 text-sm mt-1">
							{errors.currentPassword.message}
						</p>
					)}
				</div>
				<div className="w-full">
					<Label title="New Password" for="newPassword"/>
					<div className="relative">
						<input
							type={!isShown ? "password" : "text"}
							id={"newPassword"}
							placeholder={"Enter new password"}
							className={`w-full bg-secondary-gray rounded-lg py-4 px-4 mt-2 bg text-sm  ${
								errors.newPassword
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							{...register("newPassword", {
								required: "Enter your new password",
							})}
							autoComplete="off"
							disabled={!passwordPolicy}
							onClick={() => setPasswordRequirementPopup(true)}
							onBlur={() => setPasswordRequirementPopup(false)}
						/>
						<div
							className={`absolute top-[26px] right-6 block `}
							onClick={() => setIsShown(prev => !prev)}
						>
							{!isShown ? (
								<BsEyeSlash className="text-gray-400 text-lg"/>
							) : (
								<BsEye className="text-gray-400 text-lg"/>
							)}
						</div>
						{(!validPassword && passwordPolicy && passwordRequirementPopup) && (
							<PasswordRequirementModal 
								passwordPolicy={passwordPolicy} 
								password={newPassword} 
							/> 
						)}
					</div>
					{errors.newPassword && (
						<p className="text-red-500 text-sm mt-1">
							{errors.newPassword.message}
						</p>
					)}
				</div>
				<div className="w-full">
					<Label title="Confirm Password" for="confirmPassword" />
					<div className="relative">
						<input
							type={!isShown ? "password" : "text"}
							id={"confirmPassword"}
							placeholder={"Confirm new password"}
							className={`w-full bg-secondary-gray rounded-lg py-4 px-4 mt-2 bg text-sm  ${
								errors.confirmPassword
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							{...register("confirmPassword", {
								required: "Confirm your new password",
								validate: {
									matchesPreviousPassword: (value) => {
										const { newPassword } = getValues();
										return newPassword === value || "Passwords should match!";
									},
								},
							})}
							autoComplete="off"
							disabled={!passwordPolicy}
						/>
						<div
							className={`absolute top-[26px] right-6 block `}
							onClick={() => setIsShown(prev => !prev)}
						>
							{!isShown ? (
								<BsEyeSlash className="text-gray-400 text-lg"/>
							) : (
								<BsEye className="text-gray-400 text-lg"/>
							)}
						</div>
					</div>
					{errors.confirmPassword && (
						<p className="text-red-500 text-sm mt-1">
							{errors.confirmPassword.message}
						</p>
					)}
				</div>
			</div>
			<div className="flex justify-end pt-10">
				<OrangeButton
					title={
						passwordPolicy
							? uploading 
								? "Updating..." 
								: "Update"
							: "Fetching Password Requirements..."
					}
					className={"px-12"}
					onClick={handleSubmit(onSubmit)}
					disabled={!passwordPolicy || !validPassword || uploading}
				/>
			</div>
		</ModalComponent>
	);
};

export default ChangePasswordModal;
