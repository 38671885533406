import {PaginatorInfo} from "./generic.dto";
import {CostCenterDto} from "./cost-center.dto";
import { EmployeeDto } from "./employee.dto";
import { EmployeeBand } from "./employee-band.dto";
import { PayrollCompanyDto } from "./company-account.dto";
import { PayPeriodDto } from "./pay-period.dto";

export const APPLY_SAVINGS_CONTRIBUTION_ON = {
    BASIC: 'BASIC',
    GROSS: 'GROSS',
    FIXED: 'FIXED'
};

export type APPLY_SAVINGS_CONTRIBUTION_ON = (typeof APPLY_SAVINGS_CONTRIBUTION_ON)[keyof typeof APPLY_SAVINGS_CONTRIBUTION_ON]

export type SavingsScheme = {
    id: number
    companyId: number
    code: string
    name: string
    description: string
    applyEmployeeContributionOn: APPLY_SAVINGS_CONTRIBUTION_ON
    applyEmployerContributionOn: APPLY_SAVINGS_CONTRIBUTION_ON
    employeeFixedComponent: number
    employeeRateComponent: number
    employerFixedComponent: number
    employerRateComponent: number
    prorate: boolean
    statutory: boolean
    startPayPeriodId: number | null
    endPayPeriodId: number | null
    createdAt: string
    modifiedAt: string | null
}

export class CreateSavingsSchemeDto {
    companyId!: number
    code!: string
    name!: string
    description!: string
    applyEmployeeContributionOn!: APPLY_SAVINGS_CONTRIBUTION_ON
    applyEmployerContributionOn!: APPLY_SAVINGS_CONTRIBUTION_ON
    employeeContributionFigure!: number
    employerContributionFigure!: number
    prorate!: boolean
    statutory!: boolean
    startPayPeriodId?: number
    endPayPeriodId?: number
    employeeIds?: number[]
    employeeBandIds?: number[]
}

export class UpdateSavingsSchemeDto {
    code?: string
    name?: string
    description?: string
    applyEmployeeContributionOn?: APPLY_SAVINGS_CONTRIBUTION_ON
    applyEmployerContributionOn?: APPLY_SAVINGS_CONTRIBUTION_ON
    employeeContributionFigure?: number
    employerContributionFigure?: number
    prorate?: boolean
    statutory?: boolean
    startPayPeriodId?: number
    endPayPeriodId?: number
    addEmployeeBandIds?: number[];
    removeEmployeeBandIds?: number[];
    addEmployeeIds?: number[];
    removeEmployeeIds?: number[];
}

export interface SavingsSchemeDto extends SavingsScheme {
    employeeSavingsSchemes?: EmployeeSavingsSchemeDto[];
    employeeBandSavingsSchemes?: EmployeeBandSavingsSchemeDto[];
    company?: PayrollCompanyDto;
    startPayPeriod?: PayPeriodDto;
    endPayPeriod?: PayPeriodDto;
}

export interface EmployeeSavingsSchemeDto extends EmployeeSavingsScheme {
    employee?: EmployeeDto;
}
  
export interface EmployeeBandSavingsSchemeDto extends EmployeeBandSavingsScheme {
    employeeBand?: EmployeeBand;
}

type EmployeeSavingsScheme = {
    id: number;
    employeeId: number;
    savingsSchemeId: number;
    active: boolean;
    createdAt: string;
    modifiedAt: string | null;
}

type EmployeeBandSavingsScheme = {
    id: number;
    employeeBandId: number;
    savingsSchemeId: number;
    active: boolean;
    createdAt: string;
    modifiedAt: string | null;
}

export class QuerySavingsSchemeDto {
    companyId?: number;
    code?: string
    applyEmployeeContributionOn?: APPLY_SAVINGS_CONTRIBUTION_ON
    applyEmployerContributionOn?: APPLY_SAVINGS_CONTRIBUTION_ON
    prorate?: boolean
    statutory?: boolean
    page?: number = 1;
    limit?: number
    orderBy?: SavingsSchemeOrderBy = SavingsSchemeOrderBy.CREATED_AT_ASC;
}

export class SearchSavingsSchemeDto {
    q?: string
    page?: number = 1;
    limit?: number
    orderBy?: SavingsSchemeOrderBy = SavingsSchemeOrderBy.CREATED_AT_ASC;
}


export enum SavingsSchemeOrderBy {
    CREATED_AT_ASC = 'createdAt:asc',
    CREATED_AT_DESC = 'createdAt:desc',
    MODIFIED_AT_ASC = 'modifiedAt:asc',
    MODIFIED_AT_DESC = 'modifiedAt:desc',
    CODE_ASC = 'code:asc',
    CODE_DESC = 'code:desc',
    NAME_ASC = 'name:asc',
    NAME_DESC = 'name:desc'
}



export class SavingsSchemeUpdateInput {
    code?: string
    name?: string
    description?: string
    applyEmployeeContributionOn?: APPLY_SAVINGS_CONTRIBUTION_ON
    applyEmployerContributionOn?: APPLY_SAVINGS_CONTRIBUTION_ON
    employeeContributionFigure?: number
    employerContributionFigure?: number
    employeeFixedComponent!: number
    employeeRateComponent!: number
    employerFixedComponent!: number
    employerRateComponent!: number
    prorate?: boolean
    statutory?: boolean
    startPayPeriodId?: number
    endPayPeriodId?: number

    constructor(updateSavingsSchemeDto: UpdateSavingsSchemeDto) {
        this.code = updateSavingsSchemeDto.code
        this.name = updateSavingsSchemeDto.name
        this.description = updateSavingsSchemeDto.description
        this.applyEmployeeContributionOn = updateSavingsSchemeDto.applyEmployeeContributionOn
        this.applyEmployerContributionOn = updateSavingsSchemeDto.applyEmployerContributionOn
        this.prorate = updateSavingsSchemeDto.prorate
        this.statutory = updateSavingsSchemeDto.statutory
        this.startPayPeriodId = updateSavingsSchemeDto.startPayPeriodId
        this.endPayPeriodId = updateSavingsSchemeDto.endPayPeriodId
    }
}

export interface SavingsSchemePaginator extends PaginatorInfo<SavingsSchemeDto> {}
