import {useQuery, useInfiniteQuery, useQueryClient, useMutation} from "react-query";
import {toast} from 'react-toastify';
import API from './utils/api';
import { CreatePayrollDto, DownloadReportPaginator, DownloadReportQueryDto, MigrationDto, PayPeriod, PayrollRuns, PayrollRunsPaginator, PayrollRunStatusObject, PayslipPaginator, QueryCompanyPayslipsDto, QueryEmployeePayslipsDto, QueryParam, QueryPayrollDto, QueryReportDto, SavingSchemeReportResponsePaginator, SSFResponsePaginator } from "../models/payroll.dto";
import ENDPOINTS from "./utils/endpoints";

export function useGetEmployeePaysips(employeeId: number, options: QueryParam) {
    const url = ENDPOINTS.PAYROLL.GET_EMPLOYEE_PAYSLIPS;
    const request = (options: QueryParam) => API.PayrollAPI.getEmployeePayslips(employeeId, options);

    const { data, isLoading, error, refetch } = useQuery<PayslipPaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        {...API.Handlers.pageParamOption}
    );

    return { data, isLoading, error, refetch }
}

export function useGetApprovedEmployeePaysips(employeeId: number, options: QueryParam) {
    const url = ENDPOINTS.PAYROLL.GET_EMPLOYEE_PAYSLIPS;
    const request = (options: QueryParam) => API.PayrollAPI.getApprovedEmployeePayslips(employeeId, options);

    const { data, isLoading, error, refetch } = useQuery<PayslipPaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        {...API.Handlers.pageParamOption}
    );

    return { data, isLoading, error, refetch }
}

export function useGetCompanyPaysips(companyId: number, payrollId: number, options?: QueryCompanyPayslipsDto) {
    const url = ENDPOINTS.PAYROLL.GET_COMPANY_PAYSLIPS;
    const request = (options: QueryCompanyPayslipsDto) => API.PayrollAPI.getCompanyPayslips(companyId, payrollId, options);

    const { data, isLoading, error, refetch } = useQuery<PayslipPaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        {...API.Handlers.pageParamOption}
    );

    return { data, isLoading, error, refetch }
}

export function useGetPayrollRunParams(id: number, options: QueryParam) {
    const url = ENDPOINTS.PAYROLL.GET_PAYROLL_RUN_PARAMS;
    const request = API.PayrollAPI.getPayrollRunParams(id, options);

    const {data, isLoading, error} = useQuery<{ data: PayrollRuns }, Error>(
        [url, id],
        (() => request),
        {
            enabled: !!(id),
        }
    );

    return {
        data: data,
        isLoading,
        error,
    };
}

export function useGetCompanyPayrollRuns(options?: QueryPayrollDto) {
    const url = ENDPOINTS.PAYROLL.GET_COMPANY_PAYROLL_RUNS;
    const request = (options: QueryPayrollDto) => API.PayrollAPI.getCompanyPayrollRuns(options);

    const { data, isLoading, error, refetch } = useQuery<PayrollRunsPaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        {...API.Handlers.pageParamOption}
    );

    return { data, isLoading, error, refetch }
}

export function useRunPayrollParams() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ id, options }: { id: number, options: QueryParam }) => API.PayrollAPI.runPayrollParams(id, options),
        {
            onSuccess: () => {
                toast.success('Payroll ran successfully');
                queryClient.invalidateQueries(ENDPOINTS.PAYROLL.GET_COMPANY_PAYROLL_RUNS);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useCreatePayrollRun() {
    const queryClient = useQueryClient();

    return useMutation(
        ({ payload, options }: { payload: CreatePayrollDto, options: QueryParam}) => API.PayrollAPI.createPayrollRun(payload, options),
        {
            onSuccess: () => {
                toast.success('Record created successfully');
                queryClient.invalidateQueries(ENDPOINTS.PAYROLL.GET_COMPANY_PAYROLL_RUNS);
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useGetPayrollExcell() {

    return useMutation(
        ({ companyId, payPeriodId, options }: { companyId: number, payPeriodId: number, options: QueryParam }) => API.PayrollAPI.getPayrollExcell(companyId, payPeriodId, options),
        {
            onSuccess: () => {},
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useGetRecentPayrollRun(id: number, options: QueryParam) {
    const url = ENDPOINTS.PAYROLL.GET_RECENT_PAYROLL_RUN;
    const request = API.PayrollAPI.getRecentPayrollRun(id, options);

    const {data, isLoading, error, refetch} = useQuery<{ data: PayPeriod }, Error>(
        [url, id],
        (() => request),
        {
            enabled: false,
        }
    );

    return {
        data: data,
        isLoading,
        error,
        refetch,
    };
}

export function useGetEmployeePayslipPdf() {

    return useMutation(
        ({ options }: { options: QueryEmployeePayslipsDto }) => API.PayrollAPI.getEmployeePayslip(options),
        {
            onSuccess: () => {},
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useRunPayrollStatus(id: number, options: QueryParam) {
    const url = ENDPOINTS.PAYROLL.GET_PAYROLL_RUN_STATUS;
    const request = API.PayrollAPI.getPayrollRunStatus(id, options);

    const {data, isLoading, error} = useQuery<{ data: PayrollRunStatusObject }, Error>(
        [url, id],
        (() => request),
        {
            enabled: !!(id),
        }
    );

    return {
        data: data,
        isLoading,
        error,
    };
}

export function useApprovePayroll() {
    
    return useMutation(
        ({ id, options }: { id: number, options: QueryParam }) => API.PayrollAPI.approvePayroll(id, options),
        {
            onSuccess: () => {
                toast.success('Publish payslips successfully');
            },
            onError: API.Handlers.onErrorHandler,
        }
    )
}

export function useGetRunMigraton() {
    const url = ENDPOINTS.PAYROLL.RUN_MIGRATION;
    const request = API.PayrollAPI.runMigration();

    const {data, isLoading, error} = useQuery<MigrationDto, Error>(
        [url],
        (() => request)
    );

    return {
        data: data,
        isLoading,
        error,
    };
}

export function useGetSSFOneReport(options?: QueryReportDto) {
    const url = ENDPOINTS.PAYROLL.GET_SSF_ONE_REPORT;
    const request = (options: QueryReportDto) => API.PayrollAPI.getSSFOneReport(options);

    const { data, isLoading, error, refetch } = useQuery<SSFResponsePaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        {...API.Handlers.pageParamOption}
    );

    return { data, isLoading, error, refetch }
}

export function useGetSSFTwoReport(options?: QueryReportDto) {
    const url = ENDPOINTS.PAYROLL.GET_SSF_TWO_REPORT;
    const request = (options: QueryReportDto) => API.PayrollAPI.getSSFTwoReport(options);

    const { data, isLoading, error, refetch } = useQuery<SSFResponsePaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        {...API.Handlers.pageParamOption}
    );

    return { data, isLoading, error, refetch }
}

export function useGetSavingSchemeReport(options?: QueryReportDto) {
    const url = ENDPOINTS.PAYROLL.GET_SAVING_SCHEME_REPORT;
    const request = (options: QueryReportDto) => API.PayrollAPI.getSavingSchemeReport(options);

    const { data, isLoading, error, refetch } = useQuery<SavingSchemeReportResponsePaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        {...API.Handlers.pageParamOption}
    );

    return { data, isLoading, error, refetch }
}

export function useGetDownloadReport() {

    return useMutation(
        ({ options }: { options: DownloadReportQueryDto }) => API.PayrollAPI.downloadReport(options),
        {
            onSuccess: () => {},
            onError: API.Handlers.onErrorHandler,
        }
    )
}