import { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import OrangeButton from "../../../../components/buttons/OrangeButton";
import FormDescriptionInput from "../../../../components/form_fields/FormDescriptionInput";
import Label from "../../../../components/form_fields/Label";
import MoneyInput from "../../../../components/form_fields/MoneyInput";
import ModalComponent from "../../../../components/modals/ModalFormComponent";
import { formatRate, IAddEditTableForm } from "../../../../helpers/appHelpers";
import { AllowanceDto } from "../../../../models/allowance.dto";
import { BACKPAY_CATEGORY } from "../../../../models/back-pay.dto";
import { EmployeeDto } from "../../../../models/employee.dto";
import { PayPeriodDto } from "../../../../models/pay-period.dto";
import { useCreateBackPay, useUpdateBackPay } from "../../../../services/back-pay.service";
import useEditFormStore from "../../../../state-management/useEditFormStore";
import { useManageTableWithSearchParamsData } from "../../../../helpers/useManageTable";
import useCompanyStore from "../../../../state-management/useCompanyStore";
import APISelectField from "../../../../components/form_fields/APISelectField";
import FormCodeInput from "../../../../components/form_fields/FormCodeInput";
import MultiSelectField from "../../../../components/form_fields/MultiSelectField";
import { PATHS } from "../../../../routes/routes.paths";
import RateComponent from "../../../../components/form_fields/RateComponent";

interface IFormInputs {
    employeeId: number;
    name: string;
    code: string;
    description: string;
    startPayPeriodId: number;
    endPayPeriodId: number;
    applicablePayPeriodId: number;
    category: BACKPAY_CATEGORY;
    fixedComponent?: number;
    rateComponent?: number;
    allowances?: number[];
}

type BackPayModalForm = IAddEditTableForm & {
    employees: EmployeeDto[];
    allowances: AllowanceDto[];
    payPeriods: PayPeriodDto[];
}

const BackPayModalForm = ({
	open,
	setOpen,
	title,
	isEditForm,
	tableAPIRef,
	employees,
	allowances,
	payPeriods,
}: BackPayModalForm) => {
	const { currentCompany } = useCompanyStore();
	const {formDataValues} = useEditFormStore();
	const [uploading, setUploading] = useState(false);

	const {
		register,
		watch,
		handleSubmit,
		setValue,
		reset,
		formState: { errors, isSubmitted },
	} = useForm<IFormInputs>({
		defaultValues: {
			name: isEditForm ? formDataValues?.name : "",
			code: isEditForm ? formDataValues?.code : "",
			description: isEditForm ? formDataValues?.description : "",
			startPayPeriodId: isEditForm ? formDataValues?.startPayPeriodId : "",
			endPayPeriodId: isEditForm ? formDataValues?.endPayPeriodId : "",
			applicablePayPeriodId: isEditForm ? formDataValues?.applicablePayPeriodId : "",
			category: isEditForm ? formDataValues?.category : "",
			fixedComponent: isEditForm ? formDataValues?.fixedComponent : "",
			rateComponent: isEditForm ? (Number(formDataValues?.rateComponent) * 100) as any : "",
		}
	});

	const category = watch("category");
	const [allowanceIds, setAllowanceIds] = useState<number[]>([]);
	const { mutate: createBackPay } = useCreateBackPay();
	const { mutate: updateBackPay } = useUpdateBackPay();
	const { addNewTableData } = useManageTableWithSearchParamsData();

	const onSubmit: SubmitHandler<IFormInputs> = (data: any) => {
		if (category === "ALLOWANCES" && !allowanceIds.length) return; 

		setUploading(true);

		category !== "FIXED" && formatRate(data);
		data.fixedComponent = data.fixedComponent || undefined;
		data.rateComponent = data.rateComponent || undefined;

		if (isEditForm) {
			updateBackPay(
				{ payload: data, id: formDataValues?.id },
				{
					onSuccess: (updatedData) => {
						tableAPIRef?.current.updateRows([updatedData.data]);
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		} else {
            data.allowances = allowanceIds.length ? allowanceIds : undefined;

			createBackPay(
				{ payload: { ...data, companyId: currentCompany?.id as number } }, 
				{
					onSuccess: () => {
						addNewTableData();
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		}
	};

	const handleClose = () => {
		setOpen(false);
		reset();
	};

	return (
		<ModalComponent 
            open={open} 
            title={title} 
            handleClose={handleClose}
        >
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="grid grid-cols-2 gap-x-4 gap-y-6">
					<div className="w-full">
						<Label title="Name" for="name" />
						<input
							placeholder={"Enter name"}
							type="text"
							{...register("name", {
								required: "Enter name here",
							})}
							id="name"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.name
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.name && (
							<p className="text-red-500 text-sm mt-1">{errors.name.message}</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Unique Code" for="code" />
						<FormCodeInput
							register={register}
							attributes={{
								className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
									errors.code
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`,
							}}
						/>
						{errors.code && (
							<p className="text-red-500 text-sm mt-1">{errors.code.message}</p>
						)}
					</div>
					<div className="w-full col-span-2">
						<Label title="Description" for="description" />
						<FormDescriptionInput
							register={register}
							attributes={{
								className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
									errors.description
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`,
							}}
						/>
						{errors.description && (
							<p className="text-red-500 text-sm mt-1">
								{errors.description.message}
							</p>
						)}
					</div>
                    {!isEditForm && (
                        <div className="w-full">
                            <Label title="Employee" for="employeeId" />
                            <select
                                className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
                                    errors.employeeId
                                        ? " border border-red-500 focus:border-red-500 focus:outline-red-500"
                                        : "border-none"
                                }`}
                                {...register("employeeId", {
                                    required: "Select employee here",
                                    valueAsNumber: true,
                                })}
                            >
                                <option value="">
                                    Select Employee
                                </option>
                                {employees.map((employee) => (
                                    <option 
                                        key={employee.id} 
                                        value={employee.id} 
                                    >
                                        {employee.firstName + " " + employee.lastName}
                                    </option>
                                ))}
                            </select>
                            {errors.employeeId && (
                                <p className="text-red-500 text-sm mt-1">
                                    {errors.employeeId.message}
                                </p>
                            )}
                        </div>
                    )}
					<div className="w-full">
						<Label title="Applicable Pay Period" for="applicablePayPeriodId" />
						<APISelectField 
							key="applicablePayPeriodId"
							attributes={{
								id: "applicablePayPeriodId",
							}}
							placeholder="Select Pay Period"
							register={register}
							name="applicablePayPeriodId"
							options={payPeriods}
							mapText="code"
							pathname={PATHS.SUB_COMPANY_SETTINGS.PAY_PERIODS}
							required="Select applicable pay period here"
							error={errors.applicablePayPeriodId}
							valueAsNumber
						/>
						{errors.applicablePayPeriodId && (
							<p className="text-red-500 text-sm mt-1">
								{errors.applicablePayPeriodId.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Start Pay Period" for="startPayPeriodId" />
						<APISelectField 
							key="startPayPeriodId"
							attributes={{
								id: "startPayPeriodId",
							}}
							placeholder="Select Pay Period"
							register={register}
							name="startPayPeriodId"
							options={payPeriods}
							mapText="code"
							pathname={PATHS.SUB_COMPANY_SETTINGS.PAY_PERIODS}
							required="Select start pay period here"
							error={errors.startPayPeriodId}
							valueAsNumber
						/>
						{errors.startPayPeriodId && (
							<p className="text-red-500 text-sm mt-1">
								{errors.startPayPeriodId.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="End Pay Period" for="endPayPeriodId" />
						<APISelectField 
							key="endPayPeriodId"
							attributes={{
								id: "endPayPeriodId",
							}}
							placeholder="Select Pay Period"
							register={register}
							name="endPayPeriodId"
							options={payPeriods}
							mapText="code"
							pathname={PATHS.SUB_COMPANY_SETTINGS.PAY_PERIODS}
							required="Select end pay period here"
							error={errors.endPayPeriodId}
							valueAsNumber
						/>
						{errors.endPayPeriodId && (
							<p className="text-red-500 text-sm mt-1">
								{errors.endPayPeriodId.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Category" for="category" />
						<select
							{...register("category", {
								required: "Select category here",
							})}
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.category
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						>
							<option value="">Select Category</option>
							<option value="BASIC">Basic</option>
							<option value="GROSS">Gross</option>
							<option value="ALLOWANCES">Allowances</option>
							<option value="FIXED">Fixed</option>
							<option value="BASIC_DIFFERENCE">Basic Difference</option>
							<option value="GROSS_DIFFERENCE">Gross Difference</option>
						</select>
						{errors.category && (
							<p className="text-red-500 text-sm mt-1">
								{errors.category.message}
							</p>
						)}
					</div>
					{(category === "FIXED") ? (
						<div className="w-full">
							<Label title="Fixed Component" for="fixedComponent" />
							<MoneyInput 
								attributes={{
									id: "fixedComponent",
									placeholder: "0"
								}}
								register={register}
								name="fixedComponent"
								setValue={setValue}
								defaultValue={
									isEditForm 
									? Number(formDataValues?.fixedComponent) > 0 
										? formDataValues?.fixedComponent 
										: undefined
									: undefined
								}
								required={"Enter fixed component here"}
								error={errors.fixedComponent}
							/>
							{errors.fixedComponent && (
								<p className="text-red-500 text-sm mt-1">
									{errors.fixedComponent.message}
								</p>
							)}
						</div>
					):(
						<div className="w-full">
							<Label title="Rate Component" for="rateComponent" />
							<RateComponent 
								attributes={{ 
									id: "rateComponent",
									defaultValue: isEditForm 
										? Number(formDataValues?.rateComponent) > 0 
											? formDataValues?.rateComponent 
											: undefined
										: undefined
								}}
								register={register}
								name="rateComponent"
								required="Enter rate component"
								error={errors.rateComponent}
							/>
							{errors.rateComponent && (
								<p className="text-red-500 text-sm mt-1">
									{errors.rateComponent.message}
								</p>
							)}
						</div>
					)}
					{(!isEditForm && category === "ALLOWANCES") && (
						<div className="w-full">
							<Label title="Assign Allowances" for="allowanceIds" />
							<MultiSelectField
								key={"allowanceIds"}
								options={allowances}
								setResourceIds={setAllowanceIds}
								placeholder={"Select Options"}
								pathname={PATHS.BENEFIT_AND_ALLOWANCE}
								required={category === "ALLOWANCES" && isSubmitted}
							/>
						</div>
					)}
				</div>

				<div className="w-full flex justify-end mt-8">
					<OrangeButton
						type="submit"
						title={
							uploading
							? "Uploading..."
							: isEditForm
								? "Update Back Pay"
								: "Add Back Pay"
						}
						className="bg-primary-mango text-white h-14 px-8 rounded-lg text-sm"
						disabled={uploading}
					/>
				</div>
			</form>
		</ModalComponent>
	);
};

export default BackPayModalForm;