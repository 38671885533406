import { useEffect, useState } from "react";
import { APP_CONFIG, formatDate } from "../../../../helpers/appHelpers";
import CustomStyledTable from "../../../../components/table/CustomStyledTable";
import useCompanyStore from "../../../../state-management/useCompanyStore";
import TableFilter from "../../../../components/table/filter/TableFilter";
import { useGetPayPeriod } from "../../../../services/pay-period.service";
import OrangeButton from "../../../../components/buttons/OrangeButton";
import { CompanyPayslipsColumns, CompanyPayslipsColumnVisibilityModel } from "../data";
import { Popover } from "@mui/material";
import { IoCaretDown } from "react-icons/io5";
import { PayPeriodDto } from "../../../../models/pay-period.dto";
import { useManageTableWithSearchParams } from "../../../../helpers/useManageTable";
import { Payslips, QueryPayrollDto } from "../../../../models/payroll.dto";
import { useGetCompanyPaysips, useGetEmployeePayslipPdf } from "../../../../services/payrol.service";
import ManageTableColumns from "../../../../components/table/ManageTableColumns";
import { useColumnVisibilityModel } from "../../../../helpers/useColumnVisibilityModel";
import { saveAs } from 'file-saver';
import { useAsyncEffect, useLockFn } from "ahooks";
import { BsDownload } from "react-icons/bs";
import { toast } from 'react-toastify';
import API from '../../../../services/utils/api';
import ENDPOINTS from "../../../../services/utils/endpoints";

const CompanyPayslips = ({ defaultPayPeriod }: { defaultPayPeriod: any }) => {
	const { currentCompany } = useCompanyStore();
	const [payPeriodAnchorEl, setPayPeriodAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [activePayPeriod, setActivePayPeriod] = useState<PayPeriodDto>();
	const [excelFile, setExcelFile] = useState<Blob | null>(null);
	const [fetchingExcelFile, setFetchingExcelFile] = useState(false);
	const [downloadingFile, setDownloadingFile] = useState(false);
	const {
		tableKey,
		columnVisibilityModel,
		setColumnVisibilityModel,
	} = useColumnVisibilityModel(CompanyPayslipsColumnVisibilityModel);

	useEffect(() => {
		if (!defaultPayPeriod || activePayPeriod) return;
		setActivePayPeriod(defaultPayPeriod as any);
	}, [defaultPayPeriod])
	
	const {
		tableAPIRef,
		tableData,
		isLoading,
		pageCount,
		setTableData,
	} = useManageTableWithSearchParams<Payslips>({
		fetchHook: (options: QueryPayrollDto) => 
			useGetCompanyPaysips(
				currentCompany?.id as number, 
				(activePayPeriod?.id as number) || defaultPayPeriod?.id, 
				options
			),
		options: { orderBy: undefined },
		reloadDeps: [activePayPeriod],
	});
	
	const { data: payPeriods, isLoading: loadingPayPeriods } = useGetPayPeriod({ 
		companyId: currentCompany?.id,
		limit: APP_CONFIG.PAGE_LIMIT,
		orderBy: "createdAt:desc" as any,
	});

	const { mutate: fetchPayslipPdf, isLoading: fetchingPayslipPdf } = useGetEmployeePayslipPdf();

	useAsyncEffect(useLockFn(async () => {
        if (!activePayPeriod) return;
        setFetchingExcelFile(true);

		try {
			const data = await API.PayrollAPI.getPayrollExcell(
				currentCompany?.id as number, 
				(activePayPeriod?.id as number) || defaultPayPeriod?.id,
				{},
			);
            const blob = new Blob([data], { type: "application/vnd.ms-excel;charset=utf-8" });
            setExcelFile(blob);
		} catch (error) {
            setExcelFile(null);
            API.Handlers.onErrorHandler(error, null, null);
        } finally {
            setFetchingExcelFile(false);
        }
	}), [activePayPeriod])

	const handleDownload = async () => {
        if (!excelFile) return;
        setDownloadingFile(true);

        try {
            const payPeriod = activePayPeriod || defaultPayPeriod;
            const downloadLocation = ENDPOINTS.PAYROLL.GET_PAYROLL_EXCELL
                .replace('{companyId}', currentCompany?.id as any)
                .replace('{payPeriodId}', payPeriod.id);

            const response = await fetch(downloadLocation, { method: 'GET' });

            if (!response.ok) {
                setDownloadingFile(false);
                toast.error("Download failed! Please try again.");
            }

            const contentDisposition = response.headers.get('Content-Disposition');
            const filename = contentDisposition
                ? contentDisposition.split('filename=')[1]
                : `payroll report_${payPeriod.code}(${formatDate(payPeriod.startDate)} - ${formatDate(payPeriod.endDate)}).xlsx`;

            const blob = await response.blob();

            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = filename;
            link.click();
            window.URL.revokeObjectURL(link.href);
        } finally {
            setDownloadingFile(false);
        }
	};

	const additionalColumns = [
		{
			field: "actions",
			headerName: "Actions",
			width: 180,
			headerClassName: "super-app-theme--header",
            renderCell: (params) => {
                const employee = params.row.employee;
                return (
                    <button 
                        disabled={fetchingPayslipPdf} 
                        className="py-2 px-4 rounded-[6px] flex items-center gap-3 text-primary-blue text-2sm hover:bg-gray-200"
						onClick={() => {
                            fetchPayslipPdf(
                                {
                                    options: {
                                        employeeId: employee.id,
                                        payPeriodId: activePayPeriod?.id,
                                    }
                                }, 
                                {
                                    onSuccess: (data) => {
                                        if (data.data) {
                                            const fileName = "payslip_" + activePayPeriod?.code + 
                                                `(${employee?.firstName} ${employee?.lastName})` + ".pdf";
                                            saveAs(data.data, fileName);
                                            return
                                        }
                                        toast.error("Payslip pdf not found");
                                    }, 
                                }
                            )
						}}
                    >
                        <BsDownload className="font-bold" />
                        <p>Download</p>
                    </button>
                )
			},
		},
	];

	const columnsWithAdditions = CompanyPayslipsColumns.map(
		(obj) => additionalColumns.find((o) => o.field === obj.field) || obj
	);

	return (
		<>
			<div className="flex justify-between items-center mt-4 mb-8">
                <div className="flex items-center gap-3">
                    <>
                        <button
                            onClick={(e: any) => setPayPeriodAnchorEl(e.currentTarget)}
                            className="relative border border-gray-300 rounded-lg text-2xs py-3 px-3 flex items-center gap-3"
                        >
                            <div className="absolute top-0 -translate-y-1/2 left-2 px-2 bg-white text-[11px]">Pay Period</div>
                            {activePayPeriod?.id ? (
                                <span className="max-w-full truncate">
                                    {activePayPeriod.code + " "} 
                                    <span className="text-xs">
                                        ({formatDate(activePayPeriod.startDate)} - {formatDate(activePayPeriod.endDate)})
                                    </span>
                                </span>
                            ):(
                                <span className="pr-8">Select Option</span>
                            )}
                            <IoCaretDown />
                        </button>
                        <Popover
                            open={Boolean(payPeriodAnchorEl)}
                            anchorEl={payPeriodAnchorEl}
                            onClose={() => setPayPeriodAnchorEl(null)}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            PaperProps={{
                                style: {
                                    height: "auto",
                                    maxHeight: "500px",
                                    fontSize: "15px",
                                    color: "#4F4F4F",
                                    borderRadius: 10,
                                    background: "white",
                                    boxShadow: "-10px -5px 30px 0px rgba(0, 0, 0, 0.15)",
                                    overflow: "hidden",
                                }
                            }}
                        >
                            <div className="py-1 flex flex-col">
                                {loadingPayPeriods ? <p>Loading...</p> : null}
                                {(!payPeriods?.data.length && !loadingPayPeriods) ? <p>No Pay Periods found</p> : null}

                                {payPeriods?.data.map((payPeriod) => (
                                    <button 
                                        key={payPeriod.id}
                                        className={`w-full pl-4 pr-7 py-2 text-start text-gray-700 
                                            ${activePayPeriod?.id === payPeriod.id 
                                                ? "bg-gray-200" 
                                                : "hover:bg-gray-100"
                                            }
                                        `} 
                                        role="menuitem"
                                        onClick={() => {
                                            setActivePayPeriod(payPeriod);
                                            setPayPeriodAnchorEl(null);
                                        }}
                                    >
                                        {payPeriod.code + " "} 
                                        <span className="text-xs">
                                            ({formatDate(payPeriod.startDate)} - {formatDate(payPeriod.endDate)})
                                        </span>
                                    </button>
                                ))}
                            </div>
                        </Popover>
                    </>
                    <TableFilter
                        fields={[]}
                    />
                    <ManageTableColumns 
                        columns={CompanyPayslipsColumns}
                        defaultVisibilityModel={CompanyPayslipsColumnVisibilityModel}
                        visibilityModel={columnVisibilityModel}
                        setVisibilityModel={setColumnVisibilityModel}
                        minVisibleColumns={7}
                    />
                </div>
				<OrangeButton
					title={
                        fetchingExcelFile 
                        ? "Checking File Availability..." 
                        : downloadingFile
                            ? "Downloading..."
                            : "Download Excel File"
                    }
					className="bg-primary-mango text-white h-14 px-8 rounded-lg text-sm"
					onClick={handleDownload}
					disabled={fetchingExcelFile || downloadingFile || Boolean(excelFile === null)}
				/>
			</div>
			<CustomStyledTable
				key={tableKey}
				rows={tableData}
				columns={columnsWithAdditions}
				apiRef={tableAPIRef}
				columnVisibilityModel={columnVisibilityModel}
				loading={isLoading}
				pageCount={pageCount}
			/>
		</>
	);
};

export default CompanyPayslips;