import { useState } from "react";
import ModalComponent from "../../../../components/modals/ModalFormComponent";
import Label from "../../../../components/form_fields/Label";
import { SubmitHandler, useForm } from "react-hook-form";
import {
	useCreateBonus,
	useUpdateBonus,
} from "../../../../services/bonus.service";
import { EMPLOYMENT_PERIOD_UNIT, PAYMENT_FREQUENCY } from "../../../../models/bonus.dto";
import { 
	FREQUENCY, 
	IAddEditTableForm, 
	validateFuture, 
	compareDateFields,
	enumToStringConverter,
	manageResourceIds,
	formatRate, 
} from "../../../../helpers/appHelpers";
import useEditFormStore from "../../../../state-management/useEditFormStore";
import FormDescriptionInput from "../../../../components/form_fields/FormDescriptionInput";
import MoneyInput from "../../../../components/form_fields/MoneyInput";
import OrangeButton from "../../../../components/buttons/OrangeButton";
import { useManageTableWithSearchParamsData } from "../../../../helpers/useManageTable";
import useCompanyStore from "../../../../state-management/useCompanyStore";
import { CompanyCurrencyConfigDto } from "../../../../models/company-currency.dto";
import { PayPeriodDto } from "../../../../models/pay-period.dto";
import { EmployeeBandDto } from "../../../../models/employee-band.dto";
import FormCodeInput from "../../../../components/form_fields/FormCodeInput";
import MultiSelectField from "../../../../components/form_fields/MultiSelectField";
import { PATHS } from "../../../../routes/routes.paths";
import RateComponent from "../../../../components/form_fields/RateComponent";
import { EmployeeDto } from "../../../../models/employee.dto";

interface IFormInputs {
    code: string;
    name: string;
    description: string;
    fixedComponent: number;
    rateComponent: number;
    periodStartDate: string;
    periodEndDate: string;
    minEmploymentPeriod?: number;
    employmentPeriodUnit: EMPLOYMENT_PERIOD_UNIT
    paymentFrequency: PAYMENT_FREQUENCY
    minValue: number;
    maxValue?: number;
    prorate: string;
    active: string;
    currencyId?: number;
    employeeBandIds?: number[];
	payPeriodIds?: number[];
}

type BonusModalFormProps = IAddEditTableForm & {
	companyCurrencies: CompanyCurrencyConfigDto[];
	employees: EmployeeDto[];
	employeeBands: EmployeeBandDto[];
	payPeriods: PayPeriodDto[];
}

const BonusModalForm = ({
	open,
	setOpen,
	title,
	isEditForm,
	tableAPIRef,
	companyCurrencies,
	employees,
	employeeBands,
	payPeriods,
}: BonusModalFormProps) => {
	const { currentCompany } = useCompanyStore();
	const {formDataValues} = useEditFormStore();
	const [uploading, setUploading] = useState(false);

	const {
		register,
		watch,
		handleSubmit,
		setValue,
		reset,
		formState: { errors },
	} = useForm<IFormInputs>({
		defaultValues: {
			name: isEditForm ? formDataValues?.name : "",
			code: isEditForm ? formDataValues?.code : "",
			description: isEditForm ? formDataValues?.description : "",
			fixedComponent: isEditForm ? formDataValues?.fixedComponent : "",
			rateComponent: isEditForm ? (Number(formDataValues?.rateComponent) * 100) as any : "",
			periodStartDate: isEditForm ? formDataValues?.periodStartDate.slice(0, 10) : "",
			periodEndDate: isEditForm ? formDataValues?.periodEndDate.slice(0, 10) : "",
			minEmploymentPeriod: isEditForm ? formDataValues?.minEmploymentPeriod : "",
			employmentPeriodUnit: isEditForm ? formDataValues?.employmentPeriodUnit : "",
			paymentFrequency: isEditForm ? formDataValues?.paymentFrequency : "",
			minValue: isEditForm ? formDataValues?.minValue : "",
			maxValue: isEditForm ? formDataValues?.maxValue : "",
			currencyId: isEditForm ? formDataValues?.currencyId : "",
			prorate: isEditForm ? (formDataValues?.prorate ? "yes" : "no") : "",
			active: isEditForm ? (formDataValues?.active ? "yes" : "no") : "",
		}
	});
	
	const minEmploymentPeriod = watch("minEmploymentPeriod");
	const employmentPeriodUnit = watch("employmentPeriodUnit");
	const [employeeIds, setEmployeeIds] = useState<number[]>([]);
	const [employeeBandIds, setEmployeeBandIds] = useState<number[]>([]);
	const [payPeriodIds, setPayPeriodIds] = useState<number[]>([]);

	const { mutate: createBonus } = useCreateBonus();
	const { mutate: updateBonus } = useUpdateBonus();
	const { addNewTableData } = useManageTableWithSearchParamsData();

	const onSubmit: SubmitHandler<IFormInputs> = (data: any) => {
		setUploading(true);

		formatRate(data);
		data.prorate = data.prorate === "yes" ? true : false;
		data.active = data.active === "yes" ? true : false;

		if (isEditForm) {
			const { 
				addIds: addPayPeriodIds, 
				removeIds: removePayPeriodIds 
			} = manageResourceIds({
				newData: payPeriodIds,
				oldData: formDataValues?.payPeriods?.map(payPeriod => payPeriod.id) || [],
			});
			const { 
				addIds: addEmployeeIds, 
				removeIds: removeEmployeeIds 
			} = manageResourceIds({
				newData: employeeIds,
				oldData: formDataValues?.employeeBonuses?.map(employeeBonus => employeeBonus?.employee.id) || [],
			});
			const { 
				addIds: addEmployeeBandIds, 
				removeIds: removeEmployeeBandIds 
			} = manageResourceIds({
				newData: employeeBandIds,
				oldData: formDataValues?.employeeBandBonuses?.map(employeeBandBonus => employeeBandBonus?.employeeBand.id) || [],
			});
			
			data.addPayPeriodIds = addPayPeriodIds;
			data.removePayPeriodIds = removePayPeriodIds;
			data.addEmployeeIds = addEmployeeIds;
			data.removeEmployeeIds = removeEmployeeIds;
			data.addEmployeeBandIds = addEmployeeBandIds;
			data.removeEmployeeBandIds = removeEmployeeBandIds;

			updateBonus(
				{ payload: data, id: formDataValues?.id },
				{
					onSuccess: (updatedData) => {
						tableAPIRef?.current.updateRows([updatedData.data]);
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		} else {
			data.employeeBandIds = employeeBandIds.length ? employeeBandIds as any : undefined;
			data.payPeriodIds = payPeriodIds.length ? payPeriodIds as any : undefined;

			createBonus(
				{ payload: { ...data, companyId: currentCompany?.id } }, 
				{
					onSuccess: () => {
						addNewTableData();
						setOpen(false);
						reset();
					},
					onSettled: () => setUploading(false),
				}
			);
		}
	};

	const handleClose = () => {
		setOpen(false);
		reset();
	};

	return (
		<ModalComponent 
            open={open} 
            title={title} 
            handleClose={handleClose}
        >
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="grid grid-cols-2 gap-x-4 gap-y-6">
					<div className="w-full">
						<Label title="Name" for="name" />
						<input
							defaultValue={isEditForm ? formDataValues?.name : ""}
							placeholder={"Enter name"}
							type="text"
							{...register("name", {
								required: "Enter name here",
							})}
							id="name"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.name
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.name && (
							<p className="text-red-500 text-sm mt-1">{errors.name.message}</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Unique Code" for="code" />
						<FormCodeInput
							register={register}
							attributes={{
								className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
									errors.code
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`,
							}}
						/>
						{errors.code && (
							<p className="text-red-500 text-sm mt-1">{errors.code.message}</p>
						)}
					</div>
					<div className="w-full col-span-2">
						<Label title="Description" for="description" />
						<FormDescriptionInput
							register={register}
							attributes={{
								defaultValue: isEditForm ? formDataValues?.description : "",
								className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
									errors.description
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`,
							}}
						/>
						{errors.description && (
							<p className="text-red-500 text-sm mt-1">
								{errors.description.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Currency" for="currencyId" />
						<select
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm mb-2 ${
								errors.currencyId
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							{...register("currencyId", {
								required: "Enter currency here",
								valueAsNumber: true,
							})}
						>
							<option value="">
								Select Currency
							</option>
							{companyCurrencies?.map((item) => (
								<option key={item?.id} value={item?.id}>
									{item?.currency?.name} ({item?.currency?.code})
								</option>
							))}
						</select>
						{errors.currencyId && (
							<p className="text-red-500 text-sm mt-1">
								{errors.currencyId.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Payment Frequency" for="paymentFrequency" />
						<select
							defaultValue={isEditForm ? formDataValues?.paymentFrequency : ""}
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm mb-2 ${
								errors.paymentFrequency
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
							{...register("paymentFrequency", {
								required: "Select payment frequency here",
							})}
						>
							<option value="">Select Frequency</option>
							{FREQUENCY.map((item, index) => (
								<option key={index} value={item}>
									{enumToStringConverter(item)}
								</option>
							))}
						</select>
						{errors.paymentFrequency && (
							<p className="text-red-500 text-sm mt-1">
								{errors.paymentFrequency.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Fixed Component" for="fixedComponent" />
						<MoneyInput 
							attributes={{
								id: "fixedComponent",
								placeholder: "0"
							}}
							register={register}
							name="fixedComponent"
							setValue={setValue}
							defaultValue={isEditForm ? formDataValues?.fixedComponent : undefined}
							required="Enter fixed component here"
							error={errors.fixedComponent}
						/>
						{errors.fixedComponent && (
							<p className="text-red-500 text-sm mt-1">
								{errors.fixedComponent.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Rate Component" for="rateComponent" />
						<RateComponent 
							attributes={{ 
								id: "rateComponent",
							}}
							register={register}
							name="rateComponent"
							required="Enter rate component"
							error={errors.rateComponent}
						/>
						{errors.rateComponent && (
							<p className="text-red-500 text-sm mt-1">
								{errors.rateComponent.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Minimum Value" for="minValue" />
						<MoneyInput 
							attributes={{
								id: "minValue",
								placeholder: "0"
							}}
							register={register}
							name="minValue"
							setValue={setValue}
							validate={(value) => {
								const maxValue = watch("maxValue");

								if (maxValue && value >= maxValue) {
									return "Must be less than the maximum value"
								}
								return true
							}}
							defaultValue={isEditForm ? formDataValues?.minValue : undefined}
							required="Enter minimum value here"
							error={errors.minValue}
						/>
						{errors.minValue && (
							<p className="text-red-500 text-sm mt-1">
								{errors.minValue.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Maximum Value" for="maxValue" />
						<MoneyInput 
							attributes={{
								id: "maxValue",
								placeholder: "0"
							}}
							register={register}
							name="maxValue"
							setValue={setValue}
							defaultValue={isEditForm ? formDataValues?.maxValue : undefined}
							required="Enter maximum value here"
							error={errors.maxValue}
						/>
						{errors.maxValue && (
							<p className="text-red-500 text-sm mt-1">
								{errors.maxValue.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Start Date of Period" for="periodStartDate" />
						<input
							defaultValue={
								isEditForm
									? formDataValues?.periodStartDate.slice(0, 10)
									: ""
							}
							placeholder={"mm/dd/yyyy"}
							type="date"
							{...register("periodStartDate", {
								required: "Enter start date here",
							})}
							id="periodStartDate"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.periodStartDate
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.periodStartDate && (
							<p className="text-red-500 text-sm mt-1">
								{errors.periodStartDate.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="End Date of Period" for="periodEndDate" />
						<input
							defaultValue={
								isEditForm
									? formDataValues?.periodEndDate.slice(0, 10)
									: ""
							}
							placeholder={"mm/dd/yyyy"}
							type="date"
							{...register("periodEndDate", {
								required: "Enter end date here",
								validate: (value) => compareDateFields(watch("periodStartDate"), value),
							})}
							id="periodEndDate"
							className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
								errors.periodEndDate
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`}
						/>
						{errors.periodEndDate && (
							<p className="text-red-500 text-sm mt-1">
								{errors.periodEndDate.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label
							title="Minimum Employment Period"
							for="minEmploymentPeriod"
							optional
						/>
						<div className="flex items-center text-sm">
							<input
								placeholder={"0"}
								type="number"
								{...register("minEmploymentPeriod", {
									required: employmentPeriodUnit 
										? "Enter minimum employment period or clear unit" 
										: false,
								})}
								id="minEmploymentPeriod"
								min={1}
								className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 ${
									errors.minEmploymentPeriod
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
							/>
							<select
								{...register("employmentPeriodUnit", {
									required: minEmploymentPeriod 
										? "Select unit or clear minimum employment period" 
										: false,
								})}
								className={`dark ${
									errors.employmentPeriodUnit
										? " border border-red-500 focus:border-red-500 focus:outline-red-500"
										: "border-none"
								}`}
							>
								<option value="">Select Unit</option>
								<option value="DAY">Day</option>
								<option value="MONTH">Month</option>
								<option value="YEAR">Year</option>
							</select>
						</div>
						{(errors.minEmploymentPeriod || errors.employmentPeriodUnit) && (
							<p className="text-red-500 text-sm mt-1">
								{errors.minEmploymentPeriod?.message || errors.employmentPeriodUnit?.message}
							</p>
						)}
					</div>
					<div className="w-full">
						<Label title="Assign Employees" for="employeeIds" optional />
						<MultiSelectField
							key={"employeeIds"}
							options={employees}
							setResourceIds={setEmployeeIds}
							placeholder={"Select Employees"}
							pathname={PATHS.EMPLOYEES}
							required={false}
							mapTexts={["firstName", "lastName"]}
							defaultValues={
								!isEditForm 
									? [] 
									: (formDataValues?.employeeBonuses?.map(
										employeeBonus => employeeBonus?.employee.id) || []
									)
							}
						/>
					</div>
					<div className="w-full">
						<Label title="Assign Employee Bands" for="employeeBandIds" optional />
						<MultiSelectField
							key={"employeeBandIds"}
							options={employeeBands}
							setResourceIds={setEmployeeBandIds}
							placeholder={"Select Bands"}
							pathname={PATHS.SUB_COMPANY_SETTINGS.BANDS}
							required={false}
							defaultValues={
								!isEditForm 
									? [] 
									: (formDataValues?.employeeBandBonuses?.map(
										employeeBandBonus => employeeBandBonus?.employeeBand.id) || []
									)
							}
						/>
					</div>
					<div className="col-span-2">
						<div className="flex space-x-12 text-base">
							<h3>Prorate</h3>
							<div className="flex space-x-3 items-center">
								<input
									{...register("prorate")}
									defaultChecked={isEditForm && formDataValues?.prorate === true}
									type="radio"
									value={"yes"}
									id="prorate"
								/>
								<label htmlFor="prorate">Yes</label>
							</div>
							<div className="flex space-x-3 items-center">
								<input
									{...register("prorate")}
									defaultChecked={
										isEditForm && formDataValues?.prorate === false
									}
									type="radio"
									value={"no"}
									id="no-prorate"
								/>
								<label htmlFor="no-prorate">No</label>
							</div>
						</div>
						{errors.prorate && (
							<p className="text-red-500 text-sm mt-1">
								{errors.prorate.message}
							</p>
						)}
					</div>
					<div className="col-span-2">
						<div className="flex space-x-12 text-base">
							<h3>Active</h3>
							<div className="flex space-x-3 items-center">
								<input
									{...register("active")}
									defaultChecked={isEditForm && formDataValues?.active === true}
									type="radio"
									value={"yes"}
									id="active"
								/>
								<label htmlFor="active">Yes</label>
							</div>
							<div className="flex space-x-3 items-center">
								<input
									{...register("active")}
									defaultChecked={isEditForm && formDataValues?.active === false}
									type="radio"
									value={"no"}
									id="inactive"
								/>
								<label htmlFor="inactive">No</label>
							</div>
						</div>
						{errors.active && (
							<p className="text-red-500 text-sm mt-1">
								{errors.active.message}
							</p>
						)}
					</div>
				</div>

				<div className="w-full flex justify-end mt-8">
					<OrangeButton
						type="submit"
						title={
							uploading
							? "Uploading..."
							: isEditForm
								? "Update Bonus"
								: "Add Bonus"
						}
						className="bg-primary-mango text-white h-14 px-8 rounded-lg text-sm"
						disabled={uploading}
					/>
				</div>
			</form>
		</ModalComponent>
	);
};

export default BonusModalForm;
