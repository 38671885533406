import { StyledDataGrid } from "../../helpers/appHelpers";
import { Box } from "@mui/material";
import CustomPagination from "./CustomPagination";
import useEditFormStore from "../../state-management/useEditFormStore";

interface ICustomStyledTable {
	rows: any[];
	columns: any[];
	apiRef: any;
	columnVisibilityModel?: Record<string, boolean>;
	loading?: boolean;
	height?: number | string;
	pageCount?: number;
	pagination?: any;
	setAnchorEl?: (value: React.SetStateAction<HTMLButtonElement | null>) => void;
	conditionalRowAction?: (params: any) => boolean;
}

const CustomStyledTable = ({
	rows,
	columns,
	apiRef,
	columnVisibilityModel,
	loading,
	height,
	pageCount,
	pagination,
	setAnchorEl,
	conditionalRowAction,
}: ICustomStyledTable) => {
	const { setFormData } = useEditFormStore();

	return (
		<Box
			sx={{
				height: height ? height : "530px",
				backgroundColor: "background.paper",
				borderRadius: 4,
				width: "100%",
				"& .actions": {
					color: "text.secondary",
				},
				"& .textPrimary": {
					color: "text.primary",
				},
				"& .super-app-theme--header": {
					backgroundColor: "#F2F2F4",
					fontWeight: "bold",
				},
				"& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
					width: "11px",
				},
				"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
					background: "#f7fafc",
				},
				"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
					backgroundColor: "#e2e8f0",
					borderRadius: "6px",
					border: "3px solid #f7fafc",
				},
				"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover": {
					backgroundColor: "#e2e8f0",
					borderRadius: "7px",
					border: "3px solid #f7fafc",
				},
				textOverflow: "ellipsis"
			}}
		>
			<StyledDataGrid
				rows={rows}
				rowHeight={65}
				columnHeaderHeight={70}
				columns={columns}
				loading={loading}
				initialState={{
					pagination: {
						paginationModel: {
							pageSize: 6,
						},
					},
					columns: {
						columnVisibilityModel: {
							id: false,
							...columnVisibilityModel,
						},
					},
				}}
				slots={{
					pagination: () => (pagination !== undefined) ? pagination : <CustomPagination pageCount={pageCount} />
				}}
				pageSizeOptions={[5]}
				getRowClassName={(params) => `truncate 
					${params.indexRelativeToCurrentPage % 2 === 0 ? "even-row" : "odd-row"}
					${conditionalRowAction 
						? (conditionalRowAction(params) ? "cursor-pointer" : "")
						: (setAnchorEl ? "cursor-pointer" : "")
					}
				`}
				apiRef={apiRef}
				getRowId={(row) => row.id}
				onCellClick={(params, event: any) => {
					setFormData(params.row);

					if (conditionalRowAction) {
						if (conditionalRowAction(params) && setAnchorEl) {
							setAnchorEl(event.currentTarget);
						}
					} else {
						if (setAnchorEl) {
							setAnchorEl(event.currentTarget);
						}
					}
				}}
			/>
		</Box>
	);
};

export default CustomStyledTable;
