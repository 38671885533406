import {useQuery, useInfiniteQuery, useQueryClient, useMutation} from 'react-query';
import {toast} from 'react-toastify';
import API from './utils/api';
import {
    AuditLogDto, AuditLogPaginator,
    QueryAuditLogDto,
    SearchAuditLogDto
} from '../models/audit-log.dto';
import ENDPOINTS from './utils/endpoints';

export function useGetAuditLog(options?: QueryAuditLogDto) {
    const url = ENDPOINTS.AUDIT.AUDIT_LOG.GET;
    const request = (options: QueryAuditLogDto) => API.AuditAPI.getAuditLog(options);

    const { data, isLoading, error, refetch } = useQuery<AuditLogPaginator, Error>(
        [url, options],
        ({ queryKey, pageParam }) => request(Object.assign({}, queryKey[1], pageParam)),
        { ...API.Handlers.pageParamOption }
    );

    return { data, isLoading, error, refetch };
}

export function useGetAuditLogSearch() {

    return useMutation(
        (options?: SearchAuditLogDto) => API.AuditAPI.getAuditLogSearch(options),
        {
            onSuccess: (response) => {
                
            },
            onError: API.Handlers.onErrorHandler,
        },
    );
}

export function useGetAuditLogById(id: number) {
    const url = ENDPOINTS.AUDIT.AUDIT_LOG.DETAIL;
    const request = API.AuditAPI.getAuditLogById(id);

    const { data, isLoading, error } = useQuery<{ data: AuditLogDto }, Error>(
        [url, id],
        (() => request),
        { enabled: !!(id) },
    );

    return { data, isLoading, error };
}