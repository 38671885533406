import { useEffect, useState } from "react";
import OnboardingLayout from "../../components/layouts/OnboardingLayout";
import OrangeButton from "../../components/buttons/OrangeButton";
import {useNavigate, useSearchParams} from "react-router-dom";
import {SubmitHandler, useForm} from "react-hook-form";
import Label from "../../components/form_fields/Label";
import {BsEye, BsEyeSlash} from "react-icons/bs";
import {Helmet} from "react-helmet-async";
import {
    useConfirmTokenAndCreatePassword, 
    useGetAuthDefaultPasswordPolicy, 
    useGetAuthPasswordPolicyById
} from "../../services/auth.service";
import {toast} from "react-toastify";
import { PATHS } from "../../routes/routes.paths";
import useUserStore from "../../state-management/useUserStore";
import { useViewPort } from "../../helpers/useViewPort";
import { PasswordPolicyPublicDto } from "../../models/password-policy.dto";
import { PasswordRequirementModal, validatePassword } from "../../helpers/useValidatePassword";

interface IFormInputs {
    password: string;
    confirmPassword: string;
}

const SetPassword = () => {
    const navigate = useNavigate();
	const viewPortWidth = useViewPort();
	const { setCurrentUser, setUserType } = useUserStore();
	const [searchParams] = useSearchParams();
    const token = searchParams.get("code");
    const username = searchParams.get("username");
    const organizationId = searchParams.get("ouid");
    const [passwordPolicy, setPasswowrdPolicy] = useState<PasswordPolicyPublicDto>();
    const [passwordRequirementPopup, setPasswordRequirementPopup] = useState(false);
    const [validPassword, setValidPassword] = useState(false);
    const [isShown, setIsShown] = useState(false);

    const { mutate: getAuthPasswordPolicyById } = useGetAuthPasswordPolicyById();
    const { mutate: getAuthDefaultPasswordPolicy } = useGetAuthDefaultPasswordPolicy();

    useEffect(() => {
        if (organizationId) {
            getAuthPasswordPolicyById(
                { organizationId },
                { onSuccess: (data) => setPasswowrdPolicy(data.data) }
            )
        } else {
            getAuthDefaultPasswordPolicy(
                {}, { onSuccess: (data) => setPasswowrdPolicy(data.data) }
            )
        }
    }, [organizationId])

    const {
        register,
        watch,
        handleSubmit,
        getValues,
        formState: {errors},
    } = useForm<IFormInputs>();

    const password = watch("password");

    useEffect(() => {
        if (!password || !passwordPolicy) {
            setValidPassword(false);
            return
        }

        const isValid = validatePassword(password, passwordPolicy);
        setValidPassword(isValid);
    }, [password])

    const { 
        mutate: createPassword, 
        isLoading: creatingPassword  
    } = useConfirmTokenAndCreatePassword();

    const onSubmit: SubmitHandler<IFormInputs> = (formData) => {
        if(!token){
            toast.error('Token not found');
            return
        }

        createPassword(
            { 
                payload: {
                    token,
                    password: formData.password
                }
            }, 
            {
                onSuccess: (data) => {
                    toast.success('Password set successfully');
					setCurrentUser({ ...data, loginTime: new Date() });

					const userCategory = data.user.category;
					const adminUser = data.user.organization?.billingType.admin;

					switch(userCategory.toLowerCase()) {
						case "operations":
							if (adminUser) {
								setUserType("ADMIN");
								navigate(PATHS.ADMIN_ACCOUNTS_MANAGEMENT);
							} else {
								setUserType("ORGANIZATION");
								navigate(PATHS.OVERVIEW);
							}
							break;
						case "hr":
							if (viewPortWidth < 1024) {
								setUserType("EMPLOYEE");
                                navigate(PATHS.SELF_SERVICE_DASHBOARD);
							} else {
								setUserType("HR");
                                navigate(PATHS.HR_EMPLOYEE_MANAGEMENT);
							}
							break;
						case "employee":
							setUserType("EMPLOYEE");
							navigate(PATHS.SELF_SERVICE_DASHBOARD);
							break;
					}
                },
                onError: (error) => {
                    if (
                        error.response?.data?.error === "OTP_EXPIRED" || 
                        error.response?.data?.message === "OTP has expired"
                    ) {
                        navigate(
                            PATHS.SIGN_UP_CONFIRMATION + 
                            `?username=${username}&resend=true`
                        );
                    }
                }
            }
        );
    };

    return (
        <OnboardingLayout>
            <Helmet>
                <title>Create Password | Akatua</title>
            </Helmet>
            <div className="flex flex-col px-24 justify-center w-full">
                <img src="/assets/logos/akatua-logo.svg" alt="akatua" width={130}/>
                <h1 className="mt-5 font-bold text-xl text-charcoal">
                    Hey 👋, welcome to Akatua
                </h1>
                <div className="mt-4">
                    <form 
                        onSubmit={handleSubmit(onSubmit)}
                        className="text-charcoal"
                    >
                        <div className="mt-6 flex items-center w-full space-x-4">
                            <div className="w-full">
                                <Label title="Password" for="password"/>
                                <div className="relative">
                                    <input
                                        type={!isShown ? "password" : "text"}
                                        id={"password"}
                                        placeholder={"Enter password"}
                                        className={`w-full bg-secondary-gray rounded-lg py-4 px-4 mt-2 bg text-sm  ${
                                            errors.password
                                                ? " border border-red-500 focus:border-red-500 focus:outline-red-500"
                                                : "border-none"
                                        }`}
                                        {...register("password", {
                                            required: "Enter your password here",
                                        })}
                                        autoComplete="off"
                                        disabled={!passwordPolicy}
                                        onClick={() => setPasswordRequirementPopup(true)}
                                        onBlur={() => setPasswordRequirementPopup(false)}
                                    />
                                    <div
                                        className={`absolute top-[26px] right-6 block `}
                                        onClick={() => setIsShown(prev => !prev)}
                                    >
                                        {!isShown ? (
                                            <BsEyeSlash className="text-gray-400 text-lg"/>
                                        ) : (
                                            <BsEye className="text-gray-400 text-lg"/>
                                        )}
                                    </div>
                                    {(!validPassword && passwordPolicy && passwordRequirementPopup) && (
                                        <PasswordRequirementModal 
                                            passwordPolicy={passwordPolicy} 
                                            password={password} 
                                        /> 
                                    )}
                                </div>
                                {errors.password && (
                                    <p className="text-red-500 text-sm mt-1">
                                        {errors.password.message}
                                    </p>
                                )}
                            </div>
                            <div className="w-full">
                                <Label title="Confirm Password" for="confirmPassword"/>
                                <div className="relative">
                                    <input
                                        type={!isShown ? "password" : "text"}
                                        id={"confirmPassword"}
                                        placeholder={"Re-Enter new password"}
                                        className={`w-full bg-secondary-gray rounded-lg py-4 px-4 mt-2 bg text-sm  ${
                                            errors.confirmPassword
                                                ? " border border-red-500 focus:border-red-500 focus:outline-red-500"
                                                : "border-none"
                                        }`}
                                        {...register("confirmPassword", {
                                            required: "Confirm your new password",
                                            validate: {
                                                matchesPreviousPassword: (value) => {
                                                    const { password } = getValues();
                                                    return (
                                                        password === value || "Passwords should match!"
                                                    );
                                                },
                                            },
                                        })}
                                        autoComplete="off"
                                        disabled={!passwordPolicy}
                                    />
                                    <div
                                        className={`absolute top-[26px] right-6 block `}
                                        onClick={() => setIsShown(prev => !prev)}
                                    >
                                        {isShown === false ? (
                                            <BsEyeSlash className="text-gray-400 text-lg"/>
                                        ) : (
                                            <BsEye className="text-gray-400 text-lg"/>
                                        )}
                                    </div>
                                </div>
                                {errors.confirmPassword && (
                                    <p className="text-red-500 text-sm mt-1">
                                        {errors.confirmPassword.message}
                                    </p>
                                )}
                            </div>
                        </div>

                        <div className="w-[50%]">
                            <p className="text-xs mt-8">
                                By signing up you agree to the{" "}
                                <span className="text-primary-blue">Terms and Conditions</span>
                            </p>
                            <OrangeButton
                                title={
                                    passwordPolicy
                                        ? creatingPassword 
                                            ? "Creating Password..." 
                                            : "Set Password"
                                        : "Fetching Password Requirements..."
                                }
                                className="w-full mt-10 font-light"
                                type="submit"
                                disabled={!passwordPolicy || !validPassword || creatingPassword}
                            />
                        </div>
                    </form>
                </div>
                {/* <p className="text-xs mt-6">
					Go back to{" "}
					<span className="text-primary-blue">
						<Link to={PATHS.SIGN_IN}>Sign in</Link>
					</span>
				</p> */}
            </div>
        </OnboardingLayout>
    );
};

export default SetPassword;
