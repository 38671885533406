import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import Label from "../../../../../components/form_fields/Label";
import OrangeButton from "../../../../../components/buttons/OrangeButton";
import useUserStore from "../../../../../state-management/useUserStore";
import { PAY_PERIOD_TIME_TYPE } from "../../../../../models/pay-period.dto";
import { useCreatePayPeriod } from "../../../../../services/pay-period.service";
import { FREQUENCY, compareDateFields, enumToStringConverter } from "../../../../../helpers/appHelpers";
import { TaxCodeDto } from "../../../../../models/tax-code.dto";
import useCompanyStore from "../../../../../state-management/useCompanyStore";
import { useManageAccordionsData } from "../../../../../helpers/useManageAccordions";
import FormCodeInput from "../../../../../components/form_fields/FormCodeInput";

interface IFormInputs {
    code: string;
    taxCodeId: number;
    year: number;
    startDate: string;
    endDate: string;
    sequenceNumber: number;
    timePeriod: PAY_PERIOD_TIME_TYPE;
}

interface INewPayPeriodForm {
	taxCodes: TaxCodeDto[];
	setShowNewPayPeriodForm: React.Dispatch<React.SetStateAction<boolean>>;
}

const NewPayPeriodForm = ({ taxCodes, setShowNewPayPeriodForm }: INewPayPeriodForm) => {
	const { currentUser } = useUserStore();
	const { currentCompany } = useCompanyStore();
	const [uploading, setUploading] = useState(false);
	
	const {
		register,
		watch,
		handleSubmit,
		formState: { errors },
	} = useForm<IFormInputs>();
	
	const { mutate: createPayPeriod } = useCreatePayPeriod();
	const { addNewAccordionsData } = useManageAccordionsData();

	const onSubmit: SubmitHandler<IFormInputs> = (data) => {
		setUploading(true);

		createPayPeriod(
			{
				payload: {
					...data,
					companyId: currentCompany?.id as number,
					organizationId: currentUser.user.organization?.id as string,
				}
			},
			{
				onSuccess: () => {
					addNewAccordionsData();
					setShowNewPayPeriodForm(false);
				},
				onSettled: () => setUploading(false),
			}
		)
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="grid grid-cols-2 gap-x-4 gap-y-6 mt-6">
				<div className="w-full">
					<Label title="Unique Code" for="code" />
					<FormCodeInput
						register={register}
						attributes={{
							className: `w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm ${
								errors.code
									? " border border-red-500 focus:border-red-500 focus:outline-red-500"
									: "border-none"
							}`,
						}}
					/>
					{errors?.code && (
						<p className="text-red-500 text-sm mt-1">
							{errors?.code?.message}
						</p>
					)}
				</div>
				<div className="w-full">
					<Label title="Year" for={`year`} />
					<input
						placeholder={"Enter year"}
						type="number"
						{...register(`year`, {
							required: "Enter year here",
							valueAsNumber: true
						})}
						id={`year`}
						maxLength={4}
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
							errors?.year
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
					/>
					{errors?.year && (
						<p className="text-red-500 text-sm mt-1">
							{errors?.year?.message}
						</p>
					)}
				</div>
				<div className="w-full">
					<Label title="Tax Code" for="taxCodeId" />
					<select
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm mb-2 ${
							errors.taxCodeId
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
						{...register("taxCodeId", {
							required: "Enter tax code Id here",
						})}
					>
						<option value="">
							Select Tax Code
						</option>
						{taxCodes.map((taxCode) => (
							<option 
								key={taxCode.id} 
								value={taxCode.id} 
							>
								{taxCode.code}
							</option>
						))}
					</select>
					{errors.taxCodeId && (
						<p className="text-red-500 text-sm mt-1">
							{errors.taxCodeId.message}
						</p>
					)}
				</div>
				<div className="w-full">
					<Label title="Sequence Number" for={`sequenceNumber`} />
					<input
						placeholder={"Enter sequence number"}
						type="number"
						{...register(`sequenceNumber`, {
							required: "Enter sequence number here",
							valueAsNumber: true
						})}
						id={`sequenceNumber`}
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
							errors?.sequenceNumber
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
					/>
					{errors?.sequenceNumber && (
						<p className="text-red-500 text-sm mt-1">
							{errors?.sequenceNumber?.message}
						</p>
					)}
				</div>
				<div className="w-full">
					<Label title="Start Date" for="startDate" />
					<input
						type="date"
						{...register("startDate", {
							required: "Enter start date here",
						})}
						id="startDate"
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
							errors.startDate
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
					/>
					{errors?.startDate && (
						<p className="text-red-500 text-sm mt-1">
							{errors?.startDate?.message}
						</p>
					)}
				</div>
				<div className="w-full">
					<Label title="End Date" for="endDate" />
					<input
						type="date"
						{...register("endDate", {
							required: "Enter end date here",
							validate: (value) => compareDateFields(
								watch("startDate") || "", 
								value || ""
							),
						})}
						id="endDate"
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm  ${
							errors.endDate
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
					/>
					{errors?.endDate && (
						<p className="text-red-500 text-sm mt-1">
							{errors?.endDate?.message}
						</p>
					)}
				</div>
				<div className="w-full">
					<Label title="Time Period" for="timePeriod" />
					<select
						className={`w-full bg-secondary-gray rounded-lg h-14 px-5 mt-2 text-sm mb-2 ${
							errors.timePeriod
								? " border border-red-500 focus:border-red-500 focus:outline-red-500"
								: "border-none"
						}`}
						{...register("timePeriod", {
							required: "Enter time period here",
						})}
					>
						<option value="">
							Select Time Period
						</option>
						{FREQUENCY.map((item, index) => (
							<option 
								key={index} 
								value={item} 
							>
								{enumToStringConverter(item)}
							</option>
						))}
					</select>
					{errors.timePeriod && (
						<p className="text-red-500 text-sm mt-1">
							{errors.timePeriod.message}
						</p>
					)}
				</div>
			</div>

			<div className="w-full flex items-center justify-between my-8">
				<button
					onClick={() => setShowNewPayPeriodForm(false)}
					className="px-10 py-4 border text-gray-400 hover:text-gray-500 border-gray-300 rounded-lg text-2xs"
				>
					Close Form
				</button>
				<OrangeButton
					title={`${uploading ? "Saving..." : "Add Pay Period"}`}
					type="submit"
					className={"h-14 px-8"}
					disabled={uploading}
				/>
			</div>
		</form>
	);
};

export default NewPayPeriodForm;
