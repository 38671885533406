import { Modal, Box } from "@mui/material";
import { useState } from "react";
import { IoMdClose } from "react-icons/io";
import { 
	enumToStringConverter, 
	formatDate, 
	formatDateAndTime, 
	ModalStyle, 
} from "../../../../helpers/appHelpers";
import { StatementRequestDto } from "../../../../models/statement-request.dto";
import { useGetStatementRequest } from "../../../../services/statement-request.service";
import OrangeButton from "../../../buttons/OrangeButton";
import CustomPagination from "../../../table/CustomPagination";
import { GridColDef } from "@mui/x-data-grid";
import useUserStore from "../../../../state-management/useUserStore";
import StatementsRequestModalForm from "./StatementsRequestModalForm";
import { useManageTableWithStateVariables } from "../../../../helpers/useManageTable";
import CustomStyledTable from "../../../table/CustomStyledTable";

const StatementsRequestColumns: GridColDef[] = [
	{
		field: "transactionType",
		headerName: "Transaction Type",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => enumToStringConverter(params.value),
	},
	{
		field: "requestType",
		headerName: "Request Type",
		width: 250,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => enumToStringConverter(params.value),
	},
	{
		field: "startDate",
		headerName: "Start Date",
		width: 200,
		headerClassName: "super-app-theme--header",
		sortComparator: (date1, date2) => new Date(date1).getTime() - new Date(date2).getTime(),
		valueGetter: (params) => {
			const startDate = formatDate(params);
			return startDate;
		},
	},
	{
		field: "endDate",
		headerName: "End Date",
		width: 200,
		headerClassName: "super-app-theme--header",
		sortComparator: (date1, date2) => new Date(date1).getTime() - new Date(date2).getTime(),
		valueGetter: (params) => {
			const returnDate = formatDate(params);
			return returnDate;
		},
	},
	{
		field: "createdAt",
		headerName: "Date Created",
		width: 220,
		editable: true,
		headerClassName: "super-app-theme--header",
		sortComparator: (date1, date2) => new Date(date1).getTime() - new Date(date2).getTime(),
		valueGetter: (params) => {
			const createdOn = formatDateAndTime(params);
			return createdOn;
		},
	},
	{
		field: "completed",
		headerName: "Completed",
		width: 160,
		editable: true,
		headerClassName: "super-app-theme--header",
		valueGetter: (params) => params.value ? "Yes" : "No",
	},
]

type StatementsRequestViewProps = {
	openModal: boolean;
	setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const StatementsRequestView = ({
	openModal,
	setOpenModal,
}: StatementsRequestViewProps) => {
	const { currentUser } = useUserStore();
	const [openNewRequestModal, setOpenNewRequestModal] = useState(false);
	const {
        tableAPIRef,
        tableData,
        isLoading,
        page,
        pageCount,
        addNewData,
        handlePageChange,
	} = useManageTableWithStateVariables<StatementRequestDto>({ 
		fetchHook: useGetStatementRequest,
		options: {
			organizationId: currentUser.user.organizationId,
		}
	});

	return (<>
		<Modal
			open={openModal}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={ModalStyle} width={1260} fontSize={"13px"}>
				<div className="flex justify-between items-center pt-4 pb-6">
					<div className="flex items-center gap-5">
						<h1 className="font-bold text-lg">Account Statements</h1>
						<OrangeButton
							title={"Request Statement"}
							className={"px-5 h-10 flex items-center "}
							onClick={() => setOpenNewRequestModal(true)}
						/>
					</div>
					<button onClick={() => setOpenModal(false)}>
						<IoMdClose className="text-xl hover:scale-[1.1]" />
					</button>
				</div>
				<hr className="mb-6" />
				<CustomStyledTable
					rows={tableData}
					columns={StatementsRequestColumns}
					apiRef={tableAPIRef}
					loading={isLoading}
					pageCount={pageCount}
					pagination={
						<CustomPagination 
							pageCount={pageCount} 
							pageState={page}
							handleChange={handlePageChange}
						/>
					}
				/>
			</Box>
		</Modal>
		{openNewRequestModal && (
			<StatementsRequestModalForm
				openModal={openNewRequestModal}
				setOpenModal={setOpenNewRequestModal}
				addNewData={addNewData}
			/>
		)}
	</>);
};

export default StatementsRequestView;